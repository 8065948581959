<template>

    <div class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="mb0 h1">{{ $t('general.buy_credits') }}</h2>
        </div>

        <p><strong>{{ $t('credits.title') }}</strong></p>
        <p>{{ $t(`credits.text_1.${theme}`) }}</p>
        <p>{{ $t(`credits.only`) }} {{ conversation_price }} {{ $t('credits.credits') }}
            {{ $t('credits.to_unlock_conversation') }} <strong>{{ $t('credits.then_all') }}</strong></p>
    </div>

</template>
<script>
import BackButton from "@/components/generic/BackButton.vue";
import ThemeMixin from "@/mixins/ThemeMixin";
export default {
    name: "DefaultPromotionHeader",
    components: { BackButton },
    mixins: [ThemeMixin],
    props: {
        promotion: {
            type: Object
        }
    },
    created() {
        this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
    }
}

</script>
<style scoped lang="scss">
    .price__heading {
        text-align: left;

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .price__heading-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .back-button {
            margin-right: 20px;
            display: none;
        }

        @media(max-width: 991px) {
            margin-bottom: 25px;

            .back-button {
                display: flex;
            }
        }
    }
</style>