<template>
    <transition name="fade" mode="out-in">
        <teleport to="#modals">
            <component v-if="current" :is="current.component" @success="current.handler" />
        </teleport>
    </transition>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import PreActionEmail from "@/components/auth/profile/PreActionEmail";
import PreActionResend from "@/components/auth/profile/PreActionResend";
import PreActionLocation from "@/components/auth/profile/PreActionLocation";
import PreActionDescription from "@/components/auth/profile/PreActionDescription"
import PreActionPassword from "@/components/auth/profile/PreActionPassword";
import PreActionPhotos from "@/components/auth/profile/PreActionPhotos";
import PreActionPremium from "@/components/auth/profile/PreActionPremium";
import PreActionVerified from "@/components/auth/profile/PreActionVerified";
import PreActionProfilePhoto from "@/components/auth/profile/PreActionProfilePhoto";
import PreActionConnected from "@/components/auth/profile/PreActionConnected";
import PreActionOffers from "@/components/auth/profile/PreActionOffers";
import PreActionNewVersion from "@/components/auth/profile/PreActionNewVersion.vue";
import PreActionSecretProfilePhoto from "@/components/auth/profile/PreActionSecretProfilePhoto.vue";
import PreActionFlowBasedRedirect from "@/components/auth/profile/PreActionFlowBasedRedirect.vue";
import PreActionMigration from "@/components/auth/profile/PreActionMigration.vue";
import OnDemandRechargeModal from "@/components/auth/profile/OnDemandRechargeModal.vue";
import { locationPayload } from "@/service/user";
import toastr from "toastr";
import { mapState, mapActions } from "vuex";
import { GIRL_MIN_PHOTOS } from "@/utilities/user";
import { TYPE_GENDER_FEMALE } from "@/types/user";
import DailyLimitReached from "@/components/popups/DailyLimitReached";
import { getIsDisplayed } from "@/service/popups";
import { PREMIUM_MODAL_KEY, VERIFIED_MODAL_KEY } from "@/service/popups/config";


export default {
    name: "PreActionDialogs",
    components: {
        PreActionResend, PreActionEmail, PreActionLocation, PreActionDescription, PreActionPassword, PreActionPhotos,
        PreActionPremium, PreActionVerified, PreActionProfilePhoto, PreActionConnected, PreActionOffers,
        DailyLimitReached, PreActionNewVersion, PreActionSecretProfilePhoto, PreActionFlowBasedRedirect,
        PreActionMigration, OnDemandRechargeModal
    },
    mixins: [AuthenticatedMixin],
    computed: {
        ...mapState({
            queue: state => state.profile.queue,
            publicItems: state => state.items.public,
            secretItems: state => state.items.secret,
            connected: state => state.application.connected
        })
    },
    data() {
        return {
            current: null,
            theme: process.env.VUE_APP_THEME,
            pipeline: {
                connected: {
                    component: 'PreActionConnected',
                    check: () => {
                        return ! this.connected
                    },
                    async handler() {
                        this.current = null
                        await this.remove('connected')
                    }
                },
                check_app_version: {
                    component: 'PreActionNewVersion',
                    check: () => true,
                    handler: async() => {
                        await this.remove('check_app_version')
                        window.location.reload()
                    }
                },
                password: {
                    component: 'PreActionPassword',
                    check: () => {
                        return this.auth.user.password_update_required
                    },
                    handler: async () => {
                        toastr.success(this.$i18n.t('profile.update_password.updated'))
                        this.current = null
                        await this.remove('password')
                    }
                },
                send: {
                    component: 'PreActionResend',
                    check: () => {
                        return false
                    },
                    handler: () => {
                        this.current = this.pipeline.email
                    }
                },
                email: {
                    component: 'PreActionEmail',
                    check: () => {
                        return ! this.auth.user.email_verified_at
                    },
                    handler: () => {
                        this.current = this.pipeline.send
                    }
                },
                location: {
                    component: 'PreActionLocation',
                    check: () => {
                        return ! locationPayload(this.auth.user)
                    },
                    handler: async () => {
                        toastr.success(this.$i18n.t('profile.edit.profile_changed'))
                        this.current = null
                        await this.remove('location')
                    }
                },
                profile_photo: {
                    component: 'PreActionProfilePhoto',
                    check: () => ! this.auth.user.photo,
                    handler: async () => {
                        this.current = null
                        await this.remove('profile_photo')
                    }
                },
                secret_profile_photo: {
                    component: 'PreActionSecretProfilePhoto',
                    check: () => ! this.auth.user.photo,
                    handler: async () => {
                        this.current = null
                        await this.remove('secret_profile_photo')
                    }
                },
                photos: {
                    component: 'PreActionPhotos',
                    check: () => {

                        if(this.auth.user.gender === TYPE_GENDER_FEMALE) {
                            return (this.publicItems.photos.total + this.secretItems.photos.total) < GIRL_MIN_PHOTOS
                        }

                        return false
                    },
                    handler: async () => {
                        toastr.success(this.$i18n.t('profile.edit.profile_changed'))
                        this.current = null
                        await this.remove('photos')
                    }
                },
                description: {
                    component: 'PreActionDescription',
                    check: () => {

                        if(this.auth.user.gender) {
                            return ! this.auth.user.needs || ! this.auth.user.greeting
                        }

                        return ! this.auth.user.needs || ! this.auth.user.description
                    },
                    handler: async () => {
                        toastr.success(this.$i18n.t('profile.edit.profile_changed'))
                        this.current = null
                        await this.remove('description')
                    }
                },
                premium: {
                    component: 'PreActionPremium',
                    check: () => {

                        if(getIsDisplayed(PREMIUM_MODAL_KEY))
                            return false;

                        return ! this.auth.user.gender && ! this.auth.user.premium && ! ['credits', 'status'].includes(this.$route.name)
                    },
                    handler: async () => {
                        this.current = null
                        await this.remove('premium')
                    }
                },
                verified: {
                    component: 'PreActionVerified',
                    check: () => {

                        if(getIsDisplayed(VERIFIED_MODAL_KEY))
                            return false

                        return this.auth.user.gender === TYPE_GENDER_FEMALE && ! this.auth.user.profile_verified_at
                    },
                    handler: async () => {
                        this.current = null
                        await this.remove('verified')
                    }
                },
                offers: {
                    component: 'PreActionOffers',
                    check: () => {
                        return ! this.auth.user.offers.length
                    },
                    handler: async () => {
                        this.current = null
                        await this.remove('offers')
                    }
                },
                daily_limit: {
                    component: 'DailyLimitReached',
                    check: () => {
                        return true
                    },
                    handler: async() => {
                        await this.remove('daily_limit')
                    }
                },
                flow_based_redirect: {
                    component: 'PreActionFlowBasedRedirect',
                    check: () => {
                        return true
                    },
                    handler: async() => {
                        this.current = null
                        await this.remove('flow_based_redirect')
                    }
                },
                recharge_modal: {
                    component: 'OnDemandRechargeModal',
                    check: () => {
                        return true
                    },
                    handler: async () => {
                        this.current = null
                        await this.remove('recharge_modal')
                    }
                },
                migration: {
                    component: 'PreActionMigration',
                    check: () => {
                        return true
                    },
                    handler: async () => {
                        await this.remove('migration')
                    }
                }
            }
        }
    },
    watch: {

        queue: {
            handler() {
                this.work()
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            add: 'profile/add',
            remove: 'profile/remove',
        }),
        async work() {

            if(this.current && ! this.queue.includes(this.current.pipe)) {
                this.current = null
                return this.work()
            }

            if(this.current || ! this.queue.length)
                return

            const [first] = this.queue

            console.log('working on: ', first)

            if(this.pipeline[first].check()) {
                console.log('doesnt comply')
                return this.current = {
                    ...this.pipeline[first],
                    pipe: first
                }
            }

            console.log('pass')

            this.remove(first)

        }
    },
    mounted() {
        this.work()
    }
}
</script>