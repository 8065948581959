<template>
    <div>
        <Header v-if="!logged" @request-register="$emit('request-register')" />
        <main>
            <section class="privacy">
                <div class="o-container">
                    <article>
                        <div class="header-mob">
                            <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                            <h1>Privacy Policy</h1>
                        </div>
                        <h1 class="heading-mob"><strong>Privacy Policy</strong></h1>
                        <p>By using the services of this website, you accept the terms of the following Privacy Policy. Keep in mind that the Privacy Policy may suffer occasional changes, so check it out from time to time.</p>
                        <p>This website is targeted for users 18 and above, so we are not knowingly collecting info from users under the age of 18.</p>
                        <p>Choosing not to provide all the information required by our team may prevent you from gaining access to all features of this website, like contacting different members.</p>
                        <p>The membership status comes with your agreement to receive certain emails from our team – promotional messages, transactional messages, account messages, service messages and so on. Opting out of some of these messages will take a little time to be processed by out team. Also, some of the emails we send are mandatory, like the transactional or account emails.</p>
                        <h2><strong>Information Collected</strong></h2>
                        <p>We may collect the info that you are using while accessing our website’s services, like name, address, email, phone number, payment method and personal info regarding your likes, interests and activities, while using our website.</p>
                        <p>We may automatically collect information regarding your IP address, device ID, browser used, language, access time and the content of undeleted cookies.</p>
                        <p>This website may use devices meant to track your activity on the site, like time, date, links clicked and so on, for research purposes, so that we can constantly improve our services according to your online behavior.</p>
                        <p>The third-party websites that can advertise on our website, can also collect data from our users, regarding their interaction with the ads presented online. Personal info is not provided to third-party websites.</p>
                        <h2><strong>Your personal info</strong></h2>
                        <p>All personal info – about me, photos, content and so on, you post on this website must follow the rules presented in the Terms of Service.</p>
                        <p>Depending on your privacy settings, the information from your profile will not be seen on publicly viewable web pages but will be shown to logged users. We are not responsible for what others do with the information you decide to post on your profile.</p>
                        <h2><strong>What do we do with your info?</strong></h2>
                        <p>Your info is used by our team to improve our website and the services that we provide. Your info may be used directly by us, or by a third-party vendor, hired to provide services to us.</p>
                        <p>We will use your info in order to effectively communicate with you about the products and services that we think will be of interest, and to properly manage your account and offer you a custom-made service.</p>
                        <p>Your info is also used to create relevant content and advertising, to perform website analytics and process your payments.</p>
                        <h2><strong>Protection of Personal Info</strong></h2>
                        <p>Here are the circumstances in which we will share your personal info:</p>
                        <ul>
                            <li>To third parties that are performing services related to your account, on our behalf. In this case, they will have access to the info related to the service they are performing in our behalf and will not be able to share or use your personal info in other way.</li>
                            <li>When a law enforcement or a government agency asks for our cooperation, under a court order or a subpoena.</li>
                            <li>To defend against legal claims.</li>
                            <li>To deliver targeted ads and reports regarding your interaction with advertising.</li>
                        </ul>
                        <p>Please think twice when deciding what info you want to share, because we cannot guarantee that your info will always remain secure.</p>
                        <h2><strong>Online Advertising and Google Analytics</strong></h2>
                        <p>This website uses Google Analytics third-party audience data like age, gender, interests, to better understand our costumer’s behavior and work with companies that collect info about your online activity in order to provide targeted advertising.</p>
                        <p>For further information regarding our Privacy Policy, contact our support team.</p>
                    </article>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import Header from "@/components/Header";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
export default {
    name: 'Privacy',
    components: {Header},
    mixins: [AuthenticatedMixin],
}
</script>

<style scoped lang="scss">
    main {
        @media (max-width: 991px) {
            background-color: #fff;
        }
    }
    .header-mob {
        display: none;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 0!important;
                font-size: 22px!important;
                font-weight: 700!important;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    .privacy {
        padding: 70px 0;

        .heading-mob {
            @media(max-width: 500px) {
                display: none;
            }
        }

        article {
            width: 100%;
            margin: 0 auto;
            max-width:650px;

            h1 {
                font-weight: 900;
                margin-bottom: 48px;
                font-size: 26px;
            }

            h2 {
                margin-bottom: 15px;
                margin-top: 35px;
            }
        }
        ul {
            padding-left: 30px;
            list-style-type: disc;
        }

        li {
            padding: 10px 10px;
            line-height: 1.5;

        }

        p {
            line-height: 1.5;

            a {
                color: #bfa46a;
            }
        }

        @media(max-width: 991px) {
            padding: 40px 0 70px;

            article {
                h1 {
                    margin-bottom: 20px;
                }
            }

            ul {
                padding-left: 15px;
            }
        }

        @media(max-width: 500px) {
            padding: 20px 0 70px;

        }
    }
</style>
