export const push = (type, payload) => {

    const data = {
        event: type,
        ...payload,
        timestamp: Math.floor(Date.now() / 1000)
    }

    if( process.env.VUE_APP_ENVIRONMENT === 'local') {
        return console.log('dataLayer', data)
    }

    return window.dataLayer.push(data)

}

export const GTMSale = (saleType, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username, currencyCode, transactionId, transactionTotal, transactionProducts}) => {
    push(saleType, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username, currencyCode, transactionId, transactionTotal, transactionProducts})
}

export const pushDataLayer = (event, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username}) => {
    push(event, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username})
}