import axios from "axios";

export const uploadPhoto = data => {
    return axios.post('user/photo', data)
}

/**
 *
 * @param query {Object|null}
 * @returns {Promise<any>}
 */
export const gallery = (query = null) => {
    return axios.get(`photo${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

/**
 *
 * @param data {FormData}
 * @param onUploadProgress {function|null}
 * @returns {Promise<any>}
 */
export const uploadGalleryPhoto = (data, onUploadProgress = null) => {
    return axios.post('photo', data, {onUploadProgress})
}

/**
 *
 * @param id {Number}
 * @returns {Promise<any>}
 */
export const deleteGalleryPhoto = id => {
    return axios.delete(`photo/${id}`)
}

export const updatePhoto = (id, data) => {
    return axios.patch(`photo/${id}`, data)
}

/**
 *
 * @param query {Object}
 * @returns {Promise<any>}
 */
export const videoGallery = query => {
    return axios.get(`video?${new URLSearchParams(query).toString()}`)
}

/**
 *
 * @param data
 * @returns {Promise<any>}
 */
export const uploadVideo = data => {
    return axios.post('video', data)
}

/**
 *
 * @param id {Number}
 * @returns {Promise<any>}
 */
export const deleteVideo = id => {
    return axios.delete(`video/${id}`)
}

export const updateVideo = (id, data) => {
    return axios.patch(`video/${id}`, data)
}

/**
 *
 * @param data
 * @returns {Promise<any>}
 */
export const uploadVerificationVideo = data => {
    return axios.post('user/verification', data)
}

/**
 *
 * @returns {Promise<any>}
 */
export const activateInvisibleMode = () => {
    return axios.post(`user/invisible`)
}

/**
 *
 * @returns {Promise<any>}
 */
export const getUserEmailSettings = () => {
    return axios.get(`user/settings`)
}

/**
 *
 * @returns {Promise<any>}
 */
export const setUserEmailSettings = data => {
    return axios.put(`user/settings`, data)
}

/**
 *
 * @param photo
 * @returns {Promise<any>}
 */
export const setPhotoAsProfile = photo => {
    return axios.post(`photo/${photo.id}/set`)
}

export const getReport = () => {
    return axios.get('item/report')
}