import moment from "moment/moment";

export const ucFirst = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const capitalize = string => string.split(' ').map(e => ucFirst(e)).join(' ')

export const lastWordFromString = string => string.split(' ')[string.split(' ').length - 1]

export const deleteAllCookies = () => {
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const getQueryParamValue = value => new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
})[value]

export const shuffle = array => {
    let currentIndex = array.length,  randomIndex;

    while (currentIndex !== 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const equals = (a, b) => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date)
        return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
        return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => equals(a[k], b[k]));
}

export const wait = seconds => new Promise(resolve => {setTimeout(() => resolve(), seconds * 1000)})

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const daysHoursMinutesSecondsSince = (from, until) => {


    let total = durationInSeconds(from, until)
    let out = []

    if(total <= 0) {
        return '00:00:00:00'
    }

    const days = Math.floor(total / ( 60 * 60 * 24 ))


    total -= (days * 60 * 60 * 24)
    out.push(days)

    const hours = Math.floor(total / ( 60 * 60))

    total -= (hours * 60 * 60)
    out.push(hours)

    const minutes = Math.floor(total / 60)

    total -= (minutes * 60)
    out.push(minutes)

    const seconds = Math.floor(total)
    out.push(seconds)

    return out.map(e => '' + e).map(e => e.length === 1 ? '0' + e : e).join(':')

}

export const durationInSeconds = (from, until) => moment.duration(until.diff(from)).asSeconds()
export const durationInMinutes = (from, until) => moment.duration(until.diff(from)).asMinutes()
export const durationInHours = (from, until) => moment.duration(until.diff(from)).asHours()
export const durationInDays = (from, until) => moment.duration(until.diff(from)).asDays()
export const durationInMonths = (from, until) => moment.duration(until.diff(from)).asMonths()
export const durationInYears = (from, until) => moment.duration(until.diff(from)).asYears()

export const formatCardNumber = (number) => number.replaceAll('XXXXXXXX', '******').match(/.{1,4}/g).join(' ')