<template>
    <main>
        <section class="credits">
            <div class="o-container">
                <Payment @success="successHandler">
                    <template v-slot:header={promo}>
                        <Component
                            :is="promo?.header"
                            v-if="promo?.header"
                            :promotion="promo?.promotion"
                        />
                    </template>
                    <template #footer>
                        <PaymentContent />
                    </template>
                </Payment>
            </div>
        </section>
    </main>
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_VIEW_CREDITS_PAGE} from "@/service/gtm/types";
import {getDefaultPromotion} from "@/service/promotions";
import {mapState} from "vuex";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import PaymentContent from "@/components/payment/PaymentContent.vue";
import Payment from "@/components/payment/Payment.vue";
import DefaultPromotionHeader from "@/components/payment/DefaultPromotionHeader.vue";
import LimitedPromotionPackageHeader from "@/components/payment/LimitedPromotionPackageHeader.vue";
import LimitedPromotionDaysHeader from "@/components/payment/LimitedPromotionDaysHeader.vue";

export default {
    name: "Credits",
    mixins: [ThemeMixin, AuthenticatedMixin],
    components: {
        Payment, DefaultPromotionHeader, LimitedPromotionDaysHeader, LimitedPromotionPackageHeader,
        PaymentContent
    },
    emits: ['requestRegister'],
    computed: {
        ...mapState({
            promo: state => getDefaultPromotion(state.promotions.promotions),
            card: state => state.credits.card
        }),

    },
    methods: {
        successHandler(data) {

            if(data.payment_url) {
                return window.location.href = data.payment_url
            }

            return this.$router.push({name: 'status', query: {ti_mer: data.id}})
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_VIEW_CREDITS_PAGE, this.$store.state.authenticate.auth.user)
    },
}
</script>

<style scoped lang="scss">

main {
    height: auto;
    min-height: 100%;
}



.credits {
    padding: 50px 0;

    @media(max-width: 991px) {
        padding-top: 15px;

        .o-container {
            padding: 0 20px;
        }
    }
}

</style>