import { update, resendEmail as apiResendEmail } from "@/api/auth"
import store from "@/store"
import { activateInvisibleMode as APIInvisible } from "@/api/profile";
import { getCredits } from "@/api/credits";
import { unlockAccess as APIUnlockAccess } from "@/api/access";
import { defaultHeight, defaultAge } from "@/service/forms";
import { block, unblock } from "@/api/users";
import {TYPE_CHAT_ACCESS, TYPE_ITEM_ACCESS} from "@/types/access";
import router from "@/router";

/**
 *
 * @param user {Object}
 * @param size {string}
 * @returns {string}
 */
export const authenticatedUserPhoto = (user, size = 'medium') => {

    if(user.photo) {
        return user.photo.source[size]
    }

    return `${process.env.BASE_URL}/assets/img/avatar-${user.gender === 0 ? 'male' : 'female'}.svg`

}

/**
 *
 * @param user
 * @returns {{country: *, city: *, latitude: *, location: *, state, longitude: *}|null}
 */
export const locationPayload = (user) => {
    if( ! user.latitude || ! user.longitude || ! user.address || ! user.city || ! user.country)
        return null

    return {
        latitude: user.latitude,
        longitude: user.longitude,
        country: user.country,
        city: user.city,
        state: user.state,
        location: user.address ? user.address : (user.city + ', ' + user.country)
    }
}

export const updateProfile = async (data) => {

    try {
        const response = await update(data)

        if( ! data.photo && store.state.authenticate.auth.user.photo) {
            response.data.photo = store.state.authenticate.auth.user.photo
        }

        await store.dispatch('authenticate/setUser', response.data)
        return Promise.resolve(response.data)


    } catch (e) {
        return Promise.reject(e)
    }

}

export const oppositeGender = gender => {
    return gender ? 0 : 1
}

export const getSearchQueryString = searchPayload => {

    let out = ''

    out += `distance=${searchPayload.distance}&latitude=${searchPayload.latitude}&longitude=${searchPayload.longitude}`

    out += `&city=${searchPayload.city}&country=${searchPayload.country}`

    if(searchPayload.age[0] !== defaultAge[0] || searchPayload.age[1] !== defaultAge[1]) {
        out += `&min_age=${searchPayload.age[0]}&max_age=${searchPayload.age[1]}`
    }

    searchPayload.body_type.forEach(e => {
        out += `&body_type[]=${e}`
    })

    searchPayload.education.forEach(e => {
        out += `&education[]=${e}`
    })

    searchPayload.hair_color.forEach(e => {
        out += `&hair_color[]=${e}`
    })

    searchPayload.eye_color.forEach(e => {
        out += `&eye_color[]=${e}`
    })

    if(searchPayload.height[0] !== defaultHeight[0] || searchPayload.height[1] !== defaultHeight[1]) {
        out += `&min_height=${searchPayload.height[0]}&max_height=${searchPayload.height[1]}`
    }

    searchPayload.piercing.forEach(e => {
        out += `&piercing[]=${e}`
    })

    searchPayload.tattoo.forEach(e => {
        out += `&tattoo[]=${e}`
    })

    searchPayload.drinking.forEach(e => {
        out += `&drinking[]=${e}`
    })

    searchPayload.ethnicity.forEach(e => {
        out += `&ethnicity[]=${e}`
    })

    searchPayload.smoking.forEach(e => {
        out += `&smoking[]=${e}`
    })

    searchPayload.interested_in.forEach(e => {
        out += `&interested_in[]=${e}`
    })

    out += `&show_contacted=${searchPayload.show_contacted ? 1 : 0}`

    out += `&orderDirection=desc&orderBy=${searchPayload.orderBy}`

    out += `&perPage=${searchPayload.perPage ? searchPayload.perPage : 20}`

    out += `&page=${searchPayload.page ? searchPayload.page : 1}`

    return out

}

export const activateInvisibleMode = async () => {

    try {

        await APIInvisible()

        return Promise.resolve(true)

    } catch (e) {
        return Promise.reject(e)
    }

}

export const setCredits = async () => {

    try {

        const { data } = await getCredits()

        await store.dispatch('credits/setAmount', data.balance)

        if(data.card) {
            await store.dispatch('credits/setCard', data.card)
        }

    } catch(e) {

        console.log('Error fetching credits', e)

    }

}

export const unlockAccess = async (user_id, type) => {

    try {

        const { data } = await APIUnlockAccess(user_id, type)
        setCredits().catch(e => console.log('error fetching credits', e))
        if(type === TYPE_ITEM_ACCESS) {
            await store.dispatch('access/add', user_id)
        }
        if(type === TYPE_CHAT_ACCESS) {
            if(store.state.search.data?.data) {
                await store.dispatch('search/setData', {
                    ...store.state.search.data,
                    data: [...store.state.search.data.data.map(e => e.id === user_id ? {...e, contacted: true} : e)]
                })
            }
        }
        return Promise.resolve(data)

    } catch (e) {

        const error = e

        if(e.response?.data?.message?.includes('credits')) {
            error.paymentRequired = true
        }

        return Promise.reject(error)
    }

}

export const handleInsufficientFundsError = async () => {

    if(store.state.credits.card) {

        return await store.dispatch('profile/add', 'recharge_modal')

    }

    return await router.push({name: 'credits'})

}

export const resendEmail = async email => {

    try {
        const { data } = await apiResendEmail(email)

        return Promise.resolve(data)
    } catch (e) {
        return Promise.reject(e)
    }

}

/**
 *
 * @param user_id {Number}
 * @param reason {String}
 */
export const blockUser = async (user_id, reason) => {
    await block(user_id, reason)
    // Delete cache
    await store.dispatch('search/setData', {})
}

export const unblockUser = async user_id => {
    await unblock(user_id)
    // Delete cache
    await store.dispatch('search/setData', {})
}