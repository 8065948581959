<template>
    <article class="secret-browsing-box">
        <div class="header-mob">
            <BackButton />
            <h1>{{$t('settings.secret_browsing')}}</h1>
        </div>
        <p>{{$t('settings.secret.go_invisible')}}</p>
        <div class="input input--primary mb"><strong>{{$t('settings.secret.cost', [, 10])}}</strong></div>
        <PanelBox v-if="until > 0">
            <h4 class="mb--">{{$t('settings.secret.activated', [this.until])}}</h4>
            {{$t('settings.secret.now_invisible')}}
        </PanelBox>
        <div class="btn-wrapper" v-if="until <= 0">
            <button @click="update($event)" class="w-100 button button--primary">{{$t('settings.activate')}}</button>
        </div>
    </article>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {activateInvisibleMode} from "@/api/profile";
import { mapActions } from "vuex";
import {handleInsufficientFundsError, setCredits} from "@/service/user";
import toastr from "toastr";
import BackButton from "@/components/generic/BackButton";
import PanelBox from "@/components/generic/PanelBox"
import moment from "moment";

export default {
    name: "SecretBrowsing",
    components: {BackButton,PanelBox},
    mixins: [AuthenticatedMixin],
    emits: ['requestRegister'],
    computed: {
        until() {

            if(this.auth.user.invisible) {
                return Math.ceil(moment.duration(moment.utc(this.auth.user.invisible_until, 'YYYY-MM-DD HH:mm:ss').diff(moment.utc())).asHours())
            }

            return 0

        }
    },
    methods: {
        ...mapActions({
            setUser: 'authenticate/setUser'
        }),
        async update($event) {
            $event.target.disabled = true

            try {
                const { data } = await activateInvisibleMode()
                const user = {...this.auth.user, invisible: true, invisible_until: data.invisible_until}
                await this.setUser(user)
                await setCredits()
            } catch(e) {

                $event.target.disabled = false

                if(e.response?.data?.message?.includes('credits')) {
                    return await handleInsufficientFundsError()
                }

                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            $event.target.disabled = false
        }
    },
    mounted() {
        console.log(this.until)
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/_components.settings.scss";

.secret-browsing-box {

    p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
        color: rgb(119, 119, 119);
    }

    form {
        margin-top: 40px;
    }

    .btn-wrapper {
        margin-top: 20px;

        @media(max-width: 500px) {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $color-type3;
            padding: 10px 20px;
            border-radius: 10px 10px 0 0;
            .button {
                width: 100%;
                margin-left: 0!important;
            }
        }
    }

    @media(max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        padding: 20px 20px 70px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: auto;
    }
}
</style>