<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <article class="no-data" v-if="!loading && !visitors.length">
            <div class="img-wrapper">
                <img alt="Image" width="100" height="100" src="@/assets/img/no_visitors.svg">
            </div>
            <h3>{{$t('matches.visitors.title')}}</h3>
            <p class="u-text-center">{{$t('matches.visitors.text')}}</p>
            <router-link class="button button--primary" :to="{name: 'search'}">{{$t('matches.search')}}</router-link>
        </article>
        <article class="list">
            <div v-if="visitors.length">
                <transition-group appear name="fade-in" tag="ul">
                    <li v-for="(v, index) in visitors" :key="v.id"  :style="'--animate:' + index">
                        <router-link :to="{name: 'user', params: {id: v.id}}">
                            <div class="user-info">
                                <div class="img-wrapper" :class="notifications.social?.matches?.visitors?.includes(v.id) ? 'is-active' : ''">
                                    <img  alt="user photo" :src="getUserPhotoURL(v, 'small')">
                                </div>
                                <OnlineStatus class="active-user" v-if="isOnline(v)" />
                                <p class="mb0">
                                    <span class="o-flex o-flex--center">
                                        <Premium v-if="v.premium"/>
                                        <Verified v-if="v.profile_verified_at"/>
                                        <strong> {{v.username}}</strong>
                                    </span>
                                    <span>
                                        {{v.address}}
                                    </span>
                                </p>
                            </div>
                        </router-link>
                        <Favorite :profile="v" />
                    </li>
                </transition-group>
            </div>
        </article>
        <Pagination @page-click="load" :info="pagination" />
    </div>
</template>

<script>

import { getVisitors } from "@/api/matches/visitors";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import Pagination from "@/components/generic/Pagination";
import Favorite from "@/components/search/Favorite";
import { getPaginationPayload } from "@/service/general";
import { mapState } from "vuex";
import { read } from "@/service/notifications";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";

export default {
    name: "Visitor",
    components: {Verified, Premium, Pagination, Favorite, ContentLoader, OnlineStatus},
    data() {
        return {
            loading: false,
            visitors: [],
            pagination: null,
            page: 1,
            timeout: null
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications
        })
    },
    watch: {
        '$store.state.notifications.social.matches.visitors': {
            handler: function(value) {

                if( ! value.length)
                    return

                this.visitors = []
                this.pagination = null

                this.load()

                clearTimeout(this.timeout)

                this.timeout = setTimeout(() => {
                    read('visitors', this.notifications.social.matches.visitors)
                }, 3000)

            }
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.visitors = []
            const { data } = await getVisitors({page: page})
            this.visitors = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        }
    },
    mounted() {
        this.load()
        if(this.notifications.social.matches.visitors.length) {
            this.timeout = setTimeout(() => {
                read('visitors', this.notifications.social.matches.visitors)
            }, 3000)
        }
    },
    beforeUnmount() {
        if(this.notifications.social.matches.visitors.length) {
            clearTimeout(this.timeout)
            read('visitors', this.notifications.social.matches.visitors)
        }
    }
}
</script>

<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>