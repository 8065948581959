<template>
    <teleport to="#modals">
        <Modal class="modal modal--mob modal--recharge" :modalStyle="transaction?'':'background-color: #F5F5F5'">
            <template v-slot:body>
                <PaymentStatus v-if="transaction" :transaction="transaction" />
                <template v-else>
                    <div class="amount-box"><img src="../../../assets/img/dollar-icon.svg" alt="Dollar icon"> {{credits}}</div>
                    <h2 class="h4">{{$t('credits.not_enough_credits')}}! {{$t('credits.recharge')}}:</h2>
                    <Payment :toggle="true" @success="successHandler" />
                    <ul class="partners-link">
                        <li><img src="../../../assets/img/verified-members.svg" alt="verified"></li>
                        <li><img src="../../../assets/img/amazon.svg" alt="amazon"></li>
                        <li><img src="../../../assets/img/visa.svg" alt="visa"></li>
                        <li><img src="../../../assets/img/master.svg" alt="master"></li>
                    </ul>
                    <button @click="$emit('success')" class="button button&#45;&#45;gray w-100">{{$t('file_control.cancel')}}</button>
                </template>
            </template>
        </Modal>
    </teleport>
</template>
<script>

import Modal from "@/components/generic/Modal.vue";
import Payment from "@/components/payment/Payment.vue";
import PaymentStatus from "@/components/payment/PaymentStatus.vue";
import { mapState } from "vuex";

export default {
    name: "RechargeModal",
    components: {
       Modal, Payment, PaymentStatus
    },
    emits: ['success'],
    data() {
        return {
            transaction: null
        }
    },
    computed: {
        ...mapState({
            credits: state => state.credits.amount
        })
    },
    methods: {
        successHandler(payload) {
            console.log('success', payload)

            if(payload.payment_url) {
                return window.location.href = payload.payment_url
            }

            this.transaction = payload

            setTimeout(() => {
                this.$emit('success')
            }, 2000)


        }
    }

}
</script>
<style lang="scss" scoped>
    .modal {
        text-align: center;

        h2.h4 {
            font-weight: 400;
            margin-bottom: 30px;
        }
    }

    .promotion-heading {
      background-color: #3CBF6C;
      padding: 15px 20px 35px;
      letter-spacing: -0.66px;
      color: #F5F5F5;
      text-transform: uppercase;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;

      span {
        display: flex;
        border-radius: 10px;
        background-color: #F5F5F5;
        color: #3CBF6C;
        letter-spacing: -0.49px;
        text-transform: uppercase;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        padding: 4px 10px;
      }
    }

    .divider {
      width: 100%;
      margin: 0 auto;
      background-color: #DEDEDE;
      height: 1px;
      margin-bottom: 10px;
    }



    .amount-box {
        border-radius: 15px;
        background-color: #fff;
        padding-left: 40px;
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        height: 30px;
        width: auto;
        padding-right: 20px;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: "Open Sans", sans-serif!important;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        @media(max-width: 350px) {
            min-width: 105px;
        }
    }
    .partners-link {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        li {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        @media(max-width: 991px) {
            flex-wrap: nowrap;
        }
    }
</style>

<style lang="scss">
    .modal--recharge {

        .status-box {
            padding: 0;

            article {
                border: none;
                box-shadow: none;
                padding: 30px 30px 0;

                &.is-active {
                    box-shadow: none;
                    border: none;
                }
            }
        }

        .price {
            margin-bottom: 0;
        }
        .price__boxes {
            margin-bottom: 20px;
            display: block;
            margin-top: 0;
        }

        .price__item {
            border-radius: 10px;
            border: solid 1px transparent;
            background-color: $color-type1;
            padding: 10px 20px;
            position: relative;
            box-shadow: 0px 0px 12px -3px $color-type6;
            transition: all .3s ease-in-out;
            width: 100%;
            margin-bottom: 10px;

            &:hover {
                box-shadow: 0px 0px 12px 0px $color-type6;
            }

            &.is-active {
                background-color: $color-type16;

                h2,
                h3,
                p {
                    color: $color-type1;
                }
            }

            .price-box {
                // margin-bottom: 16px;
                color: $color-type19;
                font-size: 26px;
                display: flex;
                align-items: center;
                justify-content: center;

                small {
                    color: $color-type19;
                    margin-right: 8px;
                    font-size: 26px;
                    font-weight: 700;
                }
            }

            p {
                font-size: 14px;
                line-height: 1;
                transition: all .3s ease-in-out;
                margin-bottom: 20px;
                color: $color-type8;
                margin-top: -5px;
                letter-spacing: -0.57px;
            }

            h3 {
                margin-bottom: 4px;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.2;
                transition: all .3s ease-in-out;
                letter-spacing: -0.74px;
            }
        }

        .price__item-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            &:first-child {
                h2 {
                    font-size: 15px;
                    margin-bottom: 0;
                }
            }
            &:nth-child(2) {
                flex-direction: row-reverse;

                p {
                    font-size: 15px;
                    margin-bottom: 10px;
                }

                h3 {
                    font-size: 20px;
                }
            }
            &:last-child {

                h4 {
                    margin-bottom: 20px;
                }
            }
            .divider {
                display: none;
            }

            .button--primary {
                width: auto;
            }
            .price-box {
                line-height: 1;
                margin-bottom: 0;

                br {
                    display: none;
                }
            }

            .best-value {
                bottom: auto;
                top: 0;
                transform: translate(-50%, 7px);
            }
        }

        .price__item--promotion {
            padding: 0;
            background-color: transparent;
            overflow: hidden;
            border: none;

            .promotion-body {
                padding-top: 16px;
                padding-bottom: 6px;
                padding-left: 20px;
                padding-right: 20px;
            }

            p {
                margin-bottom: 10px;
            }

            p.line {

                &:before {
                    width: calc(100% + 6px)!important;
                }
            }

            .button {
                height: 30px;
                font-size: 14px;
            }

            .desktop-show {
                display: none;
            }

            .mobile-show {
                display: block;
            }

            .price__item-row-promo {
                display: block;
                text-align: left;
                margin-top: 10px;

                h4 {
                    font-weight: 400;
                    line-height: 1;
                    text-align: left;
                }

                .divider {
                    display: block;
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .price__item-row--btn-wrapper {
            justify-content: flex-end;
        }

        .credits-box {
            margin-top: -35px;

            h3 {
                margin-bottom: 10px;
            }

            p {
                margin-top: 0;
            }
        }
    }
</style>