<template>
    <div>
        <Header v-if="!logged" @request-register="$emit('request-register')" />
        <main>
            <section class="terms">
                <div class="o-container">
                    <article>
                        <div class="header-mob">
                            <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                            <h1>Terms of Service</h1>
                        </div>
                        <h1 class="heading-mob"><strong>Terms of Service</strong></h1>
                        <p>By surfing ­­­­this website, you confirm that you understand and accept its terms and conditions. Keep in mind that these terms and conditions can be periodically modified, and the changes will be effective as soon as they are posted online.</p>
                        <ul>
                            <li>You are an adult, being at least 18 years old;</li>
                            <li>In your country of residence is legal to use this website and accept its terms and conditions;</li>
                            <li>You are not registered as a sex offender and you have never been convicted of committing a felony;</li>
                            <li>In your country of residence, it is legal to use the services provided by this website or be a member of this website;</li>
                        </ul>
                        <p>There are no costs for signing up for this website, but some additional services may only be available for paying subscribers. We are talking about contacting members, viewing certain photos and profiles.</p>
                        <p>Also, keep in mind that even if you are not actively using the services of this website, your profile may remain visible.</p>
                        <h2><strong>1. Termination</strong></h2>
                        <ul>
                            <li>Change your membership status by accessing your Account page and follow the instructions provided for you there.</li>
                            <li>When canceling your subscription, this will be terminated immediately, and any paid fees will be forfeit.</li>
                            <li>When canceling the subscription, you will not be eligible for a refund and your profile will be removed from the website.</li>
                            <li>If the website’s support team believes that you’re violating this agreement, your membership can be terminated or suspended, without notice. In this case you won’t be entitled to a refund and the team is not required to disclose the reason for your termination.</li>
                        </ul>
                        <h2><strong>2. Subscription and Billing</strong></h2>
                        <ul>
                            <li>The use of any of the services provided by this website are going to be billed through an online account.</li>
                            <li>When purchasing credits from this website, you agree to pay all charges, at the price set at the time of your purchase and to authorize us to charge your payment provider.</li>
                            <li>The website can correct any billing mistakes or errors, even if a payment has already been requested or received.</li>
                            <li>Any reversed payment can lead to the immediate termination of your account, without any refund or reinstatement of the subscription.</li>
                            <li>When using the services of this website you agree to provide us the correct billing info for your account, and to alert us if a security breach is associated with your account or payment method.</li>
                        </ul>
                        <h2><strong>3. Security</strong></h2>
                        <ul>
                            <li>Please notify us immediately if there is any unauthorized activity on your account – like the use of your username, password or account without your consent.</li>
                            <li>You are the sole responsible for the security of your username and password and for any activity that occurs on your account.</li>
                        </ul>
                        <h2><strong>4. Interactions with members</strong></h2>
                        <ul>
                            <li>This website is created for personal use. Please do not use it for commercial purposes, to advertise your products or to make members buy or sell your products. Also, do not use this website to gather information for commercial use.</li>
                            <li>This website does not conduct criminal background checks on its members, so you are the only one responsible for any interactions you may have with other members. Also, we do not check the validity of the information that the members of this website may provide you with.</li>
                            <li>The way the members of this website decide to conduct is not in our power to control, so we are not liable for any type of damage that can occur from your interaction with the members of this website.</li>
                            <li>We can’t guarantee any results from you using this website as we can’t guarantee that the members will look exactly as in their profile pictures.</li>
                            <li>If needed we can create test accounts to verify certain features of the website and we can also investigate and even take legal actions against the members of this website, that use the website in an unauthorized or illegal manner.</li>
                        </ul>
                        <h2><strong>5. Your content and info</strong></h2>
                        <ul>
                            <li>You are the sole responsible for the content that you provide on your profile or transmit to other members. If the content that you provide is violating the terms and conditions of our website, we can terminate your membership.</li>
                            <li>When creating an account on the website, you agree not to post offensive, abusive, obscene, intimidating, racial, illegal content that can violate the rights of others.</li>
                            <li>We do not tolerate actions like intimidating, harassment, defraud, trolling, or content stealing, against other members of this website.</li>
                            <li>As a member of this website you agree not to promote illegal activities, use photos, texts or videos of another person without consent, spread viruses and impersonate other persons.</li>
                            <li>We are not responsible for the content you decide to post on your profile. But we have the right to use, adapt, display or copy any content that you post. Also keep in mind that everything you post is visible to all the website’s members.</li>
                            <li>If required to, by the law enforcement, we have the right to share your account info, if this helps in an investigation.</li>
                            <li>If you think your work has been used on this website without your written consent, you can alert us regarding this copywriting infringement. Please share with us the link to where your work is displayed, your contact info and your declaration regarding the infringement.</li>
                        </ul>
                        <h2><strong>6. Activities Banned on this Website</strong></h2>
                        <p>If you decide to participate in any of the activities mentioned below, this gives us the right to terminate your membership and forfeit any kind of prepaid fees:</p>
                        <ul>
                            <li>Impersonating another person</li>
                            <li>Posting any kind of forbidden content, like the one mentioned above at 5.2, 5.3, 5.4</li>
                            <li>Use devices that can index or reproduce the content or structure of this website</li>
                            <li>Use your access to other member profiles in commercial purposes</li>
                            <li>Distribute content obtained from this website in other places</li>
                            <li>Transmit viruses meant to limit or even destroy the websites functionality</li>
                            <li>Use this website in order to redirect user to other websites.</li>
                        </ul>
                        <h2><strong>7. The team’s communication with you</strong></h2>
                        <p>By becoming a member of this website, you agree to receive emails from us, about transactions that are being made, announcements, websites offer, and promotions and special offers provided to you by our partners.</p>
                        <p>You can unsubscribe at any point.</p>
                        <h2><strong>8. Property Rights</strong></h2>
                        <p>This website owns all the content, trademarks, and intellectual rights on this service and website. You are not allowed to copy, modify, transmit or reproduce content without written content from the websites support team.</p>
                        <h2><strong>9. Advertising Third Party Services</strong></h2>
                        <p>This website may contain links and advertising of third-party services and websites. This website is not responsible for the content that the third-party sites may have or promote, or for the availability of the services that those websites are promoting.</p>
                        <p>This website is not liable for any problems that may occur when using the services or the content provided by the third-party website.</p>
                        <h2><strong>Additional Disclaimers</strong></h2>
                        <p>We are not responsible for the way our members decide to conduct themselves, while both offline and online.</p>
                        <p>We are not responsible if, as a result of using this website, you suffer any personal damage or injury.</p>
                        <p>We cannot guarantee your satisfaction, or an uninterrupted, “no problems encountered” interaction, while using the services of this website.</p>
                        <p>While using this website you are the full responsible for the effects that using the services or downloading any content from the website, may have on your computer.</p>
                        <p>This website is designed for entertainment only. We are not responsible for the effects that following the advice received on this website, may have on you.</p>
                        <p>Any claim that you make regarding this website must be filled within a year after the claim appeared. Our liability to you is limited to the amount you paid for the website’s services.</p>
                        <br>
                        <br>
                        <br>
                        <br>
                        <p>***Please check the Terms of Service from time to time, in order to see the latest updates. We have the right to modify or discontinue any service and to make changes to the following Terms of Service, with or without notice. We will use all the means to let you know of the changes made – through email, website posts and so on, but we’re not responsible if you not receive any of our messages.</p>
                    </article>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import Header from "@/components/Header";
export default {
    name: 'Terms',
    mixins: [AuthenticatedMixin],
    components: {Header}
}
</script>

<style scoped lang="scss">
    .header-mob {
        display: none;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 0!important;
                font-size: 22px!important;
                font-weight: 700!important;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    .terms {
        padding: 70px 0;

        .heading-mob {
            @media(max-width: 500px) {
                display: none;
            }
        }

        article {
            width: 100%;
            margin: 0 auto;
            max-width:650px;

            h1 {
                font-weight: 900;
                margin-bottom: 48px;
                font-size: 26px;
            }

            h2 {
                margin-bottom: 15px;
                margin-top: 35px;
            }
        }
        ul {
            padding-left: 30px;
            list-style-type: disc;
        }

        li {
            padding: 10px 10px;
            line-height: 1.5;

        }

        p {
            line-height: 1.5;

            a {
                color: #bfa46a;
            }
        }

        @media(max-width: 991px) {
            padding: 40px 0 70px;

            article {
                h1 {
                    margin-bottom: 20px;
                }

                br {
                    display: none;
                }
            }

            ul {
                padding-left: 15px;
            }
        }

        @media(max-width: 500px) {
            padding: 20px 0 70px;

        }
    }
</style>
