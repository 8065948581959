<template>
    <slot name="header" :promo="promo"></slot>
    <Component
        :is="promo?.component"
        v-if="promo?.component && (toggle ? ! confirm : true)"
        :packages="availablePackages"
        :promotion="promo?.promotion"
        :valid-until="expiringDate"
        :working="working"
        @selected="packageSelectHandler"
    />
    <slot name="footer"></slot>
    <ConfirmPaymentWithCard
        v-if="selectedPrice && confirm"
        :selected-price="selectedPrice"
        @success="handlePaymentSuccess"
        @error="handlePaymentError"
        @reject="startTransaction(selectedPrice)"
        @close="selectedPrice = null; confirm = false;"
    />
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import PaymentContent from "@/components/payment/PaymentContent.vue";
import ConfirmPaymentWithCard from "@/components/payment/ConfirmPaymentWithCard.vue";
import DefaultPromotion from "@/components/payment/DefaultPromotion.vue";
import Modal from "@/components/generic/Modal.vue";
import LimitedPromotionDays from "@/components/payment/LimitedPromotionDays.vue";
import LimitedPromotionPackage from "@/components/payment/LimitedPromotionPackage.vue";
import BackButton from "@/components/generic/BackButton.vue";
import DefaultPromotionHeader from "@/components/payment/DefaultPromotionHeader.vue";
import LimitedPromotionDaysHeader from "@/components/payment/LimitedPromotionDaysHeader.vue";
import LimitedPromotionPackageHeader from "@/components/payment/LimitedPromotionPackageHeader.vue";
import {mapActions, mapState} from "vuex";
import {getDefaultPromotion, setAvailablePromotions} from "@/service/promotions";
import moment from "moment/moment";
import toastr from "toastr";
import {setCredits} from "@/service/user";
import {pushDataLayer} from "@/service/gtm";
import {GTM_EVENT_SELECT_PACKAGE, GTM_EVENT_VIEW_CREDITS_PAGE} from "@/service/gtm/types";
import {create} from "@/api/payment";

export default {
    name: "Payment",
    mixins: [ThemeMixin, AuthenticatedMixin],
    components: {
        PaymentContent,
        ConfirmPaymentWithCard, DefaultPromotion, Modal, LimitedPromotionDays, LimitedPromotionPackage,
        BackButton, DefaultPromotionHeader, LimitedPromotionDaysHeader, LimitedPromotionPackageHeader
    },
    emits: ['requestRegister', 'success', 'error'],
    props: {
        toggle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            working: false,
            confirm: false,
            selectedPrice: null,
        }
    },
    computed: {
        ...mapState({
            promo: state => getDefaultPromotion(state.promotions.promotions),
            card: state => state.credits.card
        }),

        expiringDate() {

            if( ! this.promo?.promotion || this.promo.promotion.default)
                return null

            if(this.promo.promotion.valid_until) {
                return moment.utc(this.promo.promotion.valid_until)
            }

            return moment.utc(this.auth.user.created_at, 'YYYY-MM-DD HH:mm:ss').add('3', 'day')
        },

        availablePackages() {
            return this.promo?.promotion?.packages?.filter(e => ! e.suspended)
        },
    },
    methods: {
        ...mapActions({
            addPreAction: "profile/add"
        }),
        packageSelectHandler(p) {

            this.selectedPrice = p

            if(this.card) {

                return this.confirm = true

            }

            this.startTransaction(p)
        },

        async handlePaymentError(e) {
            console.log('Error creating transaction', e)
            toastr.error(this.$i18n.t('credits.error_processing'))
            this.selectedPrice = null
            this.confirm = false
            await setAvailablePromotions()
            this.$emit('error')
            this.working = false
        },

        async handlePaymentSuccess(payload) {
            this.working = false
            await setCredits()
            this.$emit('success', payload)
        },

        async startTransaction(p) {

            pushDataLayer(GTM_EVENT_SELECT_PACKAGE.replace('{id}', p.id), this.$store.state.authenticate.auth.user)

            if(this.working)
                return

            this.working = true

            try {

                const { data } = await create({price_id: p.price_id, one_click: false})

                this.working = false

                return this.$emit('success', data)


            } catch (e) {
                await this.handlePaymentError(e)
            }

        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_VIEW_CREDITS_PAGE, this.$store.state.authenticate.auth.user)
    },
    created() {
        this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
        this.gallery_price = process.env.VUE_APP_UNLOCK_GALLERY
    }
}
</script>

<style scoped lang="scss">

</style>