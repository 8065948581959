<template>
    <Modal class="modal modal--mob u-text-center">
        <template #header>
            <br>
            <h2>{{$t('credits.one_click.confirm')}}</h2>
        </template>
        <template #body>
            <p class="mb-">{{$t('credits.one_click.charged')}}</p>
            <h4><strong>{{selectedPrice.currency.toUpperCase()}} {{selectedPrice.price.toFixed(2)}}</strong></h4>
            <h3 class="h2 mb0">{{ formatCardNumber(card.number) }}</h3>
            <a @click="$emit('reject')" class="change-credit-card">{{$t('credits.one_click.change')}}</a>
        </template>
        <template #footer>
            <div class="button-wrapper">
                <button :disabled="working" @click="purchase" class="button button--primary w-100 mb-">{{$t('credits.one_click.confirm')}}</button>
                <button :disabled="working" @click="$emit('close')" class="button button--gray w-100 mb">{{$t('credits.one_click.no')}}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from "@/components/generic/Modal.vue";
    import {create} from "@/api/payment";
    import { mapState } from "vuex";
    import { formatCardNumber } from "@/utilities/general";

    export default {
        name: "ConfirmPaymentWithCard",
        components: {Modal},
        props: {
            selectedPrice: {
                type: Object,
                required: true
            }
        },
        emits: ['close', 'success', 'error', 'reject'],
        data() {
            return {
                working: false
            }
        },
        computed: {
            ...mapState({
                card: state => state.credits.card
            }),
        },
        methods: {
            formatCardNumber,
            async purchase() {

                try {
                    this.working = true
                    const { data } = await create({price_id: this.selectedPrice.price_id, one_click: true})
                    this.$emit('success', data)

                } catch (e) {

                    this.$emit('error', e)

                }

            }

        }
    }
</script>

<style scoped lang="scss">

@font-face {
    font-family: 'Credit';
    src:   url('../../assets/fonts/CREDC___.otf')  format('opentype'), url('../../assets/fonts/CREDC___.ttf')  format('truetype');
    font-weight: 400;
    font-style: normal;
}


.modal {
    .change-credit-card {
        color: $color-type8;
        text-decoration: underline;
        line-height: 24px;

        &:hover {
            color: $color-type19;
        }
    }

    h2 {
        line-height: 38px;
    }

    h4 {
        letter-spacing: -0.66px;
        line-height: 38px;
    }

    h3 {
        line-height: 24px;
        font-size: 14px;
        letter-spacing: 1px;
        color: $color-type18;
        font-weight: 400;
        font-family: 'Credit';
    }

    .button-wrapper {
        margin-top: 60px;
    }
}

</style>