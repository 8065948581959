<template>
    <div class="price">
        <div class="price__boxes">
            <div @click="packageClickHandler(p)" style="cursor: pointer" v-for="p in packages" :key="p.id"
                 class="price__item" :class="{'is-active': p.name.toLowerCase().includes('best')}">
                <div class="price__item-row">
                    <svg v-if="p.name.toLowerCase().includes('best')" class="best-value"
                         xmlns="http://www.w3.org/2000/svg" width="20" height="16">
                        <path data-name="Icon awesome-crown"
                              d="M16.5 14h-13a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm2-10A1.5 1.5 0 0 0 17 5.5a1.47 1.47 0 0 0 .137.619l-2.262 1.356a1 1 0 0 1-1.381-.363l-2.547-4.456a1.5 1.5 0 1 0-1.894 0L6.506 7.112a1 1 0 0 1-1.381.362L2.866 6.119A1.5 1.5 0 1 0 1.5 7a1.532 1.532 0 0 0 .241-.025L4 13h12l2.259-6.025A1.532 1.532 0 0 0 18.5 7a1.5 1.5 0 1 0 0-3Z"
                              fill="#c59264"/>
                    </svg>
                    <h2 class="heading">{{ p.name }}</h2>
                    <div class="price-box">
                        <small>{{ p.currency.toUpperCase() }} </small>{{ Number(p.price / p.credits).toFixed(2) }}
                    </div>
                </div>
                <div class="price__item-row">
                    <p>{{ $t('credits.per_credit') }}</p>
                    <div class="divider"></div>
                    <h3>{{ p.credits }} {{ $t('credits.credits') }}</h3>
                </div>
                <div class="price__item-row">
                    <h4>{{ p.currency.toUpperCase() }} {{ p.price.toFixed(2) }}</h4>
                    <button :disabled="working" class="button button--primary">
                        {{ ucFirst($t('credits.buy_credits')) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import {ucFirst} from "@/utilities/general";

export default {
    name: "DefaultPromotion",
    mixins: [ThemeMixin],
    emits: ['selected'],
    props: {
        packages: {
            type: Array
        },
        working: {
            type: Boolean,
            required: true
        },
        promotion: {
            type: Object,
            required: true
        }
    },
    methods: {
        ucFirst,
        packageClickHandler(p) {

            this.$emit('selected', p)

        },

    }
}
</script>

<style scoped lang="scss">

.price {
    text-align: center;
    max-width: 1080px;
    margin: 0 auto 100px;
}

.price__boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    gap: 30px;
}

.price__item-row {
    position: relative;

    .best-value {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -5px);
    }

    .button--primary {
        width: 100%;
    }
}

.price__item {
    border-radius: 10px;
    border: solid 1px transparent;
    background-color: $color-type1;
    padding: 50px 30px 40px;
    position: relative;
    box-shadow: 0px 0px 12px -3px $color-type6;
    max-width: 500px;
    transition: all .3s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 12px 0px $color-type6;
    }

    &.is-active {
        background-color: #222222;

        h2,
        h3,
        h4,
        p {
            color: $color-type1;
        }

        .divider {
            background-color: #DEDEDE;
        }
    }

    h2 {
        font-size: 17px;
        font-weight: 700;
        transition: all .3s ease-in-out;
        margin-bottom: 40px;
    }

    .price-box {
        margin-bottom: 16px;
        color: $color-type19;
        font-size: 61px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        small {
            font-size: 26px;
            color: $color-type19;
            margin-right: 8px;
        }
    }

    p {
        font-size: 18px;
        line-height: 1;
        transition: all .3s ease-in-out;
        margin-bottom: 20px;
        color: #959595;
        margin-top: -5px;
    }


    .divider {
        width: 100%;
        max-width: 136px;
        margin: 0 auto;
        background-color: #DEDEDE;
        height: 1px;
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 4px;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.2;
        transition: all .3s ease-in-out;
        letter-spacing: -0.9px;
    }

    h4 {
        color: $color-type9;
        margin-bottom: 45px;
        font-size: 18px;
        line-height: 1.6;
        transition: all .3s ease-in-out;
        font-weight: 400;
    }
}

@media(max-width: 991px) {

    .price {
        margin-bottom: 40px;
    }

    .price > p {
        font-size: 16px;
    }

    .price__item {
        margin: 10px;
        padding: 10px 20px;
    }

    .price__item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
            h2 {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        &:nth-child(2) {
            flex-direction: row-reverse;

            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 20px;
            }
        }

        &:last-child {

            h4 {
                margin-bottom: 20px;
            }
        }

        .divider {
            display: none;
        }

        .button--primary {
            width: auto;
        }

        .price-box {
            line-height: 1;
            font-size: 30px;
            align-items: center;
            margin-bottom: 0;

            br {
                display: none;
            }
        }
    }
}

@media(max-width: 991px) {
    .price__boxes {
        display: block;
    }

    .price__item {
        margin: 10px auto;
        width: 100%;

        .best-value {
            transform: translate(-50%, 100%);
        }
    }
}

</style>