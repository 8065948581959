<template>
    <div class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="h1"> {{ $t('payment.limited_offer_1.limited') }}</h2>
        </div>
        <p>{{ $t('payment.limited_offer_1.grab') }}</p>
        <div class="time-left-box">
            <Review/>
        </div>
    </div>
</template>

<script>
import BackButton from "@/components/generic/BackButton.vue";
import Review from "@/components/generic/Review.vue";

export default {
    name: "LimitedPromotionPackageHeader",
    components: {Review, BackButton},
    props: {
        promotion: {
            type: Object
        }
    },
}
</script>

<style lang="scss" scoped>
    .price__heading {
        text-align: left;

        .h1 {
            font-size: 26px;
            margin-bottom: 0;
            font-weight: 700;

        }

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            line-height: 24px;
        }
    }
    
    .price__heading-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .back-button {
            margin-right: 20px;
            display: none;
        }

        @media(max-width: 991px) {
            margin-bottom: 25px;

            .back-button {
                display: flex;
            }
        }
    }

    .time-left-box {
        display: flex;
        align-items: center;
        
        @media(max-width: 991px) {
            justify-content: space-between;
        }
    }
</style>