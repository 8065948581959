<template>
    <div class="banners">
        <div v-if="active === 'fund'" class="banners__box banners__box--pink">
            <router-link  class="verification-link" :to="{name: 'credits'}">
                <svg class="verified-icon" width="20" viewBox="0 0 25 22"><path id="Shape 3 copy" d="M21.1.96c-2.53-1.48-6.23-1.16-8.6.88A7.546 7.546 0 0 0 7.57 0C6.23 0 4.96.34 3.92.95 1.5 2.27 0 4.83 0 7.63c0 .84.13 1.62.35 2.2 1.19 5.33 10.48 11.33 11.54 12 .19.11.4.17.61.17.21 0 .42-.06.61-.17 1.06-.67 10.35-6.67 11.5-11.86.26-.71.39-1.5.39-2.34 0-2.79-1.5-5.35-3.9-6.67ZM15 15.01h-5l1.46-5.24C10.6 9.37 10 8.51 10 7.5a2.5 2.5 0 0 1 5 0c0 1.01-.6 1.87-1.46 2.27L15 15.01Z" fill="#b23490"/></svg>
                <span>{{$t('search.fund_account_unverified')}}</span> {{$t('search.click_here')}}
            </router-link>
            <a @click="close" class="close-banner">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.003" height="10"><g data-name="Group 101"><path data-name="Icon ionic-ios-close" d="M6.187 5 9.76 1.427A.837.837 0 0 0 8.576.243L5.003 3.811 1.43.238A.837.837 0 1 0 .246 1.422L3.815 5 .242 8.573a.837.837 0 1 0 1.184 1.184l3.573-3.573 3.573 3.573a.837.837 0 1 0 1.184-1.184Z"/></g></svg>
            </a>
        </div>
        <div v-if="active === 'verify'" class="banners__box banners__box--blue">

            <router-link class="verification-link" :to="{name: 'verification'}">
                <svg class="verified-icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg> 
                <strong>{{$t('search.get_verified')}} </strong>
                {{$t('search.the_real_deal')}}
            </router-link> 

            <a @click="close" class="close-banner">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.003" height="10"><g data-name="Group 101"><path data-name="Icon ionic-ios-close" d="M6.187 5 9.76 1.427A.837.837 0 0 0 8.576.243L5.003 3.811 1.43.238A.837.837 0 1 0 .246 1.422L3.815 5 .242 8.573a.837.837 0 1 0 1.184 1.184l3.573-3.573 3.573 3.573a.837.837 0 1 0 1.184-1.184Z"/></g></svg>
            </a>
        </div>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { TYPE_GENDER_MALE } from "@/types/user";
import { mapState } from "vuex";

export default {
    name: "Banners",
    mixins: [AuthenticatedMixin],
    data() {
        return {
            banners: []
        }
    },
    computed: {
        active() {
            if(this.banners.length) {
                return this.banners[0]
            }
            return null
        },
        ...mapState({
            credits: state => state.credits.amount
        })
    },
    watch: {
        '$store.state.authenticate.auth.user.profile_verified_at': {
            handler(val) {
                if( val) {
                    this.banners = this.banners.filter(e => e !== 'verify')
                }
            }
        }
    },
    methods: {
        close() {

            let banners = []
            try {
                banners = Array.from(JSON.parse(sessionStorage.getItem('banners')))
            } catch {
                console.log('cannot parse banners')
            }
            if( ! banners.includes(this.banners[0])) {
                banners.push(this.banners[0])
            }
            sessionStorage.setItem('banners', JSON.stringify(banners))

            const [, ...rest] = this.banners
            this.banners = rest
        }
    },
    mounted() {

        let banners = []

        try {
            banners = Array.from(JSON.parse(sessionStorage.getItem('banners')))
        } catch {
            console.log('cannot parse banners')
        }

        if( ! this.auth.user.profile_verified_at && ! banners.includes('verify')) {
            this.banners.push('verify')
        }

        if(this.auth.user.gender === TYPE_GENDER_MALE && ! this.credits && ! banners.includes('fund')) {
            this.banners.push('fund')
        }

    }
}
</script>

<style scoped lang="scss">
.banners {
    width: 100%;
    position: relative;
}

.banners__box {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 45px;
    height: 100%;
    border-radius: 10px;
    padding: 10px 50px;
    font-size: 18px;
    line-height: 1.3;
    text-align: center;


    .verified-icon {
        margin-right: 5px;
    }

    &--pink {
        background-color: $color-type21;

        span {
            color: $color-type18;
            font-weight: 400;
        }

        a {
            color: $color-type23;
        }
    }
    &--blue {
        background-color: $color-type27;

        a {
            color: #000;
        }

        strong {
            color: $color-type28;
        }
    }

    .close-banner {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0,-50%);
    }

    .verification-link {
        position: relative;

        svg {
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translate(0,-50%);

            @media(max-width: 991px) {
                position: relative;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                top: 0;
                right: auto;
                transform: translate(0,4px);

            }
        }
    }

    @media(max-width: 991px) {
        padding: 12px 36px 15px 15px;
        display: block;
        text-align: left;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.3;

        a {
            line-height: 1.5;
            font-size: 16px;
            letter-spacing: -0.66px;
        }

        .close-banner {
            right: 14px;
            top: 12px;
            transform: translate(0,0);
        }
    }
}
</style>