export const GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_SOI          = 'sd-signup-soi'
export const GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_SOI           = 'sb-signup-soi'
export const GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_DOI          = 'sd-signup-doi'
export const GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_DOI           = 'sb-signup-doi'
export const GTM_EVENT_TYPE_LOGIN                           = 'login-user-data'
export const GTM_EVENT_TYPE_PAYMENT_INITIAL                 = 'initial-sale'
export const GTM_EVENT_TYPE_PAYMENT_REPEATED                = 'repeated-sale'
export const GTM_COLLECTABLE_NAME                           = 'gtm_transactions_processed'

export const GTM_EVENT_SUGGESTED_PROFILE_APPEAR             = 'notification-suggested-profile-appear'
export const GTM_EVENT_SUGGESTED_PROFILE_OPEN               = 'notification-suggested-profile-open'
export const GTM_EVENT_SUGGESTED_PROFILE_CLICK_1            = 'notification-suggested-profile-click-cta-1'
export const GTM_EVENT_SUGGESTED_PROFILE_CLICK_2            = 'notification-suggested-profile-click-cta-2'

export const GTM_EVENT_PREMIUM_SUGAR_DADDY_APPEAR           = 'notification-premium-sugar-daddy-appear'
export const GTM_EVENT_PREMIUM_SUGAR_DADDY_OPEN             = 'notification-premium-sugar-daddy-open'
export const GTM_EVENT_PREMIUM_SUGAR_DADDY_CLICK_1          = 'notification-premium-sugar-daddy-click-cta-1'
export const GTM_EVENT_PREMIUM_SUGAR_DADDY_CLICK_2          = 'notification-premium-sugar-daddy-click-cta-2'

export const GTM_EVENT_ONLINE_PROFILE_APPEAR                = 'notification-online-profile-appear'
export const GTM_EVENT_ONLINE_PROFILE_OPEN                  = 'notification-online-profile-open'
export const GTM_EVENT_ONLINE_PROFILE_CLICK_1               = 'notification-online-profile-click-cta-1'
export const GTM_EVENT_ONLINE_PROFILE_CLICK_2               = 'notification-online-profile-click-cta-2'

export const GTM_EVENT_PERFECT_MATCH_APPEAR                 = 'notification-perfect-match-appear'
export const GTM_EVENT_PERFECT_MATCH_OPEN                   = 'notification-perfect-match-open'
export const GTM_EVENT_PERFECT_MATCH_CLICK_1                = 'notification-perfect-match-click-cta-1'
export const GTM_EVENT_PERFECT_MATCH_CLICK_2                = 'notification-perfect-match-click-cta-2'

export const GTM_EVENT_REQUESTED_SECRET_ACCESS_APPEAR       = 'notification-req-secret-access-appear'
export const GTM_EVENT_REQUESTED_SECRET_ACCESS_OPEN         = 'notification-req-secret-access-open'
export const GTM_EVENT_REQUESTED_SECRET_ACCESS_CLICK_1      = 'notification-req-secret-access-click-cta-1'
export const GTM_EVENT_REQUESTED_SECRET_ACCESS_CLICK_2      = 'notification-req-secret-access-click-cta-2'

export const GTM_EVENT_SECRET_ACCESS_GRANTED_APPEAR         = 'notification-secret-access-granted-appear'
export const GTM_EVENT_SECRET_ACCESS_GRANTED_OPEN           = 'notification-secret-access-granted-open'
export const GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_1        = 'notification-secret-access-granted-click-cta-1'
export const GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_2        = 'notification-secret-access-granted-click-cta-2'

export const GTM_EVENT_FAVORITED_YOU_APPEAR                 = 'notification-favorited_you-appear'
export const GTM_EVENT_FAVORITED_YOU_OPEN                   = 'notification-favorited_you-open'
export const GTM_EVENT_FAVORITED_YOU_CLICK_1                = 'notification-favorited_you-click-cta-1'
export const GTM_EVENT_FAVORITED_YOU_CLICK_2                = 'notification-favorited_you-click-cta-2'

export const GTM_EVENT_NEW_MESSAGE_APPEAR                   = 'notification-new-message-appear'
export const GTM_EVENT_NEW_MESSAGE_OPEN                     = 'notification-new-message-open'
export const GTM_EVENT_NEW_MESSAGE_CLICK_1                  = 'notification-new-message-click-cta-1'
export const GTM_EVENT_NEW_MESSAGE_CLICK_2                  = 'notification-new-message-click-cta-2'

export const GTM_EVENT_PROFILE_VISIT_APPEAR                 = 'notification-profile-visit-appear'
export const GTM_EVENT_PROFILE_VISIT_OPEN                   = 'notification-profile-visit-open'
export const GTM_EVENT_PROFILE_VISIT_CLICK_1                = 'notification-profile-visit-click-cta-1'
export const GTM_EVENT_PROFILE_VISIT_CLICK_2                = 'notification-profile-visit-click-cta-2'

export const GTM_EVENT_ADDED_NEW_VIDEO_APPEAR               = 'notification-added-new-video-appear'
export const GTM_EVENT_ADDED_NEW_VIDEO_OPEN                 = 'notification-added-new-video-open'
export const GTM_EVENT_ADDED_NEW_VIDEO_CLICK_1              = 'notification-added-new-video-click-cta-1'
export const GTM_EVENT_ADDED_NEW_VIDEO_CLICK_2              = 'notification-added-new-video-click-cta-2'

export const GTM_EVENT_MATCH_APPEAR                         = 'notification-its-a-match-appear'
export const GTM_EVENT_MATCH_OPEN                           = 'notification-its-a-match-open'
export const GTM_EVENT_MATCH_CLICK_1                        = 'notification-its-a-match-click-cta-1'
export const GTM_EVENT_MATCH_CLICK_2                        = 'notification-its-a-match-click-cta-2'

export const GTM_EVENT_VERIFY_MODAL_VIEW                    = 'view-modal-verify-email'
export const GTM_EVENT_VERIFY_EMAIL_SEND                    = 'click-modal-verify-email-button-didnt-receive-email'
export const GTM_EVENT_VERIFY_EMAIL_SEND_CLICK              = 'click-modal-didnt-receive-email-button-resend-verification-email'
export const GTM_EVENT_EMAIL_VERIFIED_PAGE                  = 'view-modal-verified-email-address'
export const GTM_EVENT_EMAIL_VERIFIED_DISCOVER_CLICK        = 'click-modal-verified-email-address-button-discover-new-people'
export const GTM_EVENT_ADD_PROFILE_INFO_MODAL               = 'view-modal-add-profile-info'
export const GTM_EVENT_ADD_PROFILE_INFO_UPDATE              = 'click-modal-add-profile-info-button-save'
export const GTM_EVENT_GET_CREDITS_MODAL                    = 'view-modal-unlock-full-potential'
export const GTM_EVENT_GET_CREDITS_MODAL_CLICK_BUY          = 'click-modal-unlock-full-potential-button-buy-credits'
export const GTM_EVENT_GET_CREDITS_MODAL_CLICK_STAY         = 'click-modal-unlock-full-potential-button-stay-basic'
export const GTM_EVENT_PROFILE_PHOTO_MODAL                  = 'view-modal-upload-a-profile-photo'
export const GTM_EVENT_PROFILE_PHOTO_MODAL_UPLOAD           = 'click-modal-crop-profile-photo-button-save'
export const GTM_EVENT_PROFILE_PHOTO_MODAL_SKIP             = 'click-modal-upload-a-profile-photo-button-skip-for-now'
export const GTM_EVENT_ADD_PHOTOS_MODAL                     = 'view-modal-add-photos'
export const GTM_EVENT_ADD_PHOTOS_MODAL_CONTINUE            = 'click-modal-add-photos-continue'
export const GTM_EVENT_GET_VERIFIED_MODAL                   = 'view-modal-get-verified'
export const GTM_EVENT_GET_VERIFIED_MODAL_GET_VERIFIED      = 'click-modal-get-verified-button-get-verified'
export const GTM_EVENT_GET_VERIFIED_MODAL_STAY              = 'click-modal-get-verified-button-stay-basic'
export const GTM_EVENT_BUY_CREDITS_CLICK                    = 'click-button-buy-credits-header-desk-menu-mob'
export const GTM_EVENT_PAGE_LINK_CLICK                      = 'click-button-menu-{page}'
export const GTM_EVENT_VIEW_CREDITS_PAGE                    = 'view-credits-page'
export const GTM_EVENT_SELECT_PACKAGE                       = 'click-credits-page-package-{id}'
export const GTM_EVENT_PAYMENT_FAILED_PAGE                  = 'view-payment-failed-page'
export const GTM_EVENT_PAYMENT_FAILED_TRY_AGAIN             = 'click-payment-failed-page-button-try-again'
export const GTM_EVENT_PAYMENT_SUCCESS                      = 'view-payment-completed-page'
export const GTM_EVENT_PAYMENT_SUCCESS_BACK_HOME            = 'click-payment-completed-page-button-back-home'

export const GTM_EVENT_DATING_OFFER_MODAL                   = 'view-modal-dating-offers'
export const GTM_EVENT_DATING_OFFER_SUCCESS                 = 'click-modal-dating-offers-button-save'
export const GTM_EVENT_DATING_OFFER_APPEAR                  = 'notification-dating-offer-appear'
export const GTM_EVENT_DATING_OFFER_OPEN                    = 'notification-dating-offer-open'
export const GTM_EVENT_DATING_OFFER_CTA_1                   = 'notification-dating-offer-click-cta-1'
export const GTM_EVENT_DATING_OFFER_CTA_2                   = 'notification-dating-offer-click-cta-2'