<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import moment from "moment";
import store from "@/store";
import { durationInSeconds, durationInDays } from "@/utilities/general";
export default {
    name: 'PreActionFlowBasedRedirect',
    mixins: [AuthenticatedMixin],
    render() {
        return null
    },
    async mounted() {

        if(this.$route.meta?.restrictFlowRedirect) {
            return this.$emit('success')
        }

        if(durationInSeconds(moment.utc(), moment.utc(this.auth.user.created_at, 'YYYY-MM-DD HH:mm:ss').add('3', 'day')) > 0) {

            if(! store.state.credits.card) {

                // Flow one, domain agnostic
                if(['flow_1', 'flow_2'].includes(this.auth.user.flow)) {

                    await this.$router.push({name: 'credits'})

                }

            }

        }

        let reactivationPromotionActiveUntil = moment.utc('2023-05-25 10:00:00', 'YYYY-MM-DD HH:mm:ss').add('5', 'day')

        if(durationInDays(moment.utc(), reactivationPromotionActiveUntil) > 0) {

            if(! store.state.credits.card) {

                if(this.auth.user.flow === "reactivation_promo_2") {

                    await this.$router.push({name: 'credits'})

                }

            }

        }



        this.$emit('success')

    }
}
</script>