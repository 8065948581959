<template>
    <div class="h-100">
        <teleport to="#modals">
            <transition name="fade" mode="out-in">
                <Modal v-if="showUploadVideo" v-show="video && ! working" class="modal modal--mob">
                    <template #body>
                        <UploadItem v-if="video" @progress="track" @finished="finish" @error="handleUploadError" :file="video" @close="reset">
                            <template #switch>
                                {{ucFirst($t(`profile.secret_video`))}}
                            </template>
                            <template #description>
                                {{$t('profile.secret_video_visible')}}
                            </template>
                        </UploadItem>
                    </template>
                </Modal>
            </transition>
        </teleport>
        <div class="o-container h-100">
            <section class="gallery">
                <div class="messages__header">
                    <BackButton />
                    <h2>{{$t('navigation.sub_nav.videos')}}</h2>
                </div>
                <article>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="nav-wrap">
                                <router-link class="button button--fourth mr-" :to="{name: 'photos'}" :class="`font-weight-${$route.name === 'photos' ? 'bold' : 'normal'}`">
                                    {{$t('general.photos')}}
                                </router-link>
                                <router-link class="button button--fourth" :to="{name: 'videos'}" :class="`font-weight-${$route.name === 'videos' ? 'bold' : 'normal'}`">
                                    {{$t('general.videos')}}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="btn-wrapper">
                                <button :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{ucFirst($t('profile.videos.upload_video'))}}</button>
                                <input @change="set" accept="video/*" type="file" style="display: none" ref="upload">
                            </div>
                        </div>
                    </div>
                </article>
                <article>
                    <ContentLoader v-if="loading"/>
                    <div v-if="!loading">
                        <div v-if="!videos.length" class="no-uploaded-images">
                            <div class="img-wrapper">
                                <img alt="Image" style="cursor: pointer" @click="$refs.upload.click()" :src="require(`@/assets/img/${this.public ? 'no_photos': 'no_secret'}.svg`)">
                            </div>
                            <h1>{{$t(`profile.videos.${this.public ? 'public' : 'secret'}_title`)}}</h1>
                            <h2>{{$t(`profile.videos.${this.public ? 'public' : 'secret'}_text`)}}</h2>
                            <div class="gray-box">
                                <h4>{{$t('profile.videos.guidelines')}}</h4>
                                <ol>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_1')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_2')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_3')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_4')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_5')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_6')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.videos.guideline_7')}}</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="img-list">
                            <div v-for="(v, k) in videos"
                                 class="img-wrapper"
                                 :key="v"
                                 :class="!v.approved ? 'pending' : ''"
                                 >
                                <div v-if="v.ready">
                                    <div class="pending-overlay" v-if="!v.approved">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Icon feather-clock" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 65" d="M31.5 16.5a15 15 0 1 1-15-15 15 15 0 0 1 15 15Z"/><path data-name="Path 66" d="M16.5 7.5v9l6 3"/></g></svg>
                                        </span>
                                    </div>

                                    <div v-if="v.approved" class="play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><defs><filter id="a" x="14.5" y="10.5" width="35.999" height="39" filterUnits="userSpaceOnUse"><feOffset/><feGaussianBlur stdDeviation="2.5" result="blur"/><feFlood flood-opacity=".4"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g data-name="Group 159" transform="translate(-60 -194)"><circle data-name="Ellipse 30" cx="30" cy="30" r="30" transform="translate(60 194)" fill="#fff" opacity=".5"/><g transform="translate(60 194)" filter="url(#a)"><path data-name="Icon awesome-play" d="M41.893 28.064 25.394 18.31A2.237 2.237 0 0 0 22 20.246v19.5a2.248 2.248 0 0 0 3.394 1.936l16.5-9.75a2.248 2.248 0 0 0-.001-3.868Z" fill="#fff"/></g></g></svg>
                                    </div>
                                    <div class="status-info">
                                        <span v-if="v.private"><strong>{{$t('file_control.secret')}}</strong></span>
                                        <span v-if="!v.private"><strong>{{$t('file_control.public')}}</strong></span>
                                        <span v-if="v.id === auth.user.photo_id"><strong>{{$t('quick_start.profile_photo')}}</strong></span>
                                    </div>

                                    <div class="verification-box" v-if="v.verification"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg></div>
                                    <Video :src="v.source.original" @click="preview.video = v; preview.show = true; preview.index = k" style="width: 100%" :preload="mobile ? 'metadata' : null" />
                                </div>


                                <div v-else>
                                    <MediaLoader />
                                    <div class="converting-video">
                                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.071 0H1.93A1.93 1.93 0 0 0 0 1.929V16.07A1.93 1.93 0 0 0 1.929 18H16.07A1.93 1.93 0 0 0 18 16.071V1.93A1.93 1.93 0 0 0 16.071 0Zm.643 1.929v1.285h-2.571V1.286h1.928c.355 0 .643.288.643.643ZM8.357 14.786v1.928H5.143v-1.928h3.214Zm1.286 0h3.214v1.928H9.643v-1.928ZM1.286 13.5v-9h15.428v9H1.286ZM9.643 3.214V1.286h3.214v1.928H9.643Zm-1.286 0H5.143V1.286h3.214v1.928ZM1.93 1.286h1.928v1.928H1.286V1.93c0-.355.288-.643.643-.643ZM1.286 16.07v-1.285h2.571v1.928H1.93a.644.644 0 0 1-.643-.643Zm14.785.643h-1.928v-1.928h2.571v1.285a.644.644 0 0 1-.643.643Zm-3.57-8.289L7.36 5.854a.645.645 0 0 0-.93.575v5.142a.643.643 0 0 0 .93.575l5.143-2.571a.643.643 0 0 0 0-1.15Zm-4.787 2.106V7.47L10.777 9l-3.063 1.531Z" fill="#fff"/></svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </article>

                <div class="o-flex o-flex--justify-center">
                    <Pagination @page-click="load" :info="pagination" />
                </div>
            </section>
        </div>

        <Modal :closable="false" v-if="working">
            <template #header>
                <p class="mb-">{{$t('general.wait')}}</p>
            </template>
            <template #body>
                <ProgressBar :completed="uploadCompleted" />
            </template>
        </Modal>

        <teleport to="#modals">
            <Slider v-if="preview.show" :current="0" @close="preview.show = false; modal.show = false; modal.mode = null; showWarning = false" :slides="[preview.video]">
                <template v-slot:content="{displaying}">
                    <MediaSlider :slides="[preview.video]" :displaying="displaying" />
                    <div class="img-number">{{preview.index + data.from}} / {{data.total}}</div>
                    <div class="approved" v-if="!preview.video.approved">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Icon feather-clock" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 65" d="M31.5 16.5a15 15 0 1 1-15-15 15 15 0 0 1 15 15Z"/><path data-name="Path 66" d="M16.5 7.5v9l6 3"/></g></svg>
                        <p>{{$t('file_control.waiting_for_approval')}}!</p>
                    </div>
                </template>
                <template #footer>
                    <div class="slider-info slider-info--inline">
                        <div v-if="showWarning" class="pl- pr-">
                            <p><strong>{{$t('signup.replace_to_remove.must_contain')}}</strong></p>
                            <p>{{$t('signup.replace_to_remove.customize_album')}}</p>
                            <button type="button" @click="modal.mode = null; modal.show = false; showWarning = false" class="button button--third">Close</button>
                            <button type="button" :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{$t('signup.replace_to_remove.upload')}}</button>
                        </div>
                        <div v-if="modal.show && ! showWarning" class="pl- pr-">
                            <h2  v-if="modal.mode==='update'">
                                {{$t('file_control.make')}} {{$t(`file_control.${!preview.video.private ? 'secret' : 'public'}`)}}
                            </h2>
                            <h2  v-if="modal.mode==='delete'">
                                {{$t('file_control.delete')}} {{$t('file_control.video')}}
                            </h2>
                            <p v-if="modal.mode==='update'">
                                {{$t('file_control.making_this')}} {{$t('file_control.video').toLowerCase()}} {{$t(`file_control.${this.public ? 'secret' : 'public'}_means`)}}
                            </p>
                            <p v-if="modal.mode==='delete'">
                                {{$t('file_control.are_you_sure')}} {{$t('file_control.video').toLowerCase()}} {{$t('file_control.from_your_album')}}?
                            </p>
                            <button type="button" @click="modal.show = false; modal.mode = null" class="button button--third">{{ucFirst($t('quick_start.back'))}}</button>
                            <button type="button" v-if="modal.mode==='update'" :disabled="working" @click="update(preview.video, {private: preview.video.private ? 0 : 1})" class="button button--primary">{{$t('file_control.make')}} {{$t(`file_control.${ ! preview.video.private ? 'secret' : 'public'}`)}}</button>
                            <button type="button" v-if="modal.mode==='delete'" :disabled="working" @click="del(preview.video)" class="button button--primary">{{$t('file_control.delete')}}</button>
                        </div>
                        <div v-if="!modal.show && ! showWarning" class="main-status-info">
                            <div class="photo-status">{{$t('profile.this_video_is')}} <strong>{{ucFirst($t(`profile.${ ! preview.video.private ? 'public' : 'secret'}_video`))}}</strong></div>
                            <div class="o-flex o-flex--center o-flex--justify-center">
                                <button type="button" class="button link-button" @click="confirm(preview.video, 'delete')">{{$t('file_control.delete')}}</button>
                                <div v-if="!preview.video.verification" class="vertical"></div>
                                <button v-if="!preview.video.verification" type="button" class="button link-button" @click="confirm(preview.video, 'update')">{{$t(`file_control.${!preview.video.private ? 'secret' : 'public'}`)}}</button>
                            </div>
                        </div>
                    </div>
                </template>
            </Slider>
        </teleport>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import Pagination from "@/components/generic/Pagination";
import { videoGallery, deleteVideo, updateVideo } from "@/api/profile"
import Modal from "@/components/generic/Modal";
import ContentLoader from "@/components/ContentLoader";
import Slider from "@/components/generic/Slider";
import MediaSlider from "@/components/generic/MediaSlider";
import { createSelectedFile, getExtensionFromFilename } from "@/service/files";
import toastr from "toastr";
import { ITEM_TYPE_VIDEO } from "@/types/item";
import ProgressBar from "@/components/ProgressBar";
import { ALLOWED_VIDEO_FORMATS, MAX_VIDEO_FILE_SIZE } from "@/utilities/files";
import MediaLoader from "@/components/MediaLoader";
import { mapActions } from "vuex";
import { ucFirst } from "@/utilities/general";
import { setAuthenticatedUserItemCount } from "@/service/auth";
import { mapState } from "vuex";
import BackButton from "@/components/generic/BackButton";
import UploadItem from "@/components/photos/UploadItem.vue";
import Video from "@/components/generic/Video.vue";

export default {
    name: "VideoGallery",
    mixins: [AuthenticatedMixin],
    components: {
        ProgressBar, Pagination, Modal, ContentLoader, Slider,
        MediaSlider, MediaLoader, BackButton, UploadItem, Video
    },
    data() {
        return {
            data: {
                total: 0,
                from: 0,
                to: 0
            },
            public: true,
            working: false,
            loading: false,
            page: 1,
            videos: [],
            pagination: null,
            showWarning: false,
            modal: {
                show: false,
                mode: ''
            },
            pageWatcher: null,
            preview: {
                display: false,
                video: null,
                index: 0
            },
            uploadCompleted: 0,
            showUploadVideo: false,
            video: null
        }
    },
    computed: {
        ...mapState({
            deleted: state => state.items.deleted,
            ready: state => state.items.ready,
            mobile: state => state.application.mobile
        })
    },
    watch: {
        deleted(value) {
            if(this.videos.some(e => value.includes(e.id))) {
                this.load(this.page)
            }
        },
        async public(val) {
            console.log('Watching public prop in Videos', val)

            this.pagination = null

            this.videos = []
            this.page = 1

            await this.load(this.page)
        },
        async ready(value) {
            const item = {
                ...value[value.length - 1]
            }

            if( ! this.videos.find(e => e.id === item.id))
                return

            this.videos = this.videos.map(e => {
                if(e.id === item.id) {
                    return {
                        ...e,
                        ...item
                    }
                }
                return e
            })

            await setAuthenticatedUserItemCount()
        }
    },
    methods: {
        ucFirst,
        ...mapActions({
            'setUserVerified': 'authenticate/setProfileVerified',
        }),
        handleUploadError(e) {
            toastr.error(this.$i18n.t('credits.error_processing'))
            console.log('Error uploading video', e)
            this.reset()
        },
        reset() {
            this.working = false
            this.$refs.upload.value = ''
            this.video = null
            this.uploadCompleted = 0
            this.showUploadVideo = false
        },
        track(e) {
            this.working = true
            this.uploadCompleted = Math.round((e.loaded * 100) / e.total)
        },
        async finish() {
            await this.load(this.pagination.page || 1)

            await setAuthenticatedUserItemCount()

            this.reset()
        },
        async set(e) {
            const [file] = e.target.files || e.dataTransfer.files

            if( ! file)
                return

            const extension = getExtensionFromFilename(file.name)

            if( ! ALLOWED_VIDEO_FORMATS.includes(extension)) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            if(file.size > MAX_VIDEO_FILE_SIZE) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_size', [null, 'Video']))
            }

            this.showUploadVideo = true
            this.working = true
            this.video = await createSelectedFile(file)
            this.working = false


        },

        async update(video, payload) {
            this.working = true

            try {
                await updateVideo(video.id, payload)
                await this.load(this.page)
                await setAuthenticatedUserItemCount()
            } catch (e) {
                console.log(e)
                console.log('Error updating video')
            }

            this.working = false
            this.modal.show = false
            this.modal.mode = null
            this.preview.show = false
            this.preview.index = 0
            this.preview.video = null

        },
        async del(video) {

            if( ! video)
                return

            this.working = true

            try {
                await deleteVideo(video.id)
                if(video.verification) {
                    await this.setUserVerified(null)
                }
                await this.load(this.page)
                await setAuthenticatedUserItemCount()
            } catch (e) {
                console.log(e)
                console.log('Error deleting video')
            }
            this.working = false
            this.modal.show = false
            this.modal.mode = null
            this.preview.show = false
            this.preview.index = 0
            this.preview.video = null

        },
        confirm(id, mode) {
            this.modal.mode = mode
            this.modal.show = true
        },
        async load(page = 1) {

            this.loading = true

            try {
                const { data } = await videoGallery({page: page})
                this.data.from = data.from
                this.data.to = data.to
                this.data.total = data.total
                this.videos = data.data
                this.page = data.current_page
                this.pagination = {
                    current_page: data.current_page,
                    last_page: data.last_page
                }
            } catch (e) {
                console.log(e)
            }

            this.loading = false

        }
    },
    created() {
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
    },
    mounted() {
        this.load()
    }

}
</script>

<style scoped lang="scss">

    .messages__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 0;

        .back-mob {
            margin-right: 10px;
            display: none;
            margin-top: 2px;
            padding: 10px;
        }

        h2 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -0.9px;
        }

        @media(max-width: 991px) {
            margin-bottom: 30px;

            .back-mob {
                display: block;
            }
        }
    }

    .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        pointer-events: none;
    }

    .gallery {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: calc(100% - 40px);
        height: auto;
        overflow: hidden;

        article {
            width: 100%;
            margin-bottom: 30px;
        }

        @media(max-width: 991px) {
            padding: 20px 0 100px;
            margin-top: 0;

            article {
                margin-bottom: 10px;
            }

            .nav-wrap {
                justify-content: center;

                a {
                    font-size: 16px;
                    width:155px;
                    height: 30px;
                    max-width: 100%;
                    letter-spacing: -0.66px;
                }
            }
        }
    }

    .modal {
        button {
            width: 100%;
        }

        h2 {
            font-size: 19px;
            line-height: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }

        @media(max-width: 500px) {

            .button-wrapper {
                justify-content: initial;
                width: 100%;
            }

            .button {
                flex: 1 1 100%!important;
            }
        }
    }

    .img-number {
        position: fixed;
        left: 30px;
        font-size: 20px;
        top: 30px;
        color: #fff;

        @media(max-width: 991px) {
            top: 25px;
            font-size: 16px;
            left: auto;
            right: 25px;
        }
    }

    .photo-status {
        height: 24px;
        border-radius: 12px;
        padding: 0 10px;
        background-color: rgba(#000, .7);
        color: #fff;
        font-size: 14px;
        margin-bottom: 20px;
        display: inline-flex;
        align-items: center;
        width: auto;

        strong {
            margin-left: 5px;
        }
    }

    .approved {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 180px;
        width: 100%;
        aspect-ratio: 1 / 1;
        transform: translate(-50%,-50%);
        background-color: rgba(#000, .6);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10;
        border-radius: 10px;
        padding: 0 10px;
        text-align: center;

        svg {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: #fff;
            line-height: 1.3;
            font-size: 14px;
            max-width: 100px;
        }
    }

    @media(max-width: 991px) {

        .btn-wrapper {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
            background-color: $color-type1;
            border-radius: 10px 10px 0px 0px;
            z-index: 999;
            display: flex;
            align-items: center;
            padding: 15px 20px;

            .button {
                width: 100%;

            }
        }
    }

    .converting-video {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }

    .pending-overlay {
        position: absolute;
        left: 0;
        z-index: 4;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#000, .6);
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }
    }

    .nav-wrap {
        height:100%;
        display: flex;
        align-items: center;

        .button--fourth {
            color: $color-type18;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            padding: 0 30px;
            width: auto;
            white-space: nowrap;

            &.router-link-active {
                color: $color-type1;
                background-color: #000000;
            }

            &:hover {
                color: $color-type1;
                background-color: #000000;
            }
        }
    }

    .img-list {
        margin-left: -2px;
        margin-right: -2px;
        display: flex;
        flex-wrap: wrap;

        .img-wrapper {
            margin: 0 2px 4px;
            width: calc(25% - 4px);
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            // padding-bottom: calc(25% - 4px);
            aspect-ratio: 1 / 1;
            cursor: pointer;

            &:hover {
                .footer-btn {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(0,0);

                }
            }

            video {
                position: absolute;
                left: 50%;
                top: 50%;
                min-width: 100%;
                width: auto!important;
                min-height: 100%;
                max-height: 100%;
                transform: translate(-50%,-50%);
                object-fit: cover;
            }

        }

        .status-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            position: absolute;
            z-index: 5;
            left: 6px;
            bottom: 6px;

            span {
                height: 23px;
                display: inline-flex;
                align-items: center;
                padding: 0 10px;
                margin-top: 4px;
                background-color: rgba($color-type18, .7);
                border-radius: 12px;
                color: $color-type1;
                font-size: 14px;
            }
        }
        .verification-box {
            position: absolute;
            background-color: rgba(44, 125, 219, 0.5);
            border-radius: 50%;
            left: 10px;
            top: 10px;
            padding: 5px;
            display: flex;
            align-items: center;
            z-index: 5;
        }

        @media(max-width: 991px) {
            margin-left: -2px;
            margin-right: -2px;

            .img-wrapper {
                margin: 0 2px 4px;
                width: calc(50% - 4px);
                border-radius: 0;
                padding-bottom: calc(50% - 4px);

            }

            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }

    .no-uploaded-images {
        text-align: center;
        overflow: hidden;
        width: calc(100% - 20px);
        margin: 0 auto;

        h1 {
            margin-bottom: 27px;
        }

        h2 {
            line-height: 1.6;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .img-wrapper {
            width: 100px;
            height: 100px;
            margin: 10px auto 30px;
        }

        @media(max-width: 991px) {
            .img-wrapper {
                width: 60px;
                height: 60px;
                margin: 10px auto;
            }

            h1 {
                font-size: 20px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 14px;
            }
        }
    }

    .gray-box {
        text-align: left;
        border-radius: 5px;
        padding: 35px 40px;
        background-color: $color-type3;
        color: $color-type18;

        h4 {
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0;
            line-height: 20px;
        }

        ol {
            list-style-type: numbers;
            padding-left: 17px;
            margin-bottom: 0;
        }

        @media(max-width: 991px) {
            padding: 15px 20px;

            ol {
                font-size: 14px;
                padding-left: 15px;

            }

            h4 {
                font-size: 14px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .show-fullsize-img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;

        svg {
            width: 30px;
            pointer-events: none;
        }
    }

    .footer-btn {
        z-index: 5;
        width: 100%;
        height: 53px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg,transparent 0%,#000000 100%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        transition: all .3s ease;
        transform: translate(0,100%);
        visibility: hidden;
        opacity: 0;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: $color-type1;
            width: 1px;
            height: 20px;

        }
        a {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: $color-type1;

            &:hover {
                color: $color-type20;
            }
        }

        @media(max-width: 500px) {
            visibility: visible;
            opacity: 1;
            transform: translate(0,0);
        }
    }

    .btn-wrapper {
        text-align: right;

        .button--primary {
            font-weight: 400;
        }
    }
</style>