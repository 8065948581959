import store from "@/store";
import { TYPE_ACCESS_REQUESTED, TYPE_FAVORITE, TYPE_MESSAGE , TYPE_ACCESS_APPROVED, TYPE_PROFILE_VISIT } from "@/types/bubble";
import router from "@/router";
import { setAuthenticatedUserItemCount } from "@/service/auth";
import toastr from "toastr";
import i18n from "@/i18n";
import { loadAuthenticatedUser } from "@/service/auth";
import {TYPE_GENDER_FEMALE} from "@/types/user";
import {
    TYPE_ACCESS_APPROVED as ITEM_ACCESS_APPROVED,
    TYPE_ACCESS_DECLINED as ITEM_ACCESS_DECLINED
} from "@/types/access";

export const addedToFavoritesListener = async payload => {
    if( ! store.state.notifications.social?.matches.admirers?.includes(payload.author.id)) {
        await store.dispatch('notifications/addFavorite', payload.author.id)
    }
    await store.dispatch('bubble/add', {
        type: TYPE_FAVORITE,
        id: `${TYPE_FAVORITE}_${payload.author.id}`,
        payload: {
            user: {
                ...payload.author,
                mutual: payload.mutual
            }
        }
    })

}

export const removedFromFavoritesListener = async payload => {

    if( store.state.notifications.social.matches.admirers.includes(payload.user.id)) {
        await store.dispatch('notifications/removeFavorite', payload.user.id)
    }
}

export const newMessageListener = async payload => {

    if(store.state.authenticate.auth.user.id !== payload.message.author_id) {
        await store.dispatch('notifications/addMessage', payload.message)
        if(router.currentRoute.value.name !== 'messages') {
            await store.dispatch('bubble/add', {
                type: TYPE_MESSAGE,
                id: `${TYPE_MESSAGE}_${payload.author.id}`,
                payload: {
                    user: payload.author,
                    message: payload.message
                }

            })
        }

    }

    await store.dispatch('messages/addMessage', payload.message)
}

export const newVisitorListener = async ({visitor}) => {
    await store.dispatch('notifications/addVisitor', visitor.id)
    await store.dispatch('bubble/add', {
        type: TYPE_PROFILE_VISIT,
        id: `${TYPE_PROFILE_VISIT}_${visitor.id}`,
        payload: {
            user: visitor,
        }
    })
}

export const newRequestListener = async (payload) => {
    await store.dispatch('notifications/addRequest', payload.author.id)
    await store.dispatch('bubble/add', {
        type: TYPE_ACCESS_REQUESTED,
        id: `${TYPE_ACCESS_REQUESTED}_${payload.author.id}`,
        payload: {
            user: payload.author,
            request: payload.request
        }
    })
}

export const accessResponseListener = async ({photoAccess}) => {

    if(photoAccess.status === ITEM_ACCESS_APPROVED) {

        await store.dispatch('notifications/addMyAccess', photoAccess.owner.id)

        await store.dispatch('bubble/add', {
            type: TYPE_ACCESS_APPROVED,
            id: `${TYPE_ACCESS_APPROVED}_${photoAccess.owner.id}`,
            payload: {
                user: photoAccess.owner,
                request: photoAccess
            }
        })

        if(photoAccess.system_approved_at) {
            await store.dispatch('access/add', photoAccess.owner.id)
        }

    } else if(photoAccess.status === ITEM_ACCESS_DECLINED) {

        await store.dispatch('notifications/removeMyAccess', photoAccess.owner.id)

    }

}

export const systemNotificationsListener = async ({payload: {type, user, payload}}) => {
    switch (type) {
        case 'email-verified':
            await store.dispatch('authenticate/setUser', user)
            await store.dispatch('profile/remove', 'email')
            break
        case 'item-rejected':
            await store.dispatch('items/addDeleted', payload.item_id)
            await setAuthenticatedUserItemCount()
            await store.dispatch('profile/add', 'photos')
            break
        case 'profile-verified':
            await store.dispatch('authenticate/setProfileVerified', user.profile_verified_at)
            toastr.success(i18n.global.t('profile.verification.is_verified'))
            break
        case 'profile-updated':
            await loadAuthenticatedUser()
            await Promise.all([
                store.dispatch('profile/add', 'password'),
                store.dispatch('profile/add', 'email'),
                store.dispatch('profile/add', 'location'),
                store.dispatch('profile/add', 'description')
            ])
            if(user.gender === TYPE_GENDER_FEMALE && ! store.state.authenticate.auth.user.profile_photo) {
                await store.dispatch('profile/add', 'profile_photo')
            }
            break
    }

}

export const itemReadyListener = async ({item}) => {

    if( ! item.ready)
        return

    if(item.attachment) {

        return await store.dispatch('messages/addAttachmentReady', item)

    }

    await store.dispatch('items/addReady', item)
}