<template>
    <Modal class="modal modal--mob" @close="closeHandler" :closable="! auth.user.migration_status">
        <template #body>
            <div class="o-flex o-flex--justify-center">
                <img src="@/assets/img/migration_logo.svg" width="200" class="mb"/>
            </div>
            <h2 style="line-height: 1.3" v-html="$t(`migration.${auth.user.migration_status}.title`)"></h2>
            <h6>
                {{$t(`migration.${auth.user.migration_status}.dear`, {username: auth.user.username})}},
            </h6>
            <p v-html="$t(`migration.${auth.user.migration_status}.first`)"></p>
            <p v-html="$t(`migration.${auth.user.migration_status}.second`)"></p>
        </template>
        <template #footer>
            <button class="button button--primary w-100 mb" @click="buttonClickHandler">{{$t(`migration.${auth.user.migration_status}.button`)}}</button>
            <small>
                {{$t(`migration.${auth.user.migration_status}.best`)}}<br />
                {{$t(`migration.${auth.user.migration_status}.team`)}}
            </small>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal.vue";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "PreActionMigration",
    mixins: [AuthenticatedMixin],
    components: {Modal},
    data() {
        return {
            time: 10,
            interval: null
        }
    },
    methods: {
        closeHandler() {

            if(this.auth.user.migration_status === 0) {
                this.$emit('success')
            }

        },
        buttonClickHandler() {
            if(this.auth.user.redirect_url) {
                location.replace(this.auth.user.redirect_url)
            } else {
                this.$emit('success')
            }
        }
    },
    mounted() {

        // if(this.auth.user.migration_status === 1) {
        //
        //     this.interval = setInterval(() => {
        //
        //         if(this.time < 1) {
        //             clearInterval(this.interval)
        //             return location.replace(this.auth.user.redirect_url)
        //         }
        //
        //         this.time -= 1
        //
        //     }, 1000)
        //
        //
        // }
    }
}
</script>

<style lang="scss" scoped>
p {
    line-height: 1.3;
}
</style>