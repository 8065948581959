<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <div v-if="!loading">
            <article class="info-box">
                <h1>{{$t('access.approved.title')}}</h1>
                <p>
                    {{$t('access.approved.text')}}
                </p>
            </article>

            <article class="list">
                <div v-if="approved.length">
                    <transition-group appear name="fade-in" tag="ul">
                        <li v-for="(a, index) in approved" :key="a.id" :style="'--animate:' + index">
                            <router-link :to="{name: 'user', params: {id: a.user.id}}">
                                <div class="user-info">
                                    <div class="img-wrapper">
                                        <img alt="user photo" :src="getUserPhotoURL(a.user, 'small')">
                                    </div>
                                    <OnlineStatus class="active-user" v-if="isOnline(a.user)" />
                                    <p class="mb0">
                                        <span class="o-flex o-flex--center">
                                            <Premium v-if="a.user.premium" />
                                            <Verified v-if="a.user.profile_verified_at" />
                                            <strong>{{a.user.username}}</strong>
                                        </span>
                                        <span>
                                            {{a.user.address}}
                                        </span>
                                    </p>
                                </div>
                            </router-link>

                            <button @click="update(a.id, TYPE_ACCESS_DECLINED, $event)" :disabled="a.system_approved_at && a.user.gender === TYPE_GENDER_MALE" class="button button--primary">{{$t('access.decline')}}</button>
                        </li>
                    </transition-group>
                </div>
            </article>
            <Pagination @page-click="load" :info="pagination" />
        </div>
    </div>
</template>

<script>
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import { getApproved, respond } from "@/api/access";
import Pagination from "@/components/generic/Pagination";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getPaginationPayload } from "@/service/general";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import { TYPE_GENDER_MALE } from "@/types/user";
import { TYPE_ACCESS_DECLINED } from "@/types/access";
import Verified from "@/components/generic/Verified.vue";
import Premium from "@/components/generic/Premium.vue";

export default {
    name: "Approved",
    components: {Premium, Verified, Pagination, ContentLoader, OnlineStatus},
    mixins: [AuthenticatedMixin],
    data() {
        return {
            loading: false,
            approved: [],
            pagination: null
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.approved = []
            const { data } = await getApproved({page: page})
            this.approved = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        },
        async update(id, status, $event) {
            $event.target.disabled = true
            try {
                await respond(id, status)
            } catch (e) {
                console.log('Error updating access status from approved page', e)
            }
            await this.load(this.pagination?.current_page || 1)
        }
    },
    created() {
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
        this.TYPE_ACCESS_DECLINED = TYPE_ACCESS_DECLINED
    },
    mounted() {
        this.load()
    }
}
</script>
<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>