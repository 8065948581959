<template>

    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <article class="no-data" v-if="!loading && !profiles.length">
            <div class="img-wrapper">
                <img alt="Image" width="100" height="100" src="@/assets/img/no_viewed.svg">
            </div>
            <h3>{{$t('matches.views.title')}}</h3><br>
            <p class="u-text-center">{{$t('matches.views.text')}}</p>
            <router-link class="button button--primary" :to="{name: 'search'}">{{$t('matches.search')}}</router-link>
        </article>
        <article class="list">
            <div v-if="profiles.length">
                <transition-group appear name="fade-in" tag="ul">
                    <li v-for="(p, index) in profiles" :key="p.id"  :style="'--animate:' + index">
                        <router-link :to="{name: 'user', params: {id: p.id}}">
                            <div class="user-info">
                                <div class="img-wrapper">
                                    <img alt="user photo" :src="getUserPhotoURL(p, 'small')">
                                </div>
                                <OnlineStatus class="active-user" v-if="isOnline(p)" />
                                <p class="mb0">
                                    <span class="o-flex o-flex--center">
                                        <Premium v-if="p.premium" />
                                        <Verified v-if="p.profile_verified_at" />
                                        <strong>{{p.username}}</strong>
                                    </span>
                                    <span>
                                        {{p.address}}
                                    </span>
                                </p>
                            </div>
                        </router-link>
                        <Favorite :profile="p" />
                    </li>
                </transition-group>
            </div>
        </article>
        <Pagination @page-click="load" :info="pagination" />
    </div>

</template>

<script>
import Pagination from "@/components/generic/Pagination";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import { getViewedProfiles } from "@/api/matches/viewed";
import Favorite from "@/components/search/Favorite";
import { getPaginationPayload } from "@/service/general";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";

export default {
    name: "Viewed",
    components: {Verified, Premium, Favorite, Pagination, ContentLoader, OnlineStatus},
    data() {
        return {
            loading: false,
            profiles: [],
            pagination: null
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.profiles = []
            const { data } = await getViewedProfiles({page: page})
            this.profiles = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        },
    },
    mounted() {
        this.load()
    }
}
</script>

<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>