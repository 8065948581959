import { allPromotions } from "@/api/credits";
import store from "@/store";
import { durationInSeconds } from "@/utilities/general";
import moment from "moment/moment";

class PromotionComponent {

    constructor(promotion, component, header) {
        this.promotion = promotion
        this.component = component
        this.header    = header
    }

}

const createPromotionComponent = promotion => {

    if(promotion?.default) {
        return new PromotionComponent(promotion, 'DefaultPromotion', 'DefaultPromotionHeader')
    }

    if(promotion?.name === "30 credits promotion" && store.state.authenticate.auth.user.flow === 'flow_1') {
        return new PromotionComponent(promotion, 'LimitedPromotionPackage', 'LimitedPromotionPackageHeader')
    }

    if(promotion?.name === "30 credits promotion" && store.state.authenticate.auth.user.flow === 'flow_2') {
        return new PromotionComponent(promotion, 'LimitedPromotionDays', 'LimitedPromotionDaysHeader')
    }

    if(promotion?.name === "30 credits old users" && store.state.authenticate.auth.user.flow === 'reactivation_promo_1') {
        return new PromotionComponent(promotion, 'LimitedPromotionDays', 'LimitedPromotionDaysHeader')
    }

    if(promotion?.name === "30 credits old users" && store.state.authenticate.auth.user.flow === 'reactivation_promo_2') {
        return new PromotionComponent(promotion, 'LimitedPromotionDays', 'LimitedPromotionDaysHeader')
    }

}

/**
 *
 * @param {Array} promotions
 * @returns {PromotionComponent}
 */
export const getDefaultPromotion = promotions => {

    const defaultPromotion = promotions.find(e => e.default)
    const outPromotion = defaultPromotion ?? promotions[0]

    if(store.state.credits.card) {

        return createPromotionComponent(outPromotion)

    }

    if(['flow_1', 'flow_2'].includes(store.state.authenticate.auth.user.flow)) {

        if(durationInSeconds(moment.utc(), moment.utc(store.state.authenticate.auth.user.created_at, 'YYYY-MM-DD HH:mm:ss').add('3', 'day')) > 0) {

            return createPromotionComponent(promotions.find(e => e.name === "30 credits promotion") ?? outPromotion)

        }

    }

    if(['reactivation_promo_2'].includes(store.state.authenticate.auth.user.flow)) {

        return createPromotionComponent(promotions.find(e => e.name === "30 credits old users") ?? outPromotion)

    }

    return createPromotionComponent(outPromotion)

}

export const setAvailablePromotions = async() => {

    const { data } = await allPromotions()

    await store.dispatch('promotions/setPromotions', data)

}