export default {

    namespaced: true,

    state: {
        bootstrapping: false,
        connected: false,
        mobile: false
    },

    mutations: {
        setBootstrapping(state, payload) {
            state.bootstrapping = payload
        },
        setConnected(state, payload) {
            state.connected = payload
        },
        setMobile(state, payload) {
            state.mobile = payload
        }
    },

    actions: {
        setBootstrapping({commit}, payload) {
            commit('setBootstrapping', payload)
        },
        setConnected({commit}, payload) {
            commit('setConnected', payload)
        },
        setMobile({commit}, payload) {
            commit('setMobile', payload)
        }
    },

    getters: {

    }

}