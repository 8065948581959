<template>
    <div v-if="conversation" class="chat-wrapper">
        <div class="chat-wrapper__header">
            <div class="user-info">
                <BackButton v-if="!singleChat" path="/messages" />
                <router-link :to="{name: 'user', params: {id: conversation.members.find(e => e.id !== auth.user.id).id}}" class="img-wrapper"><img :src="getUserPhotoURL(conversation.members.find(e => e.id !== auth.user.id), 'medium')"></router-link>
                <div class="user-info__content">

                    <h2>
                        <router-link :to="{name: 'user', params: {id: conversation.members.find(e => e.id !== auth.user.id).id}}">
                            <Premium v-if="conversation.members.find(e => e.id !== auth.user.id).premium" />
                            <Verified v-if="conversation.members.find(e => e.id !== auth.user.id).profile_verified_at" />
                            {{conversation.members.find(e => e.id !== auth.user.id).username}}, <span class="u-font-400">{{conversation.members.find(e => e.id !== auth.user.id).age}}</span>
                            <span v-if="isOnline(conversation.members.find(e => e.id !== auth.user.id))" class="active-user"></span>
                        </router-link>
                    </h2>
                    <p>{{conversation.members.find(e => e.id !== auth.user.id).address}}</p>
                </div>
            </div>
            <div class="dropdown-box">
                <a class="dropdown-trigger" @click="displayProfileOptions = true">
                    <svg width="36" viewBox="0 0 24 24" xml:space="preserve"><circle fill="#000000;" cx="12" cy="12" r="1.5"/><circle fill="#000000;" cx="12" cy="5" r="1.5"/><circle fill="#000000;" cx="12" cy="19" r="1.5"/></svg>
                </a>
            </div>
        </div>
        <div class="chat-wrapper__body" :class="{'is-active': chat.size}">
            <div ref="box" class="scroll-item" >
                <div class="info-bottom u-text-center" v-if="!conversation.system_approved_at && ! auth.user.gender">
                    <p>{{$i18n.t('messages.conversation.reveal.unlock_conversation', ['', price])}} {{conversation.members.find(e => e.id !== auth.user.id).username}} {{$t('messages.conversation.reveal.are')}} {{$t('messages.conversation.reveal.free')}}</p>
                    <button class="button button--primary" :disabled="inProgress" @click="unlockChatHandler">{{$i18n.t('general.unlock')}}</button>
                </div>
                <div v-if="chat.size" class="chat-wrapper__box" :style="{opacity: chat.size? 1: 0}">
                   <div class="u-text-center">
                       <button class="button button--third" :disabled="loading" @click="this.followMessages = false; $emit('load', {perPage: messages.per_page, page: messages.current_page + 1}); loading = true" v-if="messages?.next_page_url">{{loading ? $t('general.loading') : $t('messages.conversation.view_older')}}</button>
                   </div>

                    <div v-for="([date, values], k) in chat" :key="k">
                        <div class="time-line">
                           <span>{{date}}</span>
                        </div>
                        <div v-for="(message, key) in values" :key="message.id" :class="`message-row message-row--${message.author_id === this.auth.user.id ? 'host' : 'guest'} ${values[key - 1]?.author_id !== message.author_id ? '' : 'no-photo' }`">
                            <router-link :to="{name: 'user', params: {id: conversation.members.find(e => e.id !== auth.user.id).id}}" v-if="message.author_id !== this.auth.user.id && values[key - 1]?.author_id !== message.author_id" class="img-wrap">
                                <img :src="getUserPhotoURL(conversation.members.find(e => e.id !== auth.user.id), 'medium')" alt="User Photo">
                            </router-link>

                            <!-- TEMPORARY MESSAGE START -->
                            <TemporaryMessage v-if="message.type === TYPE_TEMPORARY" :message="message" />
                            <!-- TEMPORARY MESSAGE END -->

                            <ChatMessage v-else :data-message-id="message.id" :id="`message-${message.id}`" :class="{'pointer':  messageBoxPointer === message.id}"
                                        :conversation="conversation"
                                        :message="message"
                                        @unlock-request="unlockChatHandler"
                                        @select="chatAttachmentClickHandler"
                           />

                           <span class="time">{{moment.utc(message.created_at, 'YYYY-MM-DD HH:mm:ss').local().format('h:mm a')}}</span>

                            <div v-if="message.id === myMessageParticipantSeen" class="seen">
                                {{$t('messages.seen')}}
                                <svg viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="m11.602 13.76 1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412zm.002-2.828 4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L7.364 18 1 11.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z"/></svg>
                            </div>
                       </div>
                    </div>
                    <div v-if="isTyping" class="message-row message-row--guest">
                        <div class="img-wrap">
                            <img :src="getUserPhotoURL(conversation.members.find(e => e.id !== auth.user.id), 'medium')" alt="User Photo">
                        </div>
                        <div class="message-row__box message-row__box-typing">
                            <TypingAnimation/>
                        </div>
                   </div>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="!followMessages && messages.data?.length && conversation.system_approved_at" class="scroll-down">
                <button @click="localScrollDirection = 'end'; scrollToLast(true)" class="button">
                    <span v-if="unreadCount">{{unreadCount}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.001" height="14.004"><path data-name="Union 16" d="m13.708 7.715-6.039 6.027a1 1 0 0 1-1.379-.03l-6-6a1 1 0 0 1 0-1.417 1 1 0 0 1 1.413 0l4.295 4.3V1.004a1 1 0 0 1 1-1 1 1 0 0 1 1 1v9.59l4.3-4.292a1 1 0 0 1 1.412 0 1 1 0 0 1 .29.702 1 1 0 0 1-.292.711Z"/></svg>
                </button>
            </div>
        </transition>
        <input @change="selectAttachment" type="file" style="display: none" :accept="ALLOWED_IMAGE_FORMATS.map(e => `image/${e}`).join(', ').concat(', video/*')" ref="attachment" />
        <div class="chat-wrapper__footer" :class="file.preview ? 'is-active' : ''">
            <!-- chat-wrapper__footer is-active -->
            <div v-if="file.preview" class="attached-box">
                <div class="attached-wrap">
                    <div v-if="file.type === ITEM_TYPE_PHOTO" class="img-wrap" :style="{'background-image': 'url(' + this.file.preview + ')'}">
                        <a class="close">
                            <svg @click="file.source = null; $refs.attachment.value = ''; file.preview = null;" viewBox="0 0 32 32" width="12"><g data-name="Layer 2"><path d="M4 29a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l24-24a1 1 0 1 1 1.42 1.42l-24 24A1 1 0 0 1 4 29Z"/><path d="M28 29a1 1 0 0 1-.71-.29l-24-24a1 1 0 0 1 1.42-1.42l24 24a1 1 0 0 1 0 1.42A1 1 0 0 1 28 29Z"/></g><path fill="none" d="M0 0h32v32H0z"/></svg>
                        </a>
                    </div>
                    <div v-else class="video-wrap">
                        <Video :src="file.preview" :preload="mobile ? 'metadata' : null" />
                        <a class="close">
                            <svg @click="file.source = null; $refs.attachment.value = ''; file.preview = null;" viewBox="0 0 32 32" width="12"><g data-name="Layer 2"><path d="M4 29a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l24-24a1 1 0 1 1 1.42 1.42l-24 24A1 1 0 0 1 4 29Z"/><path d="M28 29a1 1 0 0 1-.71-.29l-24-24a1 1 0 0 1 1.42-1.42l24 24a1 1 0 0 1 0 1.42A1 1 0 0 1 28 29Z"/></g><path fill="none" d="M0 0h32v32H0z"/></svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="input-box mb0">
                <a @click="$refs.attachment.click()" class="attached">
                    <!-- <img alt="Image" src="@/assets/img/attachment.png"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24"><path data-name="Icon metro-attachment" d="m14.855 7.665-1.522-1.521-7.613 7.611a3.23 3.23 0 0 0 4.567 4.567l9.135-9.134a5.384 5.384 0 1 0-7.611-7.616l-9.591 9.59-.021.02a7.508 7.508 0 0 0 10.617 10.62l.019-.021 6.547-6.546-1.523-1.522-6.547 6.545-.02.02a5.354 5.354 0 0 1-7.572-7.57l.021-.02 9.593-9.59a3.23 3.23 0 1 1 4.568 4.567l-9.137 9.134a1.077 1.077 0 1 1-1.523-1.522l7.613-7.612Z"/></svg>
                </a>
                <div class="textarea-wrapper relative mr ml">
                    <textarea ref="text" rows="1" @keyup="broadcastTyping" :readonly="inProgress" @keydown.enter.prevent="sendMessage" v-model="text" class="input">
                                    </textarea>
                    <span class="placeholder" v-if="!text">{{$i18n.t('messages.conversation.write_a_message')}}</span>
                </div>
                <button @mousedown="sendMessageButtonHandler" :disabled="(text.length < 1 && ! this.file.source) || inProgress" type="submit" class="button button--primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21.993 22"><path data-name="Icon awesome-paper-plane" d="M20.448.139.536 11.626a1.032 1.032 0 0 0 .095 1.856l4.568 1.92L17.54 4.521a.258.258 0 0 1 .369.357L7.56 17.486v3.458a1.031 1.031 0 0 0 1.826.679l2.728-3.321 5.353 2.243a1.034 1.034 0 0 0 1.418-.782l3.093-18.561a1.031 1.031 0 0 0-1.53-1.063Z" fill="#fff"/></svg>
                </button>
            </div>

        </div>
        <small class="small-info" @click="infoTooltipText = ! infoTooltipText" v-click-outside="closeInfoTooltip">
            <svg width="20" viewBox="0 0 1792 1792"><path fill="#767473" d="M1152 1376v-160q0-14-9-23t-23-9h-96V672q0-14-9-23t-23-9H672q-14 0-23 9t-9 23v160q0 14 9 23t23 9h96v320h-96q-14 0-23 9t-9 23v160q0 14 9 23t23 9h448q14 0 23-9t9-23zm-128-896V320q0-14-9-23t-23-9H800q-14 0-23 9t-9 23v160q0 14 9 23t23 9h192q14 0 23-9t9-23zm640 416q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z"/></svg>
            <span :class="{'is-active':infoTooltipText}">{{$t('messages.conversation.tip')}}</span>
        </small>
        <Slider v-if="showSlider" :current="currentSlide" @close="showSlider = false; currentSlide = 0" :slides="slides">
            <template v-slot:content="{displaying}">
                <MediaSlider :slides="slides" :displaying="displaying" />
            </template>
        </Slider>
    </div>

    <transition name="fade" mode="out-in">
        <ProfileOptions @open="openUserDialog" v-if="displayProfileOptions" @close="displayProfileOptions = false" />
    </transition>

    <component @success="$router.push({name: 'settings-blocked-users'})" @close="userDialog = null" :user="participant" :is="userDialog" v-if="userDialog"></component>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import TypingAnimation from "@/components/messages/TypingAnimation";
import toastr from "toastr";
import { isOnline, getUserPhotoURL } from "@/service/user/profile";
import {handleInsufficientFundsError, unlockAccess} from "@/service/user";
import { groupMessages, sortMessages, readConversation as readConversationService } from "@/service/messages";
import moment from "moment";
import { connection } from "@/connection/websocket/connection";
import Slider from "@/components/generic/Slider";
import MediaSlider from "@/components/generic/MediaSlider";
import { TYPE_TEXT, TYPE_IMAGE, TYPE_ATTACHMENT, TYPE_TEMPORARY } from "@/types/message";
import { TYPE_GENDER_MALE } from "@/types/user";
import ProfileOptions from "@/components/user/ProfileOptions";
import ReportUserDialog from "@/components/user/ReportUserDialog";
import BlockUserDialog from "@/components/user/BlockUserDialog";
import { ucFirst } from "@/utilities/general";
import BackButton from "@/components/generic/BackButton";
import Image from "@/components/generic/Image";
import { ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO } from "@/types/item";
import {
    getExtensionFromFilename,
} from "@/service/files";
import {
    ALLOWED_IMAGE_FORMATS,
    ALLOWED_VIDEO_FORMATS, MAX_VIDEO_FILE_SIZE, MAX_IMAGE_FILE_SIZE,
    isVideo
} from "@/utilities/files";
import TemporaryMessage from "@/components/generic/TemporaryMessage";
import ChatMessage from "@/components/generic/ChatMessage";
import { mapActions, mapState } from "vuex";
import SendMessagePayload, {QUEUE_ACTION_SEND, QUEUE_ACTION_UPLOAD} from "@/service/messages/queue";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import Video from "@/components/generic/Video.vue";


export default {
    name: "ChatBox",
    components: {
        Verified,
        Premium,
        ChatMessage,
        BackButton, ProfileOptions, TypingAnimation, MediaSlider,
        Slider, ReportUserDialog, BlockUserDialog, Image, TemporaryMessage,
        Video
    },
    emits: ['load', 'unlocked', 'remove', 'uploading', 'unlock-started', 'upload-error', 'seen', 'unlock-failed'],
    mixins: [AuthenticatedMixin],
    props: {
        conversation: {
            type: Object,
            required: true
        },
        isTyping: {
            type: Boolean,
            default: false
        },
        messages: {
            type: Object
        },
        typingTimeout: {
            type: Number,
            default: 2000
        },
        singleChat: {
            type: Boolean,
            default: false
        },
        scrollDirection: {
            type: String,
            default: 'end'
        },
        lastMessage: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            text: '',
            inProgress: false,
            file: {
                source: null,
                preview: null,
                type: null,
            },
            showSlider: false,
            followMessages: true,
            currentSlide: 0,
            displayProfileOptions: false,
            userDialog: null,
            infoTooltipText: false,
            scrollTimeout: null,
            intersectionObserver: null,
            localScrollDirection: 'end',
            messageBox: [],
            messageBoxPointer: null,
            loading: false,
            myMessageParticipantSeen: null,
            lastMessageIdISaw: null,
        }
    },
    computed: {
        ...mapState({
            queue: state => state.messages.sendQueue,
            mobile: state => state.application.mobile
        }),
        chat() {
            const messages = this.messages?.data ?? []
            const queue = this.queue?.filter(e => ! messages.find(i => i.hash === e.hash)).filter(e => e.conversation_id === this.conversation.id) ?? []
            return groupMessages([...messages, ...queue])
        },
        slides() {
            if( ! this.messages.data)
                return []

            return sortMessages(this.messages.data.filter(e => e.type === TYPE_ATTACHMENT).map(e => e.attachment))
        },
        participant() {
            return this.conversation?.members.find(e => e.id !== this.auth.user.id)
        },
        unreadCount() {
            if( ! this.messageBox.length) return 0

            if( ! this.messageBox.find(e => e.id === this.messageBoxPointer)) return this.messageBox.length

            return this.messageBox.slice(this.messageBox.findIndex(e => e.id === this.messageBoxPointer) + 1).length
        }
    },
    watch: {
        lastMessage(value) {

            if( ! value || ! value.id)
                return

            this.$nextTick(() => {

                if(value.author_id !== this.auth.user.id) {

                    this.readConversationMessagesLocally(value.conversation_id)

                    if( ! this.messageBoxPointer) {
                        this.broadcastSeen(value.id)
                        this.lastMessageIdISaw = value.id
                        return this.setPointer(value.id)
                    }
                    return this.addToMessageBox({id: value.id, author_id: value.author_id, conversation_id: value.conversation_id})
                }

                // console.log('Setting pointer to the self message')
                this.messageBox.forEach(({id}) => this.removeFromMessageBox(id))
                this.setPointer(value.id)
            })

        },
        scrollDirection(value) {
            this.localScrollDirection = value
        },
        isTyping: {
            handler: function() {
                if( ! this.followMessages)
                    return
                this.$nextTick(() => this.scrollToLast(true))
            }
        },
        chat: {
            handler: function(current, previous) {

                this.loading = false

                if(current.size) {
                    this.$nextTick(this.reloadObserver)
                }

                if( ! this.followMessages)
                    return

                this.$nextTick(() => {

                    clearTimeout(this.scrollTimeout)

                    this.scrollTimeout = setTimeout(() => {
                        this.scrollToLast(!! previous.size)
                    }, 100)

                })

            }
        },
        conversation: {
            handler: function (value, previous) {
                if(previous) {
                    this.followMessages = true
                    this.messageBoxPointer = null
                    this.messageBox = []
                    this.intersectionObserver?.disconnect()
                    this.resetMessage()
                    this.unsubscribeFromConversationEvents(previous)
                }

                if(value) {
                    this.subscribeToConversationEvents(value)

                    if(value.system_approved_at) {
                        this.readConversationMessagesLocally(value.id)
                    }

                }
            },
            deep: true,
            immediate: true
        },

    },
    methods: {



        findFirstUnread() {

            return [...this.messages.data]?.reverse()?.find(e => e.author_id !== this.auth.user.id && e.id > this.lastMessageIdISaw)

        },

        readConversation({id, conversation_id}) {
            readConversationService({id, conversation_id})
            this.$emit('seen', {id, conversation_id})
        },

        subscribeToConversationEvents(conversation) {

            if(connection()) {
                console.log('subscribed to conversation events', conversation.id)
                connection().private(`conversation_events.${conversation.id}`).listenForWhisper('connected', payload => console.log('connected payload', payload))
                connection().private(`conversation_events.${conversation.id}`).listenForWhisper('seen', payload => {
                    if(payload.seen_by !== this.auth.user.id) {
                        this.myMessageParticipantSeen = payload.message_id
                    }
                })
            }

            conversation.members?.forEach(e => {
                if(e.id === this.auth.user.id)
                    this.lastMessageIdISaw = e?.pivot?.last_message_seen
                if(e.id !== this.auth.user.id)
                    this.myMessageParticipantSeen = e?.pivot?.last_message_seen
            })

        },

        unsubscribeFromConversationEvents(conversation) {

            if(connection()) {
                console.log('unsubscribed to conversation events', conversation.id)
                connection().private(`conversation_events.${conversation.id}`).stopListeningForWhisper('connected')
                connection().private(`conversation_events.${conversation.id}`).stopListeningForWhisper('seen')
            }

            if(conversation.system_approved_at && this.lastMessageIdISaw && (this.lastMessageIdISaw !== conversation.members.find(e => e.id === this.auth.user.id)?.pivot?.last_message_seen)) {

                this.readConversation({id: this.lastMessageIdISaw, conversation_id: conversation.id})

            }

        },

        reloadObserver() {
            document.querySelectorAll('.message-row__box').forEach(e => this.intersectionObserver.observe(e))
        },

        addToMessageBox(message) {
            this.messageBox = [
                ...this.messageBox,
                message
            ]
        },
        removeFromMessageBox(id) {

            if( ! this.messageBox.find(e => e.id === id))
                return

            this.broadcastSeen(id)

            this.lastMessageIdISaw = id

            this.messageBox = this.messageBox.filter(e => e.id !== id)

        },
        broadcastSeen(id) {
            if(connection()) {
                connection().private(`conversation_events.${this.conversation.id}`)
                    .whisper('seen', {message_id: id, seen_by: this.auth.user.id})
            }
        },
        setPointer(id) {


            if(id === this.messageBoxPointer) {
                // console.log('will not set pointer with same value', value, this.messageBoxPointer)
                return
            }

            this.messageBoxPointer = id
            // console.log('pointer set to', value)
            this.removeFromMessageBox(id)
        },
        ucFirst,
        chatAttachmentClickHandler(message) {
            this.currentSlide = this.slides.findIndex(e => e.id === message.attachment.id);
            this.showSlider = true
        },
        resetMessage() {
            if(this.$refs?.attachment) {
                this.$refs.attachment.value = ''
            }
            this.text = ''
            this.file = {
                source: null,
                preview: null,
                type: null
            }
        },
        ...mapActions({
            enqueueMessage: "messages/enqueueMessage",
            readConversationMessagesLocally: "notifications/readConversation"
        }),
        closeInfoTooltip() {

            this.infoTooltipText = false
        },

        openUserDialog({type}) {


            this.displayProfileOptions = false

            switch (type) {
                case 'block':
                    return this.userDialog = 'BlockUserDialog'
                case 'report':
                    return this.userDialog = 'ReportUserDialog'
            }


        },

        scrollToLast(smooth = false) {

            let options = {
                block: this.localScrollDirection
            }

            if(smooth){
                options.behavior = 'smooth'

            }

            if(this.$refs?.box)
                this.$refs.box.scrollIntoView(options);
        },
        isOnline,
        getUserPhotoURL,
        selectAttachment(e) {

            this.file.type = isVideo(getExtensionFromFilename(e.target.files[0].name)) ? ITEM_TYPE_VIDEO : ITEM_TYPE_PHOTO
            this.file.source = e.target.files[0]
            this.file.preview = URL.createObjectURL(this.file.source)

        },
        async sendMessageButtonHandler(event) {
            if( ! this.file.source) {
                event.preventDefault()
            }
            await this.sendMessage()
        },
        async sendMessage() {

            if((this.text.length < 1 && ! this.file.source) || this.inProgress)
                return

            const text = this.text,
                file = {...this.file},
                item_id = null,
                conversation_id = this.conversation.id

            let extension = null

            try {

                if( this.auth.user.gender === TYPE_GENDER_MALE && ! this.conversation.system_approved_at) {

                    this.inProgress = true
                    await this.unlockChatHandler()
                    this.inProgress = false

                }

                this.resetMessage()

                this.followMessages = true

                let action = QUEUE_ACTION_SEND

                if(file.source) {

                    extension = getExtensionFromFilename(file.source.name)

                    if( ! ALLOWED_IMAGE_FORMATS.includes(extension) && ! ALLOWED_VIDEO_FORMATS.includes(extension)) {
                        this.$refs.attachment.value = ''
                        return toastr.error(this.$i18n.t('form_fields.validation.file_type', [null, extension]))

                    }

                    if(isVideo(extension) && file.source.size > MAX_VIDEO_FILE_SIZE) {

                        this.$refs.attachment.value = ''
                        return toastr.error(this.$i18n.t('form_fields.validation.file_size', [null, 'Video']))

                    }

                    if( ! isVideo(extension) && file.source.size > MAX_IMAGE_FILE_SIZE) {

                        this.$refs.attachment.value = ''
                        return toastr.error(this.$i18n.t('form_fields.validation.file_size', [null, 'Photo']))
                    }

                    action = QUEUE_ACTION_UPLOAD

                }

                await this.enqueueMessage(new SendMessagePayload(
                    `${conversation_id}` + `${Date.now()}`,
                    moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                    this.auth.user.id,
                    text,
                    false,
                    0,
                    conversation_id,
                    file,
                    extension,
                    item_id,
                    action
                ))


            } catch(e) {
                console.log('error', e)

                if(e.paymentRequired) {
                    return await handleInsufficientFundsError()
                }

                this.text = text
                this.file = {...file}
                toastr.error(this.$i18n.t('credits.error_processing'))
            }
        },
        async unlockChat() {
            const conversation = await unlockAccess(this.conversation.members.find(e => e.id !== this.auth.user.id).id, TYPE_TEXT)
            this.$emit('unlocked', conversation)
        },
        async unlockChatHandler() {
            try {
                this.inProgress = true
                this.$emit('unlock-started')
                await this.unlockChat()
            } catch (e) {
                if(e.paymentRequired) {
                    this.$emit('unlock-failed')
                    return await handleInsufficientFundsError()
                }
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.inProgress = false
            }
        },
        broadcastTyping() {

            if(connection()) {

                if(this.typingBroadcaster)
                    return

                this.typingBroadcaster = true

                connection().private(`conversation_events.${this.conversation.id}`)
                    .whisper('typing', {
                        conversation_id: this.conversation.id,
                        user_id: this.auth.user.id
                    })

                setTimeout(() => this.typingBroadcaster = false, this.typingTimeout)

            }

        }
    },
    created() {
        this.TYPE_IMAGE = TYPE_IMAGE
        this.TYPE_ATTACHMENT = TYPE_ATTACHMENT
        this.TYPE_TEMPORARY = TYPE_TEMPORARY
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
        this.price = process.env.VUE_APP_UNLOCK_CONVERSATION
        this.moment = moment
        this.ITEM_TYPE_PHOTO = ITEM_TYPE_PHOTO
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
        this.ALLOWED_IMAGE_FORMATS = ALLOWED_IMAGE_FORMATS
        this.localScrollDirection = this.scrollDirection
    },
    mounted() {

        this.intersectionObserver = new IntersectionObserver(entries => {

            entries.forEach(e => {

                const messageId = parseInt(e.target.dataset.messageId)

                if(messageId === this.messageBoxPointer) {
                    this.followMessages = !! e.isIntersecting
                }

                if(e.isIntersecting) {

                    this.messageBox.filter(e => e.id === messageId).forEach(e => this.removeFromMessageBox(e.id))

                    if(this.messageBoxPointer < messageId) {
                        this.setPointer(messageId)
                    }

                }

            })

        }, {
            threshold: 1
        })
    },
    beforeUnmount() {
        this.unsubscribeFromConversationEvents(this.conversation)
    }
}
</script>

<style scoped lang="scss">

    .small-info {
        font-weight: 300;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #000;
        line-height: 1;
        padding: 0 30px;
        pointer-events: none;

        svg {
            margin-right: 3px;
            min-width: 20px;
        }

        @media(max-width: 991px) {
            pointer-events: auto;
            padding: 0 10px;
            margin-bottom: 10px;
            line-height: 1.3;
            display: inline-flex;
            align-items: flex-start;
            position: absolute;
            z-index: 9999;
            width: auto;
            bottom: 70px;
            left: 0;

            svg {
                min-width: 20px;
                margin-right: 10px;
                cursor: pointer;
            }

            span {
                width: 120px;
                background-color: rgba(0, 0, 0, 0.72);
                color: rgb(255, 255, 255);
                border-radius: 6px;
                position: absolute;
                z-index: 1;
                bottom: 150%;
                left: 10px;
                line-height: 1;
                min-width: 50vw;
                padding: 10px;
                text-align: left;
                font-size: 12px !important;
                font-weight: 600;
                transition: all .3s ease;
                opacity: 0;
                visibility: visible;
                pointer-events: none;

                &:before {
                    position: absolute;
                    content:"";
                    left: 4px;
                    top: 100%;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid rgba(0, 0, 0, 0.72);
                }

                &.is-active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .dropdown-box {
        position:relative;
    }

    .attached-box {
        background-color: $color-type3;
        margin-bottom: -1px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        height: auto;
        transition: max-height 0.5s cubic-bezier(0,1,0,1);
        max-height: 0;
        overflow: hidden;

        .attached-wrap {
            padding: 20px;
            display: flex;
            justify-content: flex-start;

        }

        .video-wrap {
            position: relative;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 8px 0px;
            background-color: #000;
            width: 25%;
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;

            video {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }

            .close {
                position: absolute;
                right: 5px;
                top: 5px;
                padding: 5px;
                transition: all .3s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background-color: rgba(#222, .8);
                g {
                    path {
                        fill: $color-type1;
                    }
                }

                path {
                    transition: all .3s ease-in-out;
                }

                &:hover {
                background-color:$color-type16;

                    g {
                        path {
                            fill: $color-type20;
                        }
                    }
                }
            }
        }

        .img-wrap {
            width: 25%;
            padding-bottom: 25%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            position: relative;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 8px 0px;

            .close {
                position: absolute;
                right: 5px;
                top: 5px;
                padding: 5px;
                transition: all .3s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background-color: rgba(#222, .8);
                g {
                    path {
                        fill: $color-type1;
                    }
                }

                path {
                    transition: all .3s ease-in-out;
                }

                &:hover {
                background-color:$color-type16;

                    g {
                        path {
                            fill: $color-type20;
                        }
                    }
                }
            }
        }
    }

    .user-info {
        display: flex;
        align-items: center;

        .back-mob {
            display: none;
        }

        .img-wrapper {
            border-radius: 50%;
            margin-right: 15px;
            width: 60px;
            height: 60px;
            overflow: hidden;

            @media(max-width: 991px) {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
        }

        @media(max-width: 991px) {
            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    .chat-wrapper {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 190px);
        position: relative;

        @media(max-width: 991px) {
            height: 100%;
            overflow: auto;
            padding-bottom: 0;
        }
    }

    .chat-wrapper__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 30px;


        h2 {
            margin-bottom: 5px;
            font-size: 16px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 3px;
            }

            .active-user {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-type25;
                margin-left: 10px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }

        @media(min-width: 992px) {
            .img-wrapper {
                display: none;
            }
        }

        @media(max-width: 991px) {
            padding: 15px 10px;
        }
    }

    .chat-wrapper__body {
        height: 100%;
        position: relative;
        overflow: auto;

        .scroll-item {
            transition: all .3s ease;
            min-height: 102%;
            padding: 25px 30px;
            position: relative;
            display: flex;
            flex-direction: column-reverse;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 50px;
            background-color: #e2e2e2;
            width: 4.5px;
        }

        &::-webkit-scrollbar-thumb:horizontal{
            background-color: #e2e2e2;
            border-radius: 50px;
        }

        & {
            scrollbar-color: #e2e2e2 #e2e2e2;
            scrollbar-width: thin;
        }

        &.is-active {
            &::-webkit-scrollbar-thumb {
                border-radius: 50px;
                background-color: #222;
                width: 4.5px;
            }

            &::-webkit-scrollbar-thumb:horizontal{
                background-color: #222;
                border-radius: 50px;
            }

            & {
                scrollbar-color: #222 #e2e2e2;
                scrollbar-width: thin;
            }
        }

        @media(max-width: 991px) {
            max-height: 100%;
            .scroll-item {
                padding: 15px 10px 0;
            }
        }
    }

    .chat-wrapper__footer {
        padding: 25px 30px 10px;

        &.is-active {
            .attached-box {
                transition: max-height 0.5s cubic-bezier(1,0,1,0);
                max-height: 99999px;
            }

            .input-box {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                transition: all .1s ease-in 0s;
            }
        }

        .input-box {
            background-color: $color-type3;
            padding-left: 20px;
            padding-right: 12px;
            border-radius: $textarea-chat;
            display: flex;
            align-items: center;
            transition: all .3s ease-in .3s;
            padding: {
                top: 10px;
                bottom: 10px;
            }

        }

        .textarea-wrapper {
            width: 100%;

            span {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0,-50%);
                font-style: italic!important;
                color: $color-type7;
                pointer-events: none;
            }
        }

        textarea.input {
            background-color: $color-type3;
            border: none!important;
            min-height: 40px;
            height: auto;
            align-items: flex-start;
            resize: none;
            width: 100%;
            padding: 10px 0 5px 0;

            &:focus {
                & + span {
                    display: none;
                }
            }
        }

        .attached {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .button--primary {
            font-size: 14px;
            border-radius: 50%;
            padding: 0;
            width: 40px;
            min-width: 40px;

            svg {
                margin-left: -3px;
            }
        }

        @media(max-width: 991px) {
            padding: 10px;
            position: relative;
            z-index: 99999;
        }
    }
    .message-row {
        display: flex;
        padding-bottom: 12px;

        .message-row__box {
            border-radius: 20px;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            font-size: 16px;
            line-height: 1.3;
            color: $color-type18;
            display: inline-flex;
            max-width: calc(100% - 41px);
            overflow: hidden;
            flex-wrap: wrap;
            min-width: 45px;
            overflow-wrap: anywhere;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);

            &.locked {
                width:36%;
                text-align: left;
                position: relative;
            }

            .locked-wrap {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 85%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $color-type1;
                background-color: #000;
                border-bottom-left-radius: 20px;

                >div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                @media(max-width: 400px) {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }

        .time {
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            color: $color-type8!important;
            width: 100%;
            margin-top: 5px;
            height: 12px;
        }
    }

    .message-row--host {
        align-items: flex-end;
        flex-direction: column;
        .message-row__box {
            text-align: left;
            align-items: flex-end;
            justify-content: flex-end;
            background-color: $color-type18;
            color: $color-type1;
            border-bottom-right-radius: 2px;
        }


        .text-wrap {
            width: 100%;
        }
    }

    .message-row--guest {
        flex-wrap: wrap;

        &.no-photo {
            margin-left: 41px;

            .time {
                padding-left: 0;
            }
        }

        .time {
            justify-content: flex-start;
            padding-left: 41px;
        }

        .message-row__box {
            background-color: $color-type3;
            border-top-left-radius: 2px;
            display: flex;
            align-items: flex-start;

        }

        .img-wrap {
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 5px;
            overflow: hidden;
        }
    }

    .message-row__box-img {
        padding: 0!important;
        position: relative;
        width: 100%;
        background-color: transparent!important;
        overflow: initial!important;
        border-radius: 0!important;
        flex-direction: column;
        align-items: flex-end;

        & + .time {
            padding-left: 41px;
        }

        &.locked {
            width: 100%!important;
            max-width: 240px!important;
            position: relative;

            .time {
                background-image: none;
            }
        }

        .locked-wrap {
            width: 100%!important;

            svg {
                margin-bottom: 15px;
            }
        }

        img {
            min-height: 100%;
        }
    }

    .message-row__box-img-text {
        padding: 0!important;
        background-color: transparent!important;
        border-bottom-left-radius: 0!important;
    }

    .message-row__box-temporary-status {
        padding: 0!important;
        background-color: transparent!important;

    }


    .seen {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 12px;
        height: 18px;
        color: $color-type12;
    }

    .time-line {
        margin-top: 28px;
        margin-bottom: 34px;
        display: flex;
        justify-content: center;
        position: relative;
        &:before {
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            top: 50%;
            transform: translate(0,-50%);
            content:"";
            background-color: $color-type3;

        }

        span {
            position: relative;
            z-index: 2;
            background-color: $color-type1;
            font-size: 12px;
            padding: 0 18px;
            color: $color-type12;
        }
    }

    .info-bottom {
        width: 100%;
        padding: 20px 30px;
    }

    .message-row__box-typing {
        align-items: center !important;
        justify-content: center;
    }

    .scroll-down {
        position: absolute;
        right: 42px;
        bottom: 120px;
        z-index: 5;

        button {
            width: 40px;
            height: 40px;
            background-color: #DEDEDE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            span {
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translate(-50%,50%);
                height: 16px;
                background-color: #E93D60;
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding: 0 5px;
                color: #fff;
                font-size: 10px;
                font-weight: 700;
            }
        }

        @media(max-width: 991px) {
            right: 22px;
            bottom: 80px;
        }
    }


</style>