import axios from "axios";

export const getCredits = () => {
    return axios.get('user/balance')
}

export const defaultPromotion = () => {
    return axios.get('promotion/default')
}

export const allPromotions = () => {
    return axios.get('promotion/available')
}