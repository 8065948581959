import Echo from "laravel-echo";
window.io = require('socket.io-client');
import store from "@/store";

let echo = null

export const connect = () => {
    if( ! echo ) {
        echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_WEBSOCKET_URL,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + store?.state.authenticate.auth.token
                },
            },
        });

        return new Promise(resolve => {
            echo.connector.socket.on('connect', () => {
                store.commit('application/setConnected', true)
                resolve(echo)
            })
            echo.connector.socket.on('disconnect', () => {
                store.commit('application/setConnected', false)
            })
        })

    }

    if(!echo.connector.socket.connected) {

        return new Promise(resolve => {
            echo.connector.socket.on('connect', () => {
                store.commit('application/setConnected', true)
                resolve(echo)
            })
            store.commit('application/setConnected', false)
        })

    }



    return Promise.resolve(echo)


}

export const connection = () => echo

export const disconnect = () => {
    if(echo) {
        echo.disconnect()
        echo = null
    }
}

export const addedToFavorites = (connection) => {
    return connection.private(`added_to_favorites.${store.state.authenticate.auth.user.id}`)
}

export const removedFromFavorites = (connection) => {
    return connection.private(`removed_from_favorites.${store.state.authenticate.auth.user.id}`)
}

export const newMessage = (connection) => {
    return connection.private(`message_to_user.${store.state.authenticate.auth.user.id}`)
}

export const newVisitor = connection => {
    return connection.private(`profile_visit.${store.state.authenticate.auth.user.id}`)
}

export const newRequest = connection => {
    return connection.private(`access_request.${store.state.authenticate.auth.user.id}`)
}

export const accessResponse = connection => {
    return connection.private(`access_response.${store.state.authenticate.auth.user.id}`)
}

export const systemNotifications = connection => {
    return connection.private(`user.${store.state.authenticate.auth.user.id}.system`)
}

export const itemReady = connection => {
    return connection.private(`user.${store.state.authenticate.auth.user.id}.item_updated`)
}