import {autoLoginUser, logout} from "@/service/auth";
import store from "@/store";

export const autoLoginRouteHandler = async (to, from, next) => {

    try {

        await store.dispatch('application/setBootstrapping', true)

        await autoLoginUser(to.query.auto)

        delete to.query.auto
        return next(to)

    } catch {

        await logout()

        await store.dispatch('application/setBootstrapping', false)

        return next({name: "login"})
    }

}