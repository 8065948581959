<template>
    <div class="o-container">
        <section class="verification">
            <article  v-if="loaded && auth.user.profile_verified_at === null">

                <div  class="verified-box">
                    {{$t('profile.verification.get_verified')}}
                    <svg class="verified-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
                </div><br>
                <input @change="upload" accept="video/*" ref="upload" type="file" style="display: none">
                <p>
                    <strong>{{$t('profile.verification.prove_yourself')}}</strong><br>
                    {{$t('profile.verification.be_trusted')}}<br>
                    {{$t('profile.verification.a_minute')}}
                </p>
                <div v-show="!verification_video_sent">
                    <h2 class="h1"> {{$t('profile.verification.title')}} </h2><br>
                    <p>
                        {{$t('profile.verification.video_yourself')}}:<br>
                        <strong>"{{$t(`profile.verification.your_sugar${this.auth.user.gender ?  'babby' : 'daddy'}.${theme}`)}}"</strong>
                    </p>
                    <div class="btn-wrapper">
                        <button :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{$t('profile.verification.get_verified')}}</button>
                    </div>
                </div>
                <div v-show="verification_video_sent">
                    <h3>{{$t('profile.verification.video_sent')}}</h3>
                </div>
            </article>

            <article v-if="loaded && auth.user.profile_verified_at !== null" class="verified">

                <img src="@/assets/img/verified-icon.svg" alt="verified icon"/>

                <h1>
                    {{$t('profile.verification.great_news')}}<br/>
                    {{$t('profile.verification.is_verified')}}
                </h1>
                <p>{{$t('profile.verification.description')}}</p>

            </article>

            <Modal :closable="false" v-if="working">
                <template #header>
                    {{$t('general.wait')}}
                </template>
                <template #body>
                    <ProgressBar :completed="uploaded" />
                </template>
            </Modal>
        </section>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { videoGallery } from "@/api/profile";
import ThemeMixin from "@/mixins/ThemeMixin";
import {ALLOWED_VIDEO_FORMATS, MAX_VIDEO_FILE_SIZE} from "@/utilities/files";
import { getExtensionFromFilename, uploadToBucket } from "@/service/files";
import toastr from "toastr";
import { createPreSignedURL, createItem } from "@/api/files";
import Modal from "@/components/generic/Modal";
import ProgressBar from "@/components/ProgressBar";
import { ITEM_TYPE_VIDEO } from "@/types/item";

export default {
    name: "VerificationPage",
    mixins: [AuthenticatedMixin, ThemeMixin],
    components: {Modal, ProgressBar},
    data() {
        return {
            loaded: false,
            working: false,
            verification_video_sent: false,
            uploaded: 0
        }
    },
    methods: {
        async upload(e) {

            const files = e.target.files || e.dataTransfer.files

            if( ! files.length)
                return

            const extension = getExtensionFromFilename(files[0].name)

            if( ! ALLOWED_VIDEO_FORMATS.includes(extension)) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            if(files[0].size > MAX_VIDEO_FILE_SIZE) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_size', [null, 'Video']))
            }

            this.working = true

            try {

                const { data } = await createPreSignedURL(extension)

                await uploadToBucket(
                    data,
                    files[0],
                    progress => this.uploaded = Math.round((progress.loaded * 100) / progress.total)
                )

                await createItem({
                    file: data.filename,
                    type: ITEM_TYPE_VIDEO,
                    private: 0,
                    verification: 1
                })

                this.verification_video_sent = true

            } catch {

                console.log('Error uploading video')

            }

            this.$refs.upload.value = ''

            this.working = false
        }
    },
    async mounted() {

        try {
            const { data } = await videoGallery({private: 0, verification: 1})
            if(data.data.length > 0) {
                this.verification_video_sent = true
            }
        } catch (e) {
            console.log(e)
            console.log('Error fetching verification video')
        }
        this.loaded = true

    }
}
</script>

<style scoped lang="scss">
    .verification {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: calc(100% - 40px);
        height: auto;
        overflow: hidden;

        .verified-box {
            background-color: rgba(#2C7DDB, .5);
            height: 44px;
            border-radius: 22px;
            padding: 0 8px 0 14px;
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            width: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .verified-icon {
                margin-left: 8px;
            }
        }

        h2 {
            display: inline-flex;
            align-items: center;

            img {
                margin-right: 6px;
            }
        }

        article {
            width: 100%;
            max-width: 680px;
            margin: 0 auto 50px;
            text-align: center;
        }

        p {
            font-size: 18px;
            line-height: 1.6;
            padding: 20px 0;
            margin-bottom: 30px;
        }

        @media(max-width: 991px) {
            padding: 20px 0 70px;
        }

        @media(max-width: 500px) {
            p {
                font-size: 16px;
            }

            .btn-wrapper {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 10px 20px;
                background-color: #fff;
                z-index: 9999;

                .button {
                    width: 100%;
                }
            }
        }
    }

    @-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
    }
    @keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
    }

    .verified {
        height: 100%;
        padding: 100px 0;
        margin-bottom: 0;

        h1 {
            margin:0 auto 20px;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.3;
            max-width: 300px;
        }

        p {
            padding: 0;
            line-height: 1.4;
            max-width: 300px;
            margin:0 auto;
        }

        img {
            margin-bottom: 20px;
        }
    }
</style>