export const editableData = [
    'ethnicity',
    'smoking',
    'body_type',
    'age',
    'password',
    'latitude',
    'longitude',
    'city',
    'state',
    'country',
    'address',
    'needs',
    'description',
    'greeting',
    'timezone',
    'hair_color',
    'piercing',
    'height',
    'eye_color',
    'tattoo',
    'relationship',
    'net_worth',
    'drinking',
    'income',
    'education',
    'interested_in',
    'offers'
]

export const GIRL_MIN_PUBLIC_PHOTOS = 3
export const GIRL_MIN_SECRET_PHOTOS = 2

export const GIRL_MIN_PHOTOS = 4