<template>
    <div class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="h1"> {{ $t('payment.limited_offer_1.limited') }}</h2>
        </div>
        <h2 class="clr-green h1 mb-">{{ $t('promotions.limited_promotion.limited_promo', {days: 3}) }}</h2>
        <p>{{ $t('payment.limited_offer_1.grab') }}</p>
        <div class="time-left-box">
            <Review/>
            <div class="timing o-flex o-flex--end">
                <img src="../../assets/img/clock.svg" alt="3 days left">
                <div class="numb">
                    {{ daysLeft }}
                </div>
                <div class="text">
                    <div class="days">
                        <span>Day{{ daysLeft !== 1 ? 's' : '' }}</span>
                    </div>
                    <div class="left">
                        <span>Left</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from "@/components/generic/BackButton.vue";
import Review from "@/components/generic/Review.vue";
import {daysHoursMinutesSecondsSince} from "@/utilities/general";
import moment from "moment/moment";

export default {
    name: "LimitedPromotionDaysHeader",
    components: {Review, BackButton},
    props: {
        promotion: {
            type: Object
        }
    },
    data() {
        return {
            daysLeft: 0
        }
    },
    mounted() {
        this.timeRemaining = daysHoursMinutesSecondsSince(moment.utc(), moment.utc(this.promotion.valid_until))

        this.daysLeft = parseInt(this.timeRemaining.split(':')[0])
    }
}
</script>

<style scoped lang="scss">
    .price__heading-box {
        display: none;
        align-items: center;
        margin-bottom: 10px;

        .back-button {
            margin-right: 20px;
            display: none;
        }

        @media(max-width: 991px) {
            margin-bottom: 25px;
            display: flex;

            .back-button {
            display: flex;
            }
        }
    }

    .time-left-box {
        display: flex;
        align-items: center;

        @media(max-width: 991px) {
            justify-content: space-between;
        }
    }

    .timing {
        position: relative;

        img {
            position: relative;
            z-index: 5;
        }

        .numb {
            position: absolute;
            z-index: 9;
            left: 0;
            bottom: 0;
            height: calc(100% - 5px);
            aspect-ratio: 0.95/1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #68a99b;
            font-weight: 700;
            font-size: 34px;
        }

    }
    .text {
        transform: translate(-30px, -1px);
    }

    .days,
    .left {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        height: 22px;
        clip-path: polygon(0 0, 100% 0%, calc(100% - 8px) 100%, 0% 100%);
        padding: 0 10px 0 30px;
        display: flex;
        align-items: center;
    }

    .days {
        background-color: #3E3E3D;
    }
    .left {
        background-color: #68A99B;
    }
</style>