<template>
    <div class="price">
        <div class="price__boxes">
            <div @click="packageClickHandler(p)" style="cursor: pointer" v-for="(p, key) in packages" :key="key"
                 class="price__item" :class="{'price__item--promotion': !key}">
                <div v-if="key === 0">
                    <div class="promotion-heading">
                        {{ $t('payment.limited_offer_1.special') }}
                        <span>{{ $t('payment.limited_offer_1.high') }}</span>
                    </div>
                    <div class="promotion-body">
                        <div class="price__item-row">
                            <h2 class="heading"></h2>
                            <div class="price-box">
                                <small>{{ p.currency.toUpperCase() }}</small><strong>{{ p.price.toFixed(2) }}</strong>
                            </div>
                        </div>
                        <div class="price__item-row">
                            <p class="line">{{ Number(p.price * 15).toFixed(2) }} {{ p.currency.toUpperCase() }}</p>
                            <div class="divider"></div>
                            <div class="credits-box">
                                <h3>{{ p.credits }} {{ $t('credits.credits') }}</h3>
                                <p class="mobile-show">{{ $t('payment.limited_offer_1.regular') }}</p>
                            </div>
                        </div>
                        <div class="price__item-row desktop-show">
                            <h4 class="clr-red">
                                <svg class="mr-- clock" xmlns="http://www.w3.org/2000/svg" width="17" height="20"
                                     viewBox="0 0 17.143 20">
                                    <path fill="#d61a1a"
                                          d="M11.429 0H5.714v1.9h5.714Zm-3.81 12.381h1.9V6.667h-1.9Zm7.648-6.3 1.352-1.352a10.522 10.522 0 0 0-1.343-1.339l-1.352 1.353a8.571 8.571 0 1 0 1.343 1.343Zm-6.7 12.01a6.667 6.667 0 1 1 6.667-6.667A6.662 6.662 0 0 1 8.571 18.1Z"
                                          data-name="Icon material-timer"/>
                                </svg>
                                <span>{{ timeRemaining }}</span>
                            </h4>
                        </div>
                        <div class="price__item-row price__item-row--btn-wrapper">
                            <button class="button button--primary">{{ ucFirst($t('credits.buy_credits')) }}</button>
                        </div>
                        <div class="price__item-row price__item-row-promo">
                            <div class="divider"></div>
                            <h4 class="o-flex o-flex--center clr-red mb0">
                                <svg class="mr- clock" xmlns="http://www.w3.org/2000/svg" width="17" height="20"
                                     viewBox="0 0 17.143 20">
                                    <path fill="#d61a1a"
                                          d="M11.429 0H5.714v1.9h5.714Zm-3.81 12.381h1.9V6.667h-1.9Zm7.648-6.3 1.352-1.352a10.522 10.522 0 0 0-1.343-1.339l-1.352 1.353a8.571 8.571 0 1 0 1.343 1.343Zm-6.7 12.01a6.667 6.667 0 1 1 6.667-6.667A6.662 6.662 0 0 1 8.571 18.1Z"
                                          data-name="Icon material-timer"/>
                                </svg>
                                <span>{{ timeRemaining }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="price__item-row">
                        <svg class="best-value" xmlns="http://www.w3.org/2000/svg" width="20" height="16">
                            <path data-name="Icon awesome-crown"
                                  d="M16.5 14h-13a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm2-10A1.5 1.5 0 0 0 17 5.5a1.47 1.47 0 0 0 .137.619l-2.262 1.356a1 1 0 0 1-1.381-.363l-2.547-4.456a1.5 1.5 0 1 0-1.894 0L6.506 7.112a1 1 0 0 1-1.381.362L2.866 6.119A1.5 1.5 0 1 0 1.5 7a1.532 1.532 0 0 0 .241-.025L4 13h12l2.259-6.025A1.532 1.532 0 0 0 18.5 7a1.5 1.5 0 1 0 0-3Z"
                                  fill="#c59264"/>
                        </svg>
                        <h2 class="heading">{{ p.name }}</h2>
                        <div class="price-box">
                            <small>{{ p.currency.toUpperCase() }} </small>{{ Number(p.price / p.credits).toFixed(2) }}
                        </div>
                    </div>
                    <div class="price__item-row">
                        <p>{{ $t('credits.per_credit') }}</p>
                        <div class="divider"></div>
                        <h3>{{ p.credits }} {{ $t('credits.credits') }}</h3>
                    </div>
                    <div class="price__item-row">
                        <h4>{{ p.currency.toUpperCase() }} {{ p.price.toFixed(2) }}</h4>
                        <button :disabled="working" class="button button--primary">
                            {{ ucFirst($t('credits.buy_credits')) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import {ucFirst} from "@/utilities/general";
import moment from "moment";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { daysHoursMinutesSecondsSince } from "@/utilities/general";

export default {
    name: "LimitedPromotionDays",
    mixins: [ThemeMixin, AuthenticatedMixin],
    emits: ['selected'],
    props: {
        packages: {
            type: Array
        },
        working: {
            type: Boolean,
            required: true
        },
        validUntil: {
            type: moment,
            default: null
        },
        promotion: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timeRemainingInterval: null,
            timeRemaining: '',
            daysLeft: 0,
            days: 0
        }
    },
    methods: {
        ucFirst,
        packageClickHandler(p) {

            this.$emit('selected', p)

        },
        async setDynamicValues(end)
        {
            this.timeRemaining = daysHoursMinutesSecondsSince(moment.utc(), end)

            this.daysLeft = parseInt(this.timeRemaining.split(':')[0])
        }

    },
    mounted() {

        this.setDynamicValues(this.validUntil)

        this.timeRemainingInterval = setInterval(() => {
            this.setDynamicValues(this.validUntil)
        }, 1000)

    },
    beforeUnmount() {
        clearInterval(this.timeRemainingInterval)
    }
}
</script>

<style scoped lang="scss">

.price {
  text-align: center;
  max-width:1080px;
  margin: 0 auto 100px;

}

.price__heading {
  text-align: left;

  .h1 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 700;

  }

  h2 {
    margin-bottom: 10px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.price__boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 60px;
  gap: 30px;
}

.price__item-row {
  position: relative;

  .best-value {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%,-5px);
  }

  .button--primary {
    width: 100%;
  }
}

.price__item {
  border-radius: 10px;
  border: solid 1px transparent;
  background-color: $color-type1;
  padding: 50px 30px 40px;
  position: relative;
  box-shadow: 0px 0px 12px -3px $color-type6;
  max-width: 500px;
  transition: all .3s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 12px 0px $color-type6;
  }

  &.is-active {
    background-color: #222222;

    h2,
    h3,
    h4,
    p {
      color: $color-type1;
    }

    .divider {
      background-color: #DEDEDE;
    }
  }
  h2 {
    font-size: 17px;
    font-weight: 700;
    transition: all .3s ease-in-out;
    margin-bottom: 40px;
  }

  .price-box {
    margin-bottom: 16px;
    color: $color-type19;
    font-size: 61px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    small {
      font-size: 26px;
      color: $color-type19;
      margin-right: 8px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1;
    transition: all .3s ease-in-out;
    margin-bottom: 20px;
    color: #959595 ;
    margin-top: -5px;
  }


  .divider {
    width: 100%;
    max-width: 136px;
    margin: 0 auto;
    background-color: #DEDEDE;
    height: 1px;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    transition: all .3s ease-in-out;
    letter-spacing: -0.9px;
  }

  h4 {
    color: $color-type9;
    margin-bottom: 45px;
    font-size: 18px;
    line-height: 1.4;
    transition: all .3s ease-in-out;
    font-weight: 400;
  }
}

.price__item--promotion {
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  border: none;

  .mobile-show {
    display: none;
  }

  .desktop-show {
    display: block;

    .clock {
      transform: translate(0,2px);
    }
  }

  .promotion-heading {
    background-color: #3CBF6C;
    padding: 15px 20px 35px;
    letter-spacing: -0.66px;
    color: #F5F5F5;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;

    span {
      display: flex;
      border-radius: 10px;
      background-color: #F5F5F5;
      color: #3CBF6C;
      letter-spacing: -0.49px;
      text-transform: uppercase;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      padding: 4px 10px;
    }
  }

  .clock {
    min-width: 17px;
  }

  .price__item-row-promo {
    display: none;
  }

  .promotion-body {
    padding: 20px 30px 40px;
    background-color: #fff;
    margin-top: -20px;
    border-radius: 10px 10px 0 0;

    .price__item-row {
      p.line {
        position: relative;

        &:before {
          width: 86px;
          height: 1px;
          background-color: #FF0000;
          content:"";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-9deg);
        }
      }
    }
  }
}

@media(max-width: 991px) {


  .price {
    margin-bottom: 40px;
  }

  .price >p {
    font-size: 16px;
  }

  .price__item {
    margin: 10px;
    padding: 10px 20px;
  }

  .price__item--promotion {
    padding: 0;

    .price__item-row-promo {
      display: block;
      text-align: left;
      margin-top: 10px;

      h4 {
        line-height: 1;
        text-align: left;

      }

      .divider {
        display: block;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }

    .promotion-body {
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .mobile-show {
      display: block;
    }

    .price__item-row--btn-wrapper {
      justify-content: flex-end;
    }

    .price-box {
      font-size: 26px!important;
      font-weight: 700;
    }

    .credits-box {
      margin-top: -30px;

      h3 {
        margin-bottom: 10px;
      }

      p {
        margin-top: 0;
      }
    }

    .desktop-show {
      display: none;
    }
  }

  .price__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      h2 {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    &:nth-child(2) {
      flex-direction: row-reverse;

      p {
        font-size: 14px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 20px;
      }
    }
    &:last-child {

      h4 {
        margin-bottom: 20px;
      }
    }
    .divider {
      display: none;
    }

    .button--primary {
      width: auto;
    }
    .price-box {
      line-height: 1;
      font-size: 30px;
      align-items: center;
      margin-bottom: 0;

      br {
        display: none;
      }
    }
  }
}

@media(max-width: 991px) {
  .price__boxes {
    display: block;
    margin-top: 30px;
  }


  .price__item {
    margin: 10px auto;
    width: 100%;

    .best-value {
      transform: translate(-50%, 100%);
    }
  }
}

</style>