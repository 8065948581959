<template>
    <div class="loader-wrapper" v-show="display">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
        <div class="circle circle-3"></div>
        <div class="circle circle-4"></div>
        <div class="circle circle-5"></div>
    </div>
</template>

<script>
export default {
    name: "ContentLoader",
    data() {
        return {
            display: false,
            timeout: null
        }
    },
    mounted() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.display = true, 500)
    }
}
</script>


<style scoped lang="scss">
    $color: #BFA46A, #8F7B50, #605235, #30291B, #000000;
    $circle-size: 16px;
    $circle-margin: 7px;

    .loader-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle {
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    margin: $circle-margin;
    display: flex;
    justify-content: center;
    align-items: center;

        &:before {
            content: "";
            width: $circle-size;
            height: $circle-size;
            border-radius: 50%;
            opacity: 0.7;
            animation: scale 1.8s infinite
            cubic-bezier(0, 0, 0.49, 1.02);
        }
    }

    @for $i from 1 through 5 {
        .circle-#{$i} {
            background-color: nth($color, $i);
            &:before {
            background-color: nth($color, $i);
            animation-delay: 200ms * $i;
            }
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50%, 75% {
            transform: scale(2.5);
        }
        78%, 100% {
            opacity: 0;
        }
    }


</style>