<template>
    <a @click="toggle" :class="{'is-active' : checked, animate: animate }" class="like">
        <svg width="20" viewBox="0 0 24 24" xml:space="preserve"><path d="M23.3 8.6c0 5.2-7.7 10.6-10.3 12.4-.6.4-1.3.4-1.8 0C8.4 19.3.8 13.8.8 8.6c0-3.3 2.6-5.9 5.9-5.9 2.4 0 4.4 1.4 5.4 3.4.9-2 3-3.4 5.4-3.4 3.1 0 5.8 2.7 5.8 5.9z" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/></svg>
    </a>
</template>

<script>

import toastr from "toastr";
import { addToFavoriteService, removeFromFavoriteService } from "@/service/favorite"

export default {
    name: "Favorite",
    props: {
        profile: {
            type: Object,
            required: true
        }
    },
    created() {
        this.added = this.profile.my_favorite
        this.checked = this.profile.my_favorite
    },
    data() {
        return {
            added: false,
            checked: false,
            timeout: null,
            animate: false
        }
    },
    watch: {
        'profile.my_favorite'(value) {
            this.added = !! value
            this.checked = !! value
        }
    },
    methods: {
        async toggle() {

            this.animate = true

            this.checked = ! this.checked

            clearTimeout(this.timeout)

            this.timeout = setTimeout(async () => {

                if(!!this.added === !!this.checked)
                    return

                try {

                    let msg

                    if(this.added) {

                        await removeFromFavoriteService(this.profile.id)
                        msg = this.$root.$i18n.t('search.favorite_removed')
                        this.added = false
                        this.checked = false

                    } else {

                        await addToFavoriteService(this.profile.id)
                        msg = this.$root.$i18n.t('search.favorite_added')
                        this.added = true
                        this.checked = true

                    }

                    toastr.success(msg)

                } catch {
                    this.checked = this.added
                    toastr.error(this.$root.$i18n.t('credits.error_processing'))
                }
            }, 500)

        }
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>

<style lang="scss" scoped>
.like {

    path {
        -webkit-transition:all 0.3s ease;
        transition:all 0.3s ease;
    }
    &.is-active {
        &.animate {
            svg {
                animation:like 0.3s 1;
            }
        }
        path {
            fill: $color-type19;
            stroke: $color-type19;

        }
    }
    &:not(.is-active) {
        &.animate {
            svg {
                animation:unlike 0.3s 1;
            }
        }
    }
}

// @-webkit-keyframes like {
//     0%  {
//         -webkit-transform: scale(1); 
//     }
//     25% { 
//         -webkit-transform: scale(0.5);
//     }
//     75% { 
//         -webkit-transform: scale(1.5);
//     }
//     100% { 
//         -webkit-transform: scale(1.0); 
//     }
// }

@keyframes like {
    0%  {
        -webkit-transform: scale(1); 
        transform: scale(1); 
    }
    25% { 
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    75% { 
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% { 
        -webkit-transform: scale(1.0); 
        transform: scale(1.0); 
    }
}



@keyframes unlike {
    0%   { 
        -webkit-transform: scale(1); 
        transform: scale(1); 
    }
    50% { 
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% { 
        -webkit-transform: scale(1.0); 
        transform: scale(1.0); 
    }
}
</style>