<template>
    <div class="img-wrapper">
        <img :src="getUserPhotoURL(conversation.members.find(e => e.id !== auth.user.id), 'small')" alt="User Photo">
    </div>
    <div class="chat-list__user">
        <OnlineStatus class="active-user" v-if="isOnline(conversation.members.find(e => e.id !== auth.user.id))" />
        <div>
            <h2>
                <Premium v-if="conversation.members.find(e => e.id !== auth.user.id).premium" />
                <Verified v-if="conversation.members.find(e => e.id !== auth.user.id).profile_verified_at" />
                {{conversation.members.find(e => e.id !== auth.user.id).username}}
            </h2>
            <p v-if="sending"><SendingAnimation /></p>
            <p v-else-if="isTyping" class="ml- mt-"><TypingAnimation /></p>
            <p v-else-if="conversation.last_message" class="o-flex o-flex--center">
                <svg v-if="!conversation.system_approved_at && auth.user.gender === TYPE_GENDER_MALE" class="mr--" xmlns="http://www.w3.org/2000/svg" width="12" height="13.082" viewBox="0 0 11.8 13.082"><g data-name="Icon feather-lock" transform="translate(-3.9 -2.5)" fill="none" stroke="#000"><path data-name="Path 77" d="M5.6 7.082H14a1.346 1.346 0 0 1 1.2 1.455v5.091a1.346 1.346 0 0 1-1.2 1.454H5.6a1.346 1.346 0 0 1-1.2-1.455v-5.09a1.346 1.346 0 0 1 1.2-1.455Z" stroke-linecap="round" stroke-linejoin="round"/><path data-name="Path 78" d="M6.9 7.082V6a3 3 0 0 1 6 0v1.082" stroke-linecap="round" stroke-linejoin="round"/><circle data-name="Ellipse 31" cx="1.5" cy="1.5" r="1.5" transform="translate(8.4 9.582)"/></g></svg>
                <svg v-else-if="conversation.last_message.attachment?.type === ITEM_TYPE_PHOTO" class="mr--" xmlns="http://www.w3.org/2000/svg" width="13" height="13.207"><g data-name="Icon feather-image" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"><path data-name="Path 67" d="M1.833.5h9.333A1.333 1.333 0 0 1 12.5 1.833v9.333a1.333 1.333 0 0 1-1.333 1.334H1.833A1.333 1.333 0 0 1 .5 11.167V1.833A1.333 1.333 0 0 1 1.833.5Z"/><path data-name="Path 68" d="M5.167 4.167a1 1 0 1 1-1-1 1 1 0 0 1 1 1Z"/><path data-name="Path 69" d="M12.5 8.5 9.166 5.167 1.833 12.5"/></g></svg>
                <svg v-else-if="conversation.last_message.attachment?.type === ITEM_TYPE_VIDEO" height="18" width="18" viewBox="0 0 32 32" class="mr--" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m28.496 15.132-21-12A1 1 0 0 0 6 4v24a1 1 0 0 0 1.496.868l21-12a1 1 0 0 0 0-1.736zM8 26.276V5.724L25.984 16 8 26.276z"/></svg>
                {{ preview() }}
            </p>
        </div>
        <div class="chat-list__user-right">
            <div v-if="unread" class="messages-numb">{{unread}}</div>
            <div class="time" v-if="conversation.last_message">{{moment.utc(conversation.last_message.created_at, 'YYYY-MM-DD HH:mm:ss').local().format('h:mm a')}}</div>
        </div>
    </div>
</template>

<script>
import TypingAnimation from "@/components/messages/TypingAnimation";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import moment from "moment";
import { TYPE_TEXT } from "@/types/message";
import { TYPE_GENDER_MALE } from "@/types/user";
import { ucFirst } from "@/utilities/general";
import OnlineStatus from "@/components/user/OnlineStatus";
import SendingAnimation from "@/components/messages/SendingAnimation";
import { ITEM_TYPE_VIDEO, ITEM_TYPE_PHOTO } from "@/types/item";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";

export default {
    name: "ConversationItem",
    mixins: [AuthenticatedMixin],
    components: {Verified, Premium, TypingAnimation, OnlineStatus, SendingAnimation },
    props: {
        conversation: {
            type: Object,
            required: true
        },
        isTyping: {
            type: Boolean
        },
        unread: {
            type: Number,
            default: 0
        },
        sending: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            moment,
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        preview() {
            if( ! this.conversation.system_approved_at && this.auth.user.gender === TYPE_GENDER_MALE)
                return ucFirst(this.$i18n.t('messages.conversation.to_read'))

            if(this.conversation.last_message?.attachment?.type === ITEM_TYPE_PHOTO)
                return ucFirst(this.$i18n.t('file_control.photo'))

            if(this.conversation.last_message?.attachment?.type === ITEM_TYPE_VIDEO)
                return ucFirst(this.$i18n.t('file_control.video'))

            return this.conversation.last_message.body.length > 17 ? (this.conversation.last_message.body.substring(0, 17).trim() + '...') : this.conversation.last_message.body

        },
        lastMessageDate(date) {
            return moment(date, 'YYYY-MM-DD HH:mm:ss').isSame(new Date, 'day') ? this.$i18n.t('messages.today') : moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('ll')
        }
    },
    created() {
        this.TYPE_TEXT = TYPE_TEXT
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
        this.ITEM_TYPE_PHOTO = ITEM_TYPE_PHOTO
    }
}
</script>

<style scoped lang="scss">
.delete-user {
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 2;
    background-color: $color-type2;
    border-radius: $chat-item-list;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease-in-out;
    visibility: hidden;
    opacity: 0;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}

.locked-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-type1;
    border-radius: $chat-item-list;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    padding-left: 100px;

    &.is-active {
        opacity: 1;
        visibility: visible;
        // border:1px solid #bfa46a;
    }

    >div:last-child {
        position: relative;
    }
}

.delete,
.undo {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;

    svg {
        margin-right: 10px;
    }

    &:hover {
        color: #000;
    }
}

.undo {
    font-weight: 600;
}

.chat-wrapper {
    background-color: $color-type1;
    height: 100%;
}

.messages-numb {
    font-size: 10px;
    min-width: 16px;
    padding: 0 2px;
    height: 16px;
    font-weight: 600;
    color: $color-type1;
    background-color: $color-type32;
    border-radius: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    @media(max-width: 991px) {
        left: 45px;
        top: 8px;
        margin-left: 0;
    }
}

.chat-list__user {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h2 {
        margin-bottom: 7px;
        font-size: 16px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 3px;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
    }

    .time {
        font-size: 12px;
        color: #848484;
        line-height: 1;
        margin-top: 7px;

        @media(max-width: 991px) {
            line-height: 1.5;
            margin-top: 0px;
        }
    }

    @media(max-width: 991px) {
        h2 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    }
}

.chat-list__user-right {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
}

.img-wrapper {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    position: relative;
    z-index: 3;

    img {
        min-width: 100%;
        min-height: 100%;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    @media(max-width: 991px) {
        min-width: 60px;
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }

}
.active-user {
    border: 2px solid $color-type1;
    position: absolute;
    z-index: 9;
    left: 44px;
    bottom: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-type25;

    @media(max-width: 991px) {
        left: 45px;
        bottom: 8px;
        margin-left: 0;
    }
}
</style>