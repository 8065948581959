<template>
    <div class="h-100">
        <teleport to="#modals">
            <transition name="fade" mode="out-in">
                <Modal v-if="uploading.display" v-show="photo && !working" class="modal modal--mob">
                    <template #body>
                        <UploadItem v-if="photo" @progress="track" @finished="finish" @error="handleUploadError" :file="photo" @close="photo = null; $refs.upload.value = null">
                            <template #switch>
                                {{ucFirst($t(`profile.secret_photo`))}}
                            </template>
                            <template #description>
                                {{$t('profile.secret_photo_visible')}}
                            </template>
                        </UploadItem>
                    </template>
                </Modal>
            </transition>
        </teleport>
        <div class="o-container h-100">
            <section class="gallery">

                <div class="messages__header">
                    <BackButton />
                    <h2>{{$t('general.gallery')}}</h2>
                </div>
                <article>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="nav-wrap">
                                <router-link class="button button--fourth mr-" :to="{name: 'photos'}" :class="`font-weight-${$route.name === 'photos' ? 'bold' : 'normal'}`">
                                    {{$t('general.photos')}}
                                </router-link>
                                <router-link class="button button--fourth" :to="{name: 'videos'}" :class="`font-weight-${$route.name === 'videos' ? 'bold' : 'normal'}`">
                                    {{$t('general.videos')}}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="btn-wrapper">
                                <button :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{ucFirst($t('profile.photos.upload_photos'))}}</button>
                                <input @change="set" type="file" :accept="ALLOWED_IMAGE_FORMATS.map(e => `image/${e}`).join(', ')" style="display: none" ref="upload">
                            </div>
                        </div>
                    </div>
                </article>

                <article>
                    <ContentLoader v-if="loading"/>
                    <div v-if="!loading">
                        <div v-if="!images.length" class="no-uploaded-images">

                            <div class="img-wrapper">
                                <img alt="Image" style="cursor: pointer" @click="$refs.upload.click()" :src="require(`@/assets/img/${this.public ? 'no_photos': 'no_secret'}.svg`)">
                            </div>
                            <h1>{{$t(`profile.photos.${this.public ? 'public' : 'secret'}_title`)}}</h1>
                            <h2>{{$t(`profile.photos.${this.public ? 'public' : 'secret'}_text`)}}</h2>
                            <div class="gray-box">
                                <h4>{{$t('profile.photos.guidelines')}}:</h4>
                                <ol>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_1')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_2')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_3')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_4')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_5')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_6')}}</p>
                                    </li>
                                    <li>
                                        <p>{{$t('profile.photos.guideline_7')}}</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="img-list">
                            <div v-for="(i, k) in images"
                                    :key="i"
                                    class="img-wrapper"
                                    :class="!i.approved ? 'pending' : ''">
                                <Image @click="preview.photo = i; preview.display = true; preview.index = k" alt="Image" class="img-thumbnail" :src="i.source.medium" />
                                <div class="status-info">
                                    <span v-if="i.private"><strong>{{$t('file_control.secret')}}</strong></span>
                                    <span v-if="!i.private"><strong>{{$t('file_control.public')}}</strong></span>
                                    <span v-if="i.id === auth.user.photo_id"><strong>{{$t('quick_start.profile_photo')}}</strong></span>
                                </div>
                                <div class="pending-overlay" v-if="!i.approved">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Icon feather-clock" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 65" d="M31.5 16.5a15 15 0 1 1-15-15 15 15 0 0 1 15 15Z"/><path data-name="Path 66" d="M16.5 7.5v9l6 3"/></g></svg>
                                    </span>
                                </div>


                            </div>
                        </div>
                    </div>

                </article>

                <Pagination @page-click="load" :info="pagination" />
            </section>
        </div>

        <teleport to="#modals">
            <Slider v-if="preview.display" :current="0" @close="preview.display = false; modal.mode = null; modal.show = false; showWarning = false;" :slides="[preview.photo]">
                <template v-slot:content="{ displaying }">
                    <MediaSlider :slides="[preview.photo]" :displaying="displaying" />
                    <div class="img-number">{{preview.index + data.from}} / {{data.total}}</div>
                    <div class="approved" v-if="!preview.photo.approved">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Icon feather-clock" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 65" d="M31.5 16.5a15 15 0 1 1-15-15 15 15 0 0 1 15 15Z"/><path data-name="Path 66" d="M16.5 7.5v9l6 3"/></g></svg>
                        <p>{{$t('file_control.waiting_for_approval')}}!</p>
                    </div>
                </template>
                <template #footer>
                    <div class="slider-info slider-info--inline">
                        <div v-if="showWarning" class="pl- pr-">
                            <p><strong>{{$t('profile.must_contain', [GIRL_MIN_PHOTOS])}}</strong></p>
                            <p>{{$t('signup.replace_to_remove.customize_album')}}</p>
                            <button type="button" @click="modal.mode = null; modal.show = false; showWarning = false" class="button button--third">Close</button>
                            <button type="button" :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{$t('signup.replace_to_remove.upload')}}</button>
                        </div>
                        <div v-if="modal.show && ! showWarning" class="pl- pr-">
                            <h2  v-if="modal.mode==='update'">
                                {{$t('file_control.make')}} {{$t(`file_control.${!preview.photo.secret ? 'secret' : 'public'}`)}}
                            </h2>
                            <h2  v-if="modal.mode==='delete'">
                                {{$t('file_control.delete')}} {{$t('file_control.photo')}}
                            </h2>
                            <p v-if="modal.mode==='update'">
                                {{$t('file_control.making_this')}} {{$t('file_control.photo').toLowerCase()}} {{$t(`file_control.${!preview.photo.secret ? 'secret' : 'public'}_means`)}}
                            </p>
                            <p v-if="modal.mode==='delete'">
                                {{$t('file_control.are_you_sure')}} {{$t('file_control.photo').toLowerCase()}} {{$t('file_control.from_your_album')}}?
                            </p>
                            <button type="button" @click="modal.show = false; modal.mode = null" class="button button--third">{{ucFirst($t('quick_start.back'))}}</button>
                            <button type="button" v-if="modal.mode==='update'" :disabled="working" @click="update(target, {private: preview.photo.private ? 0 : 1})" class="button button--primary">{{$t('file_control.make')}} {{$t(`file_control.${!preview.photo.private ? 'secret' : 'public'}`)}}</button>
                            <button type="button" v-if="modal.mode==='delete'" :disabled="working" @click="del(target)" class="button button--primary">{{$t('file_control.delete')}}</button>
                        </div>
                        <div v-if="!modal.show && ! showWarning" class="main-status-info">
                            <div class="photo-status">{{$t('profile.this_photo_is')}} <strong>{{ucFirst($t(`profile.${!preview.photo.private ? 'public' : 'secret'}_photo`))}}</strong></div>
                            <div class="o-flex o-flex--center">
                                <button type="button" class="button link-button" @click="confirm(preview.photo, 'delete')">{{$t('file_control.delete')}}</button>
                                <div class="vertical"></div>
                                <button type="button" class="button link-button" @click="confirm(preview.photo, 'update')">{{$t(`file_control.${!preview.photo.private ? 'secret' : 'public'}`)}}</button>
                            </div>
                        </div>
                    </div>
                </template>
            </Slider>
        </teleport>


        <teleport to="#modals">

            <Modal :closable="false" v-if="uploading.working">
                <template #header>
                    <p class="mb-">{{$t('general.wait')}}</p>
                </template>
                <template #body>
                    <ProgressBar :completed="uploading.completed" />
                </template>
            </Modal>

        </teleport>

    </div>



</template>

<script>

import { gallery } from "@/api/profile"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { updatePhotoService, deletePhoto } from "@/service/photo"
import Pagination from "@/components/generic/Pagination"
import { mapActions, mapState } from "vuex";
import { getPaginationPayload } from "@/service/general";
import { GIRL_MIN_PHOTOS } from "@/utilities/user";
import ContentLoader from "@/components/ContentLoader";
import Slider from "@/components/generic/Slider";
import MediaSlider from "@/components/generic/MediaSlider";
import { ucFirst } from "@/utilities/general";
import Image from "@/components/generic/Image";
import Modal from "@/components/generic/Modal";
import { ALLOWED_IMAGE_FORMATS } from "@/utilities/files";
import ProgressBar from "@/components/ProgressBar";
import {TYPE_GENDER_FEMALE} from "@/types/user";
import {setAuthenticatedUserItemCount} from "@/service/auth";
import BackButton from "@/components/generic/BackButton";
import {getExtensionFromFilename, hashFile, createSelectedFile} from "@/service/files";
import UploadItem from "@/components/photos/UploadItem.vue";
import toastr from "toastr";


export default {
    name: "Gallery",
    components: { UploadItem, BackButton, Modal, Image, Pagination, ContentLoader, Slider, MediaSlider, ProgressBar },
    mixins: [AuthenticatedMixin],
    props: {
        public: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            edit: null,
            data: {
                total: 0,
                from: 0,
                to: 0
            },
            loading: true,
            working: false,
            page: 1,
            images: [],
            pagination: null,
            modal: {
                mode: '',
                show: ''
            },
            showWarning: false,
            updateMode: null,
            updateModalShow: false,
            deleteModalShow: false,
            target: null,
            preview: {
                display: false,
                photo: null,
                index: 0
            },
            uploading: {
                working: false,
                completed: 0,
                display: false
            },
            photo: null
        }
    },
    computed: {
        ...mapState({
            publicNumber: state => state.items.public.photos.total,
            secretNumber: state => state.items.secret.photos.total,
            deleted: state => state.items.deleted,
            uploaded: state => state.items.uploaded,
            ready: state => state.items.ready
        })
    },
    methods: {
        ucFirst,
        ...mapActions({
            checkProfile: "profile/add",
            trackUploadedPhoto: "items/trackUploadedFile",
        }),
        confirm(target, mode) {

            if(mode === "delete" && this.auth.user.gender === TYPE_GENDER_FEMALE && (this.publicNumber + this.secretNumber <=  GIRL_MIN_PHOTOS)) {
                return this.showWarning = true
            }

            this.modal.mode = mode
            this.modal.show = true
            this.target = target
        },
        async del(target) {
            this.working = true
            try {
                await deletePhoto(target.id)

                if(this.images.length < 2 && this.page > 1) {
                    await this.load(this.page - 1)
                } else {
                    await this.load(this.page)
                }

                await setAuthenticatedUserItemCount()

                this.preview.photo = null
                this.preview.display = false


            } catch (e) {
                console.log(e)
                console.log('Error deleting photo')
            }

            this.modal.show = false
            this.working = false

        },
        async update(target, payload) {

            this.working = true

            try {
                await updatePhotoService(target.id, payload)
                await this.load(this.page)
                this.preview.photo = null
                this.preview.display = false
            } catch (e) {
                console.log(e)
                console.log('Error updating photo')
            }

            this.working = false
            this.modal.show = false

            if(target.private !== payload.private) {

                await setAuthenticatedUserItemCount()

            }

            this.target = null

            await this.checkProfile('photos')
        },

        resetUpload() {
            this.working = false
            this.showWarning = false
            this.modal.show = false
            this.modal.mode = null
            this.preview.display = false
            this.preview.photo = null
            this.$refs.upload.value = ''
            this.uploading.working = false
            this.uploading.display = false
            this.photo = null
        },

        handleUploadError(e) {
            console.log(e)
            toastr.error(this.$i18n.t('credits.error_processing'))
            return this.resetUpload()
        },

        async set(e) {
            const [file] = e.target.files || e.dataTransfer.files

            if( ! file)
                return

            const extension = getExtensionFromFilename(this.$refs.upload.value).toLowerCase()

            if( ! ALLOWED_IMAGE_FORMATS.includes(extension)) {
                return toastr.warning(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            this.uploading.display = true

            const hash = await hashFile(file)

            if(this.uploaded.find(i => i.hash === hash)) {
                toastr.warning(this.$i18n.t('file_control.photo_uploaded_already'))
                return this.resetUpload()
            }

            this.photo = await createSelectedFile(file, hash)
        },

        async finish(item) {
            await setAuthenticatedUserItemCount()
            await this.trackUploadedPhoto({id: item.id, hash: item.hash})
            this.resetUpload()
            this.working = false
            this.uploading.working = false
            this.$refs.upload.value = ''
        },

        async track(e) {

            this.working = true
            this.uploading.working = true
            this.uploading.completed = Math.round((e.loaded * 100) / e.total)
            this.modal.show = false
            this.showWarning = false

        },
        async load(page) {
            this.loading = true
            const { data } = await gallery({page: page, perPage: 50})
            this.data.from = data.from
            this.data.to = data.to
            this.data.total = data.total
            this.images = data.data.filter(e => e.id !== this.auth.user.photo_id)
            this.page = data.current_page
            this.pagination = getPaginationPayload(data)
            this.loading = false
        }
    },
    watch: {
        publicNumber: {
            handler() {
                this.load(this.page)
            }
        },
        secretNumber: {
            handler() {
                this.load(this.page)
            }
        },
        async public(val) {
            console.log('Watching public prop in Gallery', val)

            this.pagination = null

            this.images = []
            this.page = 1

            await this.load(this.page)
        },
        deleted(value) {
            if(this.images.some(e => value.includes(e.id))) {
                this.load(this.page)
            }

        },
        ready(value) {
            const item = {
                ...value[value.length - 1]
            }
            this.images = this.images.map(e => {
                if(e.id === item.id) {
                    return {
                        ...e,
                        ...item
                    }
                }
                return e
            })
        }
    },
    created() {
        this.ALLOWED_IMAGE_FORMATS = ALLOWED_IMAGE_FORMATS
        this.GIRL_MIN_PHOTOS = GIRL_MIN_PHOTOS
    },
    async mounted() {
        await this.load(this.page)
    },

}
</script>

<style scoped lang="scss">

    .messages__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 0;

        .back-mob {
            margin-right: 10px;
            display: none;
            margin-top: 2px;
            padding: 10px;
        }

        h2 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -0.9px;
        }

        @media(max-width: 991px) {
            margin-bottom: 30px;

            .back-mob {
                display: block;
            }
        }
    }

    // .o-container {
    //     padding: 0;
    // }

    .gallery {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: calc(100% - 40px);
        height: auto;
        overflow: hidden;


        article {
            width: 100%;
            margin-bottom: 30px;
        }

        @media(max-width: 991px) {
            padding: 20px 0 100px;
            margin-top: 0;

            article {
                margin-bottom: 10px;
            }

            .nav-wrap {
                justify-content: center;

                a {
                    font-size: 16px;
                    width:155px;
                    height: 30px;
                    max-width: 100%;
                    letter-spacing: -0.66px;
                }
            }
        }
    }

    .img-number {
        position: fixed;
        left: 30px;
        font-size: 20px;
        top: 30px;
        color: #fff;

        @media(max-width: 991px) {
            top: 25px;
            font-size: 16px;
            left: auto;
            right: 25px;
        }
    }

    .photo-status {
        height: 24px;
        border-radius: 12px;
        padding: 0 10px;
        background-color: rgba(#000, .7);
        color: #fff;
        font-size: 14px;
        margin-bottom: 20px;
        display: inline-flex;
        align-items: center;
        width: auto;

        strong {
            margin-left: 5px;
        }
    }

    .approved {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 180px;
        width: 100%;
        aspect-ratio: 1 / 1;
        transform: translate(-50%,-50%);
        background-color: rgba(#000, .6);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10;
        border-radius: 10px;
        padding: 0 10px;
        text-align: center;

        svg {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: #fff;
            line-height: 1.3;
            font-size: 14px;
            max-width: 100px;
        }
    }

    .modal {

        .button-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .button {
            flex: 0 1 calc(50% - 5px)
        }

        h2 {
            font-size: 19px;
            line-height: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }

        @media(max-width: 500px) {

            .button-wrapper {
                justify-content: initial;
                width: 100%;
            }

            .button {
                flex: 1 1 100%!important;
            }
        }
    }

    @media(max-width: 991px) {

        .btn-wrapper {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
            background-color: $color-type1;
            border-radius: 10px 10px 0px 0px;
            z-index: 999;
            display: flex;
            align-items: center;
            padding: 15px 20px;

            .button {
                width: 100%;

            }
        }
    }

    .pending-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#000, .6);
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }
    }

    .nav-wrap {
        height:100%;
        display: flex;
        align-items: center;

        .button--fourth {
            color: $color-type18;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            padding: 0 30px;
            width: auto;
            white-space: nowrap;

            &.router-link-active {
                color: $color-type1;
                background-color: #000000;
            }

            &:hover {
                color: $color-type1;
                background-color: #000000;
            }
        }
    }

    .img-list {
        margin-left: -2px;
        margin-right: -2px;
        display: flex;
        flex-wrap: wrap;

        .img-wrapper {
            margin: 0 2px 4px;
            width: calc(25% - 4px);
            position: relative;
            overflow: hidden;
            cursor: pointer;
            aspect-ratio: 1 / 1;

            &:hover {
                .footer-btn {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(0,0);

                }
            }
        }

        .status-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            position: absolute;
            z-index: 2;
            left: 6px;
            bottom: 6px;

            span {
                height: 23px;
                display: inline-flex;
                align-items: center;
                padding: 0 10px;
                margin-top: 4px;
                background-color: rgba($color-type18, .7);
                border-radius: 12px;
                color: $color-type1;
                font-size: 14px;
            }
        }

        @media(max-width: 991px) {
            margin-left: -2px;
            margin-right: -2px;

            .img-wrapper {
                margin: 0 2px 4px;
                width: calc(50% - 4px);
                border-radius: 0;
            }

            img {
                object-fit: cover;
                width: 100%;
            }
        }

    }

    .no-uploaded-images {
        width: calc(100% - 20px);
        text-align: center;
        overflow: hidden;
        margin: 0 auto;

        h1 {
            margin-bottom: 27px;
        }

        h2 {
            line-height: 1.6;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .img-wrapper {
            width: 100px;
            height: 100px;
            margin: 10px auto 30px;

        }

        @media(max-width: 991px) {
            .img-wrapper {
                width: 60px;
                height: 60px;
                margin: 10px auto;
            }

            h1 {
                font-size: 20px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 14px;
            }
        }

    }

    .show-fullsize-img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 30px;
            pointer-events: none;
        }
    }

    .gray-box {
        text-align: left;
        border-radius: 5px;
        padding: 35px 40px;
        background-color: $color-type3;
        color: $color-type18;

        h4 {
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0;
            line-height: 20px;
        }

        ol {
            list-style-type: numbers;
            padding-left: 17px;
            margin-bottom: 0;
        }

        @media(max-width: 991px) {
            padding: 15px 20px;

            ol {
                font-size: 14px;
                padding-left: 15px;

            }

            h4 {
                font-size: 14px;
            }

            p {
                font-size: 14px;
            }
        }
    }


    .footer-btn {
        z-index: 3;
        width: 100%;
        height: 53px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg,transparent 0%,#000000 100%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        transition: all .3s ease;
        transform: translate(0,100%);
        visibility: hidden;
        opacity: 0;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: $color-type1;
            width: 1px;
            height: 20px;

        }
        a {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: $color-type1;

            &:hover {
                color: $color-type20;
            }
        }

        @media(max-width: 500px) {
            visibility: visible;
            opacity: 1;
            transform: translate(0,0);
        }
    }

    .btn-wrapper {
        text-align: right;

        .button--primary {
            font-weight: 400;
        }
    }
</style>