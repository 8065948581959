<template>
    <header class="header-register">
        <div class="o-container">
            <router-link :to="{name: 'home'}" class="header-logo">
                <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
            </router-link>
            <LanguageSelection class="type" :dropdownAbsolute="'type1'"/>
        </div>
    </header>
    <section class="register">
        <div class="o-container">

            <article class="register__text">
                <h1>
                    {{$t(`signup.${gender === 'man' ? 'male' : 'female'}.register.title.${theme}`)}}
                </h1>
                <ul class="register__text-list">
                    <li v-if="!selectedGender">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Group 201"><path data-name="Path 70" d="M16.5 0A16.5 16.5 0 1 1 0 16.5 16.5 16.5 0 0 1 16.5 0Z" fill="#fff"/><path data-name="Icon metro-link" d="M15.351 18.481a.83.83 0 0 1-.588-.244 3.909 3.909 0 0 1 0-5.522l3.073-3.073a3.905 3.905 0 1 1 5.522 5.522l-1.4 1.4a.832.832 0 0 1-1.177-1.177l1.4-1.4a2.241 2.241 0 0 0-3.169-3.168l-3.072 3.073a2.243 2.243 0 0 0 0 3.169.832.832 0 0 1-.588 1.421Zm-2.948 6.021a3.9 3.9 0 0 1-2.761-6.666l1.4-1.4a.832.832 0 0 1 1.181 1.173l-1.4 1.4a2.241 2.241 0 0 0 3.169 3.169l3.072-3.069a2.243 2.243 0 0 0 0-3.169.832.832 0 1 1 1.177-1.177 3.909 3.909 0 0 1 0 5.522l-3.077 3.074a3.879 3.879 0 0 1-2.761 1.144Z"/></g></svg>
                        {{$t(`signup.${gender === 'man' ? 'male' : 'female'}.register.connect.${theme}`)}}
                    </li>
                    <li v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Group 201"><path data-name="Path 70" d="M16.5 0A16.5 16.5 0 1 1 0 16.5 16.5 16.5 0 0 1 16.5 0Z" fill="#fff"/><path data-name="Icon metro-link" d="M15.351 18.481a.83.83 0 0 1-.588-.244 3.909 3.909 0 0 1 0-5.522l3.073-3.073a3.905 3.905 0 1 1 5.522 5.522l-1.4 1.4a.832.832 0 0 1-1.177-1.177l1.4-1.4a2.241 2.241 0 0 0-3.169-3.168l-3.072 3.073a2.243 2.243 0 0 0 0 3.169.832.832 0 0 1-.588 1.421Zm-2.948 6.021a3.9 3.9 0 0 1-2.761-6.666l1.4-1.4a.832.832 0 0 1 1.181 1.173l-1.4 1.4a2.241 2.241 0 0 0 3.169 3.169l3.072-3.069a2.243 2.243 0 0 0 0-3.169.832.832 0 1 1 1.177-1.177 3.909 3.909 0 0 1 0 5.522l-3.077 3.074a3.879 3.879 0 0 1-2.761 1.144Z"/></g></svg>
                        {{$t(`signup.${gender === 'man' ? 'male' : 'female'}.register.connect`)}}</li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Group 202"><path data-name="Path 71" d="M16.5 0A16.5 16.5 0 1 1 0 16.5 16.5 16.5 0 0 1 16.5 0Z" fill="#fff"/><path data-name="Icon metro-airplane" d="m21 18-2.857-2.857L25 10l-2-2-8.571 3.429-2.7-2.7a1.791 1.791 0 0 0-2.414-.414 1.791 1.791 0 0 0 .414 2.415l2.7 2.698L9 22l2 2 5.144-6.857L19.001 20v4h2l1-3 3-1v-2h-4Z"/></g></svg>
                        {{$t(`signup.${gender === 'man' ? 'male' : 'female'}.register.pin`)}}</li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Group 203"><path data-name="Path 72" d="M16.5 0A16.5 16.5 0 1 1 0 16.5 16.5 16.5 0 0 1 16.5 0Z" fill="#fff"/><path data-name="Icon ionic-ios-pin" d="M16.538 9A5.353 5.353 0 0 0 11 14.142c0 4 5.538 10.858 5.538 10.858s5.538-6.858 5.538-10.858A5.353 5.353 0 0 0 16.538 9Zm0 7.342a1.8 1.8 0 1 1 1.8-1.8 1.8 1.8 0 0 1-1.8 1.8Z"/></g></svg>
                        {{$t(`signup.${gender === 'man' ? 'male' : 'female'}.register.map.${theme}`)}}</li>
                </ul>
            </article>
            <article class="register__form">
                <div>
                    <h1>{{ucFirst($t('signup.free_100'))}}</h1>
                    <p class="register__form-text">{{$t(`signup.subtitle.${gender === 'man' ? 'male' : 'female'}.${theme}`)}}</p>
                    <Form ref="registrationForm" :validation-schema="validationSchema" @submit="onSubmit" @keydown.enter="$event.preventDefault()" autocomplete="off">
                        <div class="step">
                            <div class="input-box">
                                <p>{{$t('form_fields.email_label')}}</p>
                                <Field :class="`input input--primary`" tabindex="1" :validateOnInput="true" :placeholder="$t('form_fields.email_placeholder')" name="email" />
                                <ErrorMessage class="text-error text-left form-text" name="email"  />
                            </div>
                            <div class="input-box">
                                <p>{{$t('form_fields.password_label')}}</p>

                                <div class="relative">
                                    <Field :class="`input input--primary`" tabindex="2" :validateOnInput="true" :placeholder="$t('form_fields.password_placeholder')" name="password" :type="showPassTrigger?'password':'text'" />
                                    <ErrorMessage class="text-error text-left form-text" name="password"  />
                                    <a class="show-pass-trigger" @click="showPassTrigger = !showPassTrigger">
                                        <svg v-if="showPassTrigger" class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                                        <svg v-else class="hidden-pass" xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path data-name="Icon awesome-eye-slash" d="M10 12.5a4.483 4.483 0 0 1-4.466-4.16L2.256 5.807a10.415 10.415 0 0 0-1.147 1.737 1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 10 14a9.707 9.707 0 0 0 2.434-.327l-1.622-1.255A4.5 4.5 0 0 1 10 12.5Zm9.807 1.816-3.455-2.67a10.352 10.352 0 0 0 2.539-3.19 1.011 1.011 0 0 0 0-.912A10.023 10.023 0 0 0 10 2a9.63 9.63 0 0 0-4.6 1.178L1.421.105a.5.5 0 0 0-.7.088l-.616.79a.5.5 0 0 0 .088.7l18.386 14.21a.5.5 0 0 0 .7-.088l.614-.79a.5.5 0 0 0-.086-.699Zm-5.741-4.438-1.228-.949a2.961 2.961 0 0 0-3.628-3.81A1.489 1.489 0 0 1 9.5 6a1.458 1.458 0 0 1-.048.313l-2.3-1.778A4.447 4.447 0 0 1 10 3.5 4.5 4.5 0 0 1 14.5 8a4.393 4.393 0 0 1-.434 1.878Z" fill="#4b4b4b"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div class="input-box">
                                <p>{{$t('form_fields.username_label')}}</p>
                                <Field :class="`input input--primary`" tabindex="3" :validateOnInput="true" type="text" :placeholder="$t('form_fields.username_placeholder')" name="username" />
                                <ErrorMessage class="text-error text-left form-text" name="username"  />
                            </div>
                            <div class="input-box input-box--search input-box--search-gray">
                                <p>{{$t('form_fields.address_label')}}</p>
                                <SearchLocation :class="`input input--primary`" :index="4" :placeholder="$t('form_fields.address_placeholder')" @location-updated="getLocation" />
                                <Field :class="`input input--primary`" v-show="false" name="location" v-model="registration.location" />
                                <ErrorMessage class="text-error text-left form-text" name="location"  />
                            </div>
                            <div class="input-box" v-if="selectedGender">
                                <p>{{$t('form_fields.body_type_label')}}</p>
                                <div class="relative">
                                    <svg class="angle-down" xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><g data-name="Group 238"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></g></svg>
                                    <Field :class="`input input--primary`" tabindex="5" :validateOnInput="true" name="body_type" :label="$t('form_fields.body_type_label').toLowerCase()" as="select">
                                        <option style="display: none" value="">{{$t(`form_fields.body_type_placeholder`)}}</option>
                                        <option v-for="type in types.filter(e => e.genders.includes(selectedGender))" :key="type.code" :value="type.value">{{$t(`form_fields.body_type_options_${type.value}`)}}</option>
                                    </Field>
                                    <ErrorMessage class="text-error text-left form-text" name="body_type"  />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-7 col-lg-7">
                                    <div class="input-box">
                                        <p>{{$t('form_fields.ethnicity_label')}}</p>
                                        <div class="relative">
                                            <svg class="angle-down" xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><g data-name="Group 238"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></g></svg>
                                            <Field :class="`input input--primary`" tabindex="6" :validateOnInput="true" name="ethnicity" as="select">
                                                <option value="" style="display: none;">{{$t('form_fields.ethnicity_placeholder')}}</option>
                                                <option v-for="e in ethnicities" :key="e.code" :value="e.value">{{$t(`form_fields.ethnicity_options_${e.value}`)}}</option>
                                            </Field>
                                            <ErrorMessage class="text-error text-left form-text" name="ethnicity"  />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-5 col-lg-5">
                                    <div class="input-box">
                                        <p>{{$t('form_fields.age_label')}}</p>
                                        <Field :class="`input input--primary`" tabindex="7" :validateOnInput="true" type="number" inputmode="numeric" :placeholder="$t('form_fields.age_placeholder')" name="age" />
                                        <ErrorMessage class="text-error text-left form-text" name="age"  />
                                    </div>
                                </div>
                            </div>
                            <div class="input-box">
                                <p v-if="selectedGender">{{$t('form_fields.interested_in.label')}}</p>
                                <div tabindex="8" class="input-box--buttons" v-if="selectedGender">
                                    <div v-for="i in interests" :key="i.code" class="checkbox">
                                        <Field :class="[`form-check-input`]" :id="`interested_${i.code}`" :label="$t('form_fields.interested_in.label').toLowerCase()" :validateOnInput="true" type="checkbox" class="form-check-input" name="interested_in" :value="i.value" />
                                        <label :for="`interested_${i.code}`" class="button button--third form-check-label">{{$t(`form_fields.interested_in.${i.code}`)}}</label>
                                    </div>
                                    <ErrorMessage class="text-error text-left form-text" name="interested_in"  />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <Button :disabled="working" tabindex="9" :class-name="['button', 'button--primary', 'w-100']">
                                <template v-slot:content>
                                    {{ucFirst($t('signup.join_now'))}}
                                </template>
                            </Button>
                        </div>
                    </Form>

                    <p class="return">{{$t('signup.have_account')}} <router-link :to="{name: 'login'}" class="link">{{$t('signup.login_here')}}</router-link></p>
                </div>

            </article>
        </div>
    </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import SearchLocation from "@/components/location/SearchLocation";
import { login, register } from '@/api/auth'
import Button from "@/components/generic/Button";
import { defineRule } from 'vee-validate';
import { required, email, min, min_value, max, max_value } from '@vee-validate/rules'
import { bodyTypes, ethnicities, interests, defaultAge } from '@/service/forms'
import {bootUser} from "@/service";
import { loadMarketingParams } from "@/service/marketing";
import { mapActions } from "vuex";
import { ucFirst, capitalize } from "@/utilities/general";
import LanguageSelection from "@/components/language/LanguageSelection";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_SOI, GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_SOI} from "@/service/gtm/types";
import { TYPE_GENDER_MALE, TYPE_GENDER_FEMALE } from "@/types/user";
import ThemeMixin from "@/mixins/ThemeMixin";

export default {
    name: "Register",
    mixins: [ThemeMixin],
    components: {
        Button,
        SearchLocation,
        LanguageSelection,
        Form, Field, ErrorMessage
    },
    data() {
        return {
            theme: '',
            working: false,
            step: 1,
            registration: {
                location: null
            },
            validationSchema: null,
            errorBag: null,
            showPassTrigger: true
        }
    },
    props: {
        gender: String
    },
    computed: {
        selectedGender() {
            return this.gender === 'man' ? TYPE_GENDER_MALE : TYPE_GENDER_FEMALE
        }
    },
    watch: {
        '$store.state.authenticate.lang': {
            handler() {
                this.$refs.registrationForm.validate()
            }
        }
    },
    methods: {
        ucFirst,
        capitalize,
        ...mapActions({
            setBootstrapping: 'application/setBootstrapping'
        }),
        async onSubmit(values) {

            this.working = true

            try {

                values.gender   = this.gender === 'man' ? 0 : 1
                values.lang     = this.$store.state.authenticate.lang

                for(const property in this.registration.location) {
                    values[property] = this.registration.location[property]
                }

                values.address = this.registration.location.location

                const marketing = loadMarketingParams()

                if(marketing) {

                    values = {
                        ...values,
                        ...marketing
                    }

                }

                // if( ! values.flow) {
                //     values.flow = "flow_1"
                // }

                const response = await register(values)

                pushDataLayer(
                    values.gender === TYPE_GENDER_MALE ? GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_SOI : GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_SOI,
                    response.data
                )

                localStorage.removeItem('marketing')

                let { data: {access_token} } = await login(values.email, values.password)

                localStorage.setItem('auth_token', access_token);

                await this.setBootstrapping(true)

                await bootUser(false)

                await this.$router.push('search')

                await this.setBootstrapping(false)


            } catch (e) {
                console.log('Error in registration', e)
                this.$refs.registrationForm.setErrors(e.response.data.errors)

            }

            this.working = false

        },
        getLocation(payload) {
            this.registration.location = payload
        }
    },
    created() {

        defineRule('required', required)
        defineRule('email', email)
        defineRule('min', min)
        defineRule('max', max)
        defineRule('min_value', min_value)
        defineRule('max_value', max_value)

        let validationObject = {
            email: 'required|email',
            username: 'required|min:3|max:15',
            password: 'required|min:6|max:45',
            age: `required|min_value:${defaultAge[0]}|max_value:${defaultAge[1]}`,
            ethnicity: 'required',
            location: 'required'
        }

        if(this.gender === 'woman') {
            validationObject.interested_in = 'required'
            validationObject.body_type= 'required'
        }

        this.validationSchema = validationObject

        this.theme = process.env.VUE_APP_THEME

        this.types = bodyTypes
        this.ethnicities = ethnicities
        this.interests = interests
    }
}
</script>

<style  scoped lang="scss">

    .header-register {
        height: 72px;

        .o-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        @media(max-width: 991px) {
            background-color: #fff;

            .o-container {
                padding: 0 20px;
            }
        }
    }

    .register {
        padding: 20px 0 57px;
        position: relative;
        height: auto;
        min-height: calc(100vh - 60px);

        article {
            position: relative;
            z-index: 2;
        }


        @media(max-width: 991px) {
            padding: 0;

            .o-container {
                padding: 0;
            }
        }

        .o-container {
            display: grid;
            grid-template-columns: 44% 56%;

            @media(max-width: 991px) {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }


    .register__text {
        padding-right: 90px;

        h1 {
            font-weight: 700;
            line-height: 1.3;
            margin-bottom: 30px;
            font-size: 20px;
            letter-spacing: -0.82px;
        }

        @media(max-width: 991px) {
            padding-top: 0;
            padding-right: 0;
            background-color: #F5F5F5;
            padding: 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                text-align: left;
                font-size: 20px;
                max-width: 400px;
            }
        }
    }

    .show-pass-trigger {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0,-50%);
    }

    .hidden-pass {
        transform: translate(1px,1px);
    }

    .register__text-list {
        li {
            color: $color-type16;
            font-size: 16px;
            letter-spacing: -0.66px;
            line-height: 1.5;
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            max-width: 360px;
            svg {
                margin-right: 14px;
                min-width: 33px;
            }
        }
    }

    .register__form {
        padding: 50px 90px 60px;
        background-color: #fff;
        border-radius: 10px;
        text-align: left;

        h1 {
            font-weight: bold;
            font-size: 30px;
            color: $color-type19;
            margin-bottom: 10px;
        }
        @media(max-width: 991px) {
            padding: 0 20px 40px;
            background-color: $color-type1;
            border-radius: 0;

            h1 {
                letter-spacing: -0.9px;
                color: #C59264;
                font-size: 22px;
                text-align: left;
                margin-top: 0;
            }

            .button--primary {
                max-width: 400px;
                width: 100%;
            }
        }
    }

    .register__form-heading {
        display: flex;
        align-items: center;

        h1 {
            margin-bottom: 0;
        }

        .header-logo {
            display: none;
            margin-right: 10px;

        }

        @media(max-width: 991px) {
            justify-content: flex-start;
            margin-bottom: 20px;

            .header-logo {
                display: block;
            }
        }
    }

    .register__form-text {
        width: 100%;
        margin: 10px auto 30px;
        line-height: 1.3;
        font-size: 16px;
    }

    @media(max-width: 991px) {

        .register__form-text {
            text-align: left;
            max-width: 250px;
            margin: 0 0 30px;
            font-size: 14px;
            letter-spacing: -0.57px;

            br {
                display: none ;
            }
        }
    }

    .row {
        margin: 0 -10px;

        >div {
            padding: 0 10px;
        }
    }

    .button-190 {
        width:190px;
    }

    .input-box--buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &:focus-visible {
            outline:1px solid $color-type19;
        }

        .form-check-input:checked + .button--third {
            background-color: $color-type18;
            color: $color-type1;
            border-color: $color-type18;
        }
    }

    .button--third {
        border: 1px solid $color-type3;
        padding: 0 30px;
        margin-right: 3px;
        margin-bottom: 6px;
    }

    .input--primary {
        height: 40px;
    }

    @media(min-width: 992px) {

        .step {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .return {
        margin-top: 35px;
        margin-bottom: 0;
        font-size: 16px;

        a {
            font-weight: 600;
            font-size: 16px;
        }

        @media(max-width: 991px) {
            margin-top: 20px;
            text-align: left;
        }
    }
</style>
