import axios from "axios";

export const login = (email, password) => {
    return axios.post(`auth/login`, {email: email, password: password})
}

export const user = () => {
    return axios.get(`user`)
}

export const userForToken = token => {
    return axios.get(`user`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const update = data => {
    return axios.post('user', data)
}

export const register = payload => {
    return axios.post('auth/register', payload)
}

/**
 *
 * @param url
 * @returns {Promise<any>}
 */
export const verifyEmail = url => {
    return axios.get(url)
}

export const autoLogin = url => {
    return axios.post(url)
}

export const resendEmail = email => {
    return axios.post('auth/register/resend', {email})
}

export const requestPasswordReset = email => {
    return axios.post('auth/reset-password', {email})
}

export const resetPassword = (email, password, password_confirmation, token) => {
    return axios.post('auth/reset/password', {email, password, password_confirmation, token})
}