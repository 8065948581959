<template>
    <Header class="login-header-page" @request-register="$emit('request-register')" />
    <main class="main-small-header">
        <section class="login">
            <div class="o-container">
                <article>
                    <h1><strong>{{$t('login.welcome')}}</strong></h1>
                    <transition name="fade" mode="out-in">
                        <div class="errors-box" v-if="errors.length">
                            <div v-for="(e, k) in errors" :key="k">{{e}}</div>
                        </div>
                    </transition>
                    <Form ref="loginForm" @submit="onSubmit" :validation-schema="schema">
                        <div class="input-box">
                            <p>{{$t('form_fields.email_or_username')}}</p>
                            <Field id="email" :class="`input input--primary`" :placeholder="'Type email address or username...'" :label="$t('form_fields.email_or_username').toLowerCase()" :validateOnInput="true" :disabled="working" name="email" />
                            <ErrorMessage class="text-error text-left form-text" name="email"  />
                        </div>
                        <div class="input-box">
                            <p>{{$t('form_fields.password_label')}}</p>
                            <div class="relative">
                                <Field id="password" autocomplete="on" :class="`input input--primary`" :placeholder="'Your password...'" :validateOnInput="true" :disabled="working" :type="showPassTrigger?'password':'text'" name="password" />
                                <a class="show-pass-trigger" @click="showPassTrigger = !showPassTrigger">
                                    <svg v-if="showPassTrigger" class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                                    <svg v-else class="hidden-pass" xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path data-name="Icon awesome-eye-slash" d="M10 12.5a4.483 4.483 0 0 1-4.466-4.16L2.256 5.807a10.415 10.415 0 0 0-1.147 1.737 1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 10 14a9.707 9.707 0 0 0 2.434-.327l-1.622-1.255A4.5 4.5 0 0 1 10 12.5Zm9.807 1.816-3.455-2.67a10.352 10.352 0 0 0 2.539-3.19 1.011 1.011 0 0 0 0-.912A10.023 10.023 0 0 0 10 2a9.63 9.63 0 0 0-4.6 1.178L1.421.105a.5.5 0 0 0-.7.088l-.616.79a.5.5 0 0 0 .088.7l18.386 14.21a.5.5 0 0 0 .7-.088l.614-.79a.5.5 0 0 0-.086-.699Zm-5.741-4.438-1.228-.949a2.961 2.961 0 0 0-3.628-3.81A1.489 1.489 0 0 1 9.5 6a1.458 1.458 0 0 1-.048.313l-2.3-1.778A4.447 4.447 0 0 1 10 3.5 4.5 4.5 0 0 1 14.5 8a4.393 4.393 0 0 1-.434 1.878Z" fill="#4b4b4b"/></svg>
                                </a>
                            </div>
                            <ErrorMessage class="text-error text-left form-text" name="password"  />
                        </div>

                        <div class="input-box">
                            <Button :disabled="working" :class-name="['button', 'button--primary','w-100']">
                                <template v-slot:content>
                                    {{ ucFirst($t('login.login')) }}
                                </template>
                            </Button>
                        </div>
                    </Form>
                    <div class="o-flex o-flex--center o-flex--justify mb-">
                        <router-link :to="{name: 'forgot-password'}" class="link">{{$t('login.forgot_password')}}</router-link>
                    </div>
                    <p class="login-text">{{$t('login.dont_have')}} <a @click="$emit('request-register')" class="link">{{$t('login.singnup')}}</a></p>
                </article>
            </div>
        </section>
    </main>
</template>

<script>

import { Form, Field, ErrorMessage } from 'vee-validate'
import { login } from '@/api/auth'
import { mapActions } from 'vuex'
import Button from "@/components/generic/Button";
import { defineRule } from 'vee-validate'
import { required, min } from '@vee-validate/rules'
import { bootUser } from "@/service";
import Header from "@/components/Header";
import { ucFirst } from "@/utilities/general";

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
        Button,
        Header
    },
    data() {
        return {
            working: false,
            errors: [],
            showPassTrigger: true,
            schema: {
                email: 'required',
                password: 'required|min:6'
            }
        }
    },
    emits: ['request-register'],
    watch: {
        '$store.state.authenticate.lang': {
            handler() {
                this.$refs.loginForm.validate()
            }
        }
    },
    methods: {
        ucFirst,
        ...mapActions({
            setLoggedUser: 'authenticate/setLoggedUser',
            setBootstrapping: 'application/setBootstrapping'
        }),

        async onSubmit({email, password}) {

            this.working = true

            try {

                const { data: {access_token} } = await login(email, password)

                localStorage.setItem('auth_token', access_token);

                await this.setBootstrapping(true)

                await bootUser(true)

                await this.$router.push('search')

                await this.setBootstrapping(false)

            } catch (e) {
                this.errors = [this.$i18n.t('auth.invalid')]
            }

            this.working = false

        },
    },
    created() {
        defineRule('required', required)
        defineRule('min', min)
    }
}
</script>

<style scoped lang="scss">


    .login {
        padding: 90px 0;

        article {
            padding: 60px 90px 80px;
            max-width: 520px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 10px;
        }

        h1 {
            margin-bottom: 30px;
            font-size: 30px;
            color: $color-type19;
        }

        .link {
            font-weight: 700;
            color: $color-type19;
        }

        .button {
            margin: 22px 0;
        }

        .login-text {
            margin-bottom: 0;
        }

        @media(max-width: 991px) {
            padding: 30px 0;

            article {
                padding: 10px;
                background-color: #fff;
                border-radius: 0;
            }
        }
    }

    main.main-small-header {
        min-height: calc(100vh - 180px);
        height: auto;

        @media(max-width: 991px) {
            background-color: #fff;
            min-height: calc(100vh - 230px);
        }
    }

    .show-pass-trigger {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0,-50%);
    }

    .hidden-pass {
        transform: translate(1px,1px);
    }

    .errors-box {
        padding: 10px 20px;
        border-radius: 10px;
        background-color: #FFBCBC;
        margin-bottom: 30px;

        >div {
            color: #B10000;
            font-size: 16px;
            line-height: 23px;
        }
    }

    @media(max-width: 991px) {
        .login-page {
            background-color: #fff;

        }
        .login-header-page {
            background-color: #fff;
        }
    }
</style>

<style lang="scss">
    .header-main {
        &.login-header-page {
            .action-buttons {
                >li:first-child {
                    display: none;
                }
            }
            @media(max-width: 991px) {
                .o-container {
                    padding: 0 20px;
                }
          
                .navbar-nav {
                    background-color: #fff;
                }
            }
        }
    }
</style>
